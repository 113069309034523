/*
@File: Cyrnetics Labs Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the template.

This files table contents are outlined below>>>>>

******************************************* 
** - Default-style
** - Navbar-style
** - Modal-style
** - Banner-style
** - Features-style
** - Partner-style
** - Data Analysis-style
** - Solution-style
** - Core-Statics-style
** - Counter-style
** - Pricing-style
** - Reviews-style
** - Blog-style
** - Contact-style
** - Footer-style
** - Acquaintance-style
** - Design-style
** - Download-style
** - Team-style
** - Choose-style
** - About-style
** - Services-style
** - Portfolio-style
** - Testimonial-style
** - Instagram-style
** - Coming-Soon-style
** - Header-style
** - Movement-style
** - Volunteer-style
** - Freedom-style
** - Buy-&-Sell-style
** - Coin-Sell-style
** - Tranding-style
** - Top-Sellers-style
** - Live-Auction-style
** - Author-style
** - Collection-style
** - Priducts-style
*******************************************
*/

@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mukta:wght@200;300;400;500;600;700;800&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$heading-font-family: 'Kumbh Sans', sans-serif;
$heading-font-family-2: 'Roboto', sans-serif;
$heading-font-family-3: 'Montserrat', sans-serif;
$heading-font-family-4: 'Mulish', sans-serif;
$heading-font-family-5: 'Playfair Display', serif;
$body-font-family: 'Poppins', sans-serif;
$body-font-family-2: 'Karla', sans-serif;
$body-font-family-3: 'Mukta', sans-serif;

$sass-main-color: #0797ff;
$app-main-color: #f4732a;
$consulting-main-color: #3561e4;
$photography-main-color: #9C5451;
$political-main-color: #dd131a;
$currency-main-color: #0b6dff;
$nft-main-color: #06dbac;
$exchange-main-color: #00a9a4;
$sass-secondary-color: #6064e3;
$app-secondary-color: #36234b;
$heading-color: #19191b;
$heading-color-2: #0b1460;
$heading-color-3: #181c27;
$heading-color-4: #0c1a3c;
$body-color: #555555;
$white-color: #ffffff;
$font-size: 16px;
$box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
$transition: all ease .5s;

/*Default-style
======================================================*/
body {
    color: $body-color;
    font-family: $body-font-family;
    font-size: $font-size;
    font-weight: 400;
}
img {
    max-width: 100%;
}
.d-table {
    width: 100%;
    height: 100%;
}
.d-table-cell {
    vertical-align: middle;
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    };
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.pb-50 {
    padding-bottom: 50px;
}
.pb-70 {
    padding-bottom: 70px;
}
.pt-70 {
    padding-top: 70px;
}
.ptb-50 {
    padding: {
        top: 50px;
        bottom: 50px;
    };
}
.pl-15 {
    padding-left: 15px;
}
.pr-15 {
    padding-right: 15px;
}
.mr-20 {
    margin-right: 15px;
}
.pr-20 {
    padding-right: 20px;
}
.pl-20 {
    padding-left: 20px;
}
.pl-30 {
    padding-left: 30px;
}
.mlr-5 {
    margin-right: 5px;
    margin-left: 5px;
}
.mlrt-5 {
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
}
.right-15 {
    right: 15px !important;
}
h1, h2, h3, h4, h5, h6 {
    color: $heading-color;
    font-family: $heading-font-family;
    font-weight: 700;
}
p {
    margin-bottom: 15px;
    font-size: $font-size;
    color: $body-color;
    font-family: $body-font-family;
    line-height: 1.8;
    &:last-child {
        margin-bottom: 0;
    }
}
.form-control {
    height: 55px;
    border: 1px solid #eeeeee;
    padding-left: 15px;
    &:focus {
        border: 1px solid $sass-main-color;
        box-shadow: none;
    }
}
a {
    text-decoration: none;
}
textarea {
    height: auto !important;
}
button {
    box-shadow: none;
    outline: none;
}
.bg-f7f7f7 {
    background-color: #f7f7f7;
}
/*Section-title
======================================================*/
.sass-section-title {
    max-width: 530px;
    margin: 0 auto 40px auto;
    text-align: center;
    position: relative;
    span {
        font-size: 16px;
        margin-bottom: 10px;
        display: inline-block;
        color: $sass-main-color;
    }
    h2 {
        font-size: 40px;
    }
}
.app-section-title {
    max-width: 530px;
    margin: 0 auto 40px auto;
    text-align: center;
    position: relative;
    span {
        font-size: 16px;
        margin-bottom: 10px;
        display: inline-block;
        color: $app-main-color;
    }
    h2 {
        font-size: 40px;
        font-family: $heading-font-family-2;
    }
}
.agency-section-title {
    max-width: 530px;
    margin: -5px auto 40px auto;
    text-align: center;
    position: relative;
    span {
        font-size: 16px;
        margin-bottom: 10px;
        display: inline-block;
        color: $app-main-color;
        font-family: $body-font-family-2;
    }
    h2 {
        font-size: 40px;
        font-family: $heading-font-family-3;
        color: $heading-color-2;
    }
}
.consulting-section-title {
    max-width: 760px;
    margin: 0 auto 50px auto;
    text-align: center;
    position: relative;
    span {
        font-size: 16px;
        margin-bottom: 10px;
        display: inline-block;
        color: $consulting-main-color;
        letter-spacing: 3px;
        text-transform: uppercase;
        font-weight: 500;
    }
    h2 {
        font-size: 40px;
        font-family: $heading-font-family-4;
        color: $heading-color-3;
    }
    &::before {
        position: absolute;
        content: '';
        height: 3px;
        width: 100px;
        background-color: $consulting-main-color;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
    }
    &::after {
        position: absolute;
        content: '';
        height: 3px;
        width: 7px;
        bottom: -20px;
        background-color: $white-color;
        animation: movebounce2 4s linear infinite;
    }
}
.photography-section-title {
    max-width: 630px;
    margin: -5px auto 40px auto;
    text-align: center;
    position: relative;
    span {
        font-size: 16px;
        color: $photography-main-color;
        letter-spacing: 3px;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 10px;
        font-family: $body-font-family-3;
    }
    h2 {
        font-size: 40px;
        font-family: $heading-font-family-5;
        color: $heading-color;
        font-weight: 600;
    }
}
.political-section-title {
    text-align: center;
    position: relative;
    margin: -5px auto 40px auto;
    span {
        font-size: 16px;
        color: $political-main-color;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 10px;
        font-family: $heading-font-family-2;
    }
    h2 {
        font-size: 40px;
        font-family: $body-font-family;
        color: $heading-color-4;
        font-weight: 700;
    }
}
.currency-section-title {
    max-width: 730px;
    margin: 0 auto 40px auto;
    text-align: center;
    position: relative;
    span {
        font-size: 16px;
        color: $currency-main-color;
        letter-spacing: 3px;
        text-transform: uppercase;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 10px;
        font-family: $heading-font-family-2;
    }
    h2 {
        font-size: 40px;
        font-family: $body-font-family;
        color: $white-color;
        font-weight: 700;
    }
}
.nft-section-title {
    margin: -5px 0 40px 0;
    position: relative;
    padding-left: 50px;
    h2 {
        font-size: 40px;
        font-family: $body-font-family;
        color: $heading-color;
        font-weight: 700;
    }
    &::before {
        position: absolute;
        content: '';
        height: 30px;
        width: 30px;
        background: linear-gradient(90deg, #06DBAC 4.17%, #1EA5FF 100%);
        border-radius: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    &::after {
        position: absolute;
        content: '';
        height: 20px;
        width: 20px;
        background: $white-color;
        border-radius: 100%;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
    }
    &.style2 {
        h2 {
            color: $white-color;
        }
        &::after {
            background-color: #0a0a0a;
        }
    }
}
.exchange-section-title {
    text-align: center;
    position: relative;
    margin: -5px auto 40px auto;
    span {
        font-size: 16px;
        color: $exchange-main-color;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 10px;
        font-family: $heading-font-family-2;
    }
    h2 {
        font-size: 40px;
        font-family: $body-font-family;
        font-weight: 700;
    }
    &.style2 {
        h2 {
            color: $white-color;
        }
    }
}

.bg-color-f8f8f8 {
    background-color: #f8f8f8;
}
.bg-color-efedf0 {
    background-color: #efedf0;
}
.bg-color-f9f5f4 {
    background-color: #f9f5f4;
}
.bg-color-f9f9f9 {
    background-color: #f9f9f9;
}
.bg-color-eef1f6 {
    background-color: #eef1f6;
}
.bg-color-1c2029 {
    background-color: #1c2029;
}
.bg-color-0e1013 {
    background-color: #0e1013;
}
.bg-color-0a0a0a {
    background-color: #0a0a0a;
}
.bg-color-f5f5f5 {
    background-color: #f5f5f5;
}


/*Navbar-style
======================================================*/
.navbar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    padding: 15px 0;
    height: auto;

    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 15px 0;
        z-index: 999;
        box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
        background-color: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
    .container-fluid {
        max-width: 1690px;
        padding-left: 30px;
        padding-right: 30px;
        position: relative;
    }
    .others-options {
        a {
            background-color: $sass-secondary-color;
            color: $white-color;
            padding: 15px 30px;
            border-radius: 7px;
            margin-left: 40px;
            transition: $transition;
            display: inline-block;
            text-decoration: none;
            &:hover {
                background-color: $sass-main-color;
                color: $white-color;
            }
        }
        &.style3 {
            a {
                background-color: $heading-color-2;
                color: $white-color;
                i {
                    position: relative;
                    right: 0;
                    top: 2px;
                    padding-left: 7px;
                }
                &:hover {
                    background-color: $app-main-color;
                    color: $white-color;
                }
            }
        }
        &.style4 {
            a {
                background-color: $heading-color-3;
                color: $white-color;
                i {
                    position: relative;
                    right: 0;
                    top: 2px;
                    padding-left: 7px;
                }
                &:hover {
                    background-color: $consulting-main-color;
                    color: $white-color;
                }
            }
        }
        &.style7 {
            a {
                background-color: $political-main-color;
                color: $white-color;
                border-radius: 0;
                i {
                    position: relative;
                    right: 0;
                    top: 2px;
                    padding-left: 7px;
                }
                &:hover {
                    background-color: $heading-color-3;
                    color: $white-color;
                }
            }
        }
        &.style9 {
            a {
                margin-left: 0;
            }
        }
        &.style10 {
            a {
                background-color: $exchange-main-color;
                color: $white-color;
                &:hover {
                    background-color: $white-color;
                    color: #030734;
                }
                i {
                    position: relative;
                    top: 2px;
                    padding-left: 7px;
                }
            }
        }
        .modal-btn {
            text-align: center;
            margin-left: 40px;
            i {
                color: $white-color;
                font-size: 30px;
                background-color: #222222;
                height: 50px;
                width: 60px;
                line-height: 50px;
                cursor: pointer;
                display: inline-block;
                transition: $transition;
                &:hover {
                    color: $photography-main-color;
                    background-color: $white-color;
                }
            }
        }
    }
}
.bg-light {
    background-color: transparent !important;
}

.navbar-brand {
    .white-logo {
        display: none;
    }
}
.navbar-light {
    &.nav-style2 {
        background-color: $white-color;
        .others-options {
            a {
                background-color: $app-main-color;
                color: $white-color;
                transition: $transition;
                &:hover {
                    background-color: $app-secondary-color;
                    color: $white-color;
                }
            }
        }
        .navbar-nav {
            .nav-item {
                .nav-link {
                    &:hover, &:focus, &.active {
                        color: $app-main-color;
                    }
                }
                &.active {
                    .nav-link {
                        color: $app-main-color;
                    }
                }
            }
        }
        &.is-sticky {
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        &:hover, &:focus, &.active {
                            color: $app-main-color;
                        }
                    }
                }
            }
        }
        
    }
    &.nav-style5 {
        .navbar-nav {
            .nav-item {
                .nav-link {
                    &:hover, &:focus, &.active {
                        color: $app-main-color;
                    }
                }
                &.active {
                    .nav-link {
                        color: $app-main-color;
                    }
                }
            }
        }
        &.is-sticky {
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        &:hover, &:focus, &.active {
                            color: $app-main-color;
                        }
                    }
                }
            }
        }
    }
    &.nav-style3 {
        background-color: $white-color;
        .navbar-nav {
            .nav-item {
                .nav-link {
                    &:hover, &:focus, &.active {
                        color: $consulting-main-color;
                    }
                }
                &.active {
                    .nav-link {
                        color: $consulting-main-color;
                    }
                }
            }
        }
        &.is-sticky {
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        &:hover, &:focus, &.active {
                            color: $consulting-main-color;
                        }
                    }
                }
            }
        }
    }
    &.nav-style4 {
        background-color: transparent;
        .others-options {
            a {
                background-color: $app-main-color;
                color: $white-color;
                transition: $transition;
                &:hover {
                    background-color: $app-secondary-color;
                    color: $white-color;
                }
            }
        }
        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: $white-color;
                    &:hover, &:focus, &.active {
                        color: $photography-main-color;
                    }
                }
            }
        }
        &.is-sticky {
            background-color: #191919 !important;
            .nav-item {
                .nav-link {
                    color: $white-color !important;
                    &:hover, &:focus, &.active {
                        color: $photography-main-color !important;
                    }
                }
            }
        }
    }
    .navbar-nav {
        z-index: 999;
        .nav-item {
            margin-bottom: 0;
            padding: {
                right: 20px;
                left: 20px;
            }
            .nav-link {
                color: $heading-color;
                padding: 0;
                position: relative;
                font: {
                    size: 15px;
                    weight: 600;
                }
                &:hover, &:focus, &.active {
                    color: $sass-main-color;
                }
            }
            &:last-child {
                padding-right: 0;
            }
            &:first-child {
                padding-left: 0;
            }
            &.active {
                .nav-link {
                    color: $sass-main-color;

                    &::before {
                        bottom: -13px;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
    &.is-sticky {
        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: $heading-color;
                    &:hover, &:focus, &.active {
                        color: $sass-main-color;
                    }
                }
            }
        }
    }
    &.nav-style6 {
        background-color: $white-color;
    }
    &.nav-style7 {
        padding: 0;
        .container-fluid {
            background-color: $white-color;
            box-shadow: $box-shadow;
            padding: 15px 30px;
        }

        .navbar-nav {
            .nav-item {
                .nav-link {
                    &:hover, &:focus, &.active {
                        color: $political-main-color;
                    }
                }
                &.active {
                    .nav-link {
                        color: $political-main-color;
                    }
                }
            }
        }
        &.is-sticky {
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        &:hover, &:focus, &.active {
                            color: $political-main-color;
                        }
                    }
                }
            }
        }
    }
    &.nav-style8 {
        background-color: transparent;
        .others-options {
            margin-left: 40px;
            ul {
                padding-left: 0;
                margin-bottom: 0;
                li {
                    display: inline-block;
                    margin-right: 15px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        padding: 15px 20px;
                        background-color: #1C2029;
                        color: $white-color;
                        border-radius: 10px;
                        transition: $transition;
                        margin: 0;
                        &:hover {
                            background-color: $currency-main-color;
                            color: $white-color;
                        }
                    }
                }
            }
        }
        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: $white-color;
                    &:hover, &:focus, &.active {
                        color: $currency-main-color;
                    }
                }
            }
        }
        &.is-sticky {
            background-color: #191919 !important;
            .nav-item {
                .nav-link {
                    color: $white-color !important;
                    &:hover, &:focus, &.active {
                        color: $currency-main-color !important;
                    }
                }
            }
        }
    }
    &.nav-style9 {
        background-color: transparent;
        .others-options {
            margin-left: 40px;
        }
        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: $white-color;
                    &:hover, &:focus, &.active {
                        color: $nft-main-color !important;
                    }
                }
            }
        }
        &.is-sticky {
            background-color: #191919 !important;
            .nav-item {
                .nav-link {
                    color: $white-color !important;
                    &:hover, &:focus, &.active {
                        color: $nft-main-color !important;
                    }
                }
            }
        }
    }
    &.nav-style10 {
        background-color: #030734;
        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: $white-color;
                    &:hover, &:focus, &.active {
                        color: $exchange-main-color !important;
                    }
                }
            }
        }
        &.is-sticky {
            background-color: #030734 !important;
            .nav-item {
                .nav-link {
                    color: $white-color !important;
                    &:hover, &:focus, &.active {
                        color: $exchange-main-color !important;
                    }
                }
            }
        }
    }
    &.nav-style11 {
        background-color: transparent;
        .others-options {
            a {
                background-color: $sass-main-color;
                color: $white-color;
                transition: $transition;
                &:hover {
                    background-color: $heading-color;
                    color: $white-color;
                }
            }
        }
        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: $white-color;
                    &:hover, &:focus, &.active {
                        color: $sass-main-color;
                    }
                }
            }
        }
        &.is-sticky {
            background-color: #191919 !important;
            .nav-item {
                .nav-link {
                    color: $white-color !important;
                    &:hover, &:focus, &.active {
                        color: $sass-main-color !important;
                    }
                }
            }
        }
    }
}
@keyframes fadeInDown {
	0% {
	  opacity: 0;
	  transform: translate3d(0, -100%, 0);
	}
	to {
	  opacity: 1;
	  transform: translateZ(0);
	}
}
@keyframes ripple {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    75% {
      -webkit-transform: scale(1.75);
              transform: scale(1.75);
      opacity: .6;
    }
    100% {
      -webkit-transform: scale(2);
              transform: scale(2);
      opacity: 0;
    }
}
@keyframes rotateme {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes movebounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes movebounce2 {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(30px);
    }
    100% {
        transform: translateX(0px);
    }
}

/* Navbar toggler */
.navbar-toggler {
    border: none;
	border-radius: 0;
	padding: 0;

    &:focus {
        outline: 0;
        box-shadow: none;
    }

    .icon-bar {
        width: 35px;
        transition: all 0.3s;
        background: #221638;
        height: 4px;
		display: block;
		border-radius: 3px;
    }
    .top-bar {
        transform: rotate(45deg);
        transform-origin: 10% 10%;
        left: 4px;
        position: relative;
    }
    .middle-bar {
        opacity: 0;
        margin: 5px 0;
    }
    .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
        left: 4px;
        position: relative;
    }
    &.collapsed {
        .top-bar {
            transform: rotate(0);      
            left: 0;
        }
        .middle-bar {
            opacity: 1;
        }
        .bottom-bar {
            transform: rotate(0);
            left: 0;
            margin-top: 6px;
        }
    }
}

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    overflow: hidden;
    background: $heading-color;

    .loader {
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        width: 110px;
        height: 110px;
        margin: -80px 0 0 -75px;
        border-radius: 50%;
        border: 7px solid transparent;
        border-top-color: $sass-main-color;
        animation: spin 1.7s linear infinite;
        z-index: 11;

        &::before {
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            border-radius: 50%;
            border: 7px solid transparent;
            border-top-color: $white-color;
            animation: spin-reverse .6s linear infinite;
        }
        &::after {
            content: "";
            position: absolute;
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 15px;
            border-radius: 50%;
            border: 7px solid transparent;
            border-top-color: $sass-main-color;
            animation: spin 1s linear infinite;
        }
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes spin-reverse {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

/*Sidebar Modal
======================================================*/
.modal {
    &.right {
        .modal-dialog {
            position: fixed;
            margin: auto;
            width: 420px;
            height: 100%;
            transform: translate3d(0%, 0, 0);
            right: -100%;
            transition: $transition;
        }
        .modal-content {
            height: 100%;
            overflow-y: auto;
            background-color: #0e0e0e;
            border: none;
            border-radius: 0;
            button {
                &.close {
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    background-color: transparent;
                    border: none;
                    text-shadow: unset;
                    box-shadow: unset;
                    z-index: 99;
                    opacity: 1;
                    i {
                        display: inline-block;
                        height: 30px;
                        width: 30px;
                        line-height: 30px;
                        background-color: $photography-main-color;
                        color: $white-color;
                        font-size: 20px;
                        transition: $transition;
                        border-radius: 50%;
                        &:hover {
                            background-color: $heading-color;
                        }
                    }
                }
            }
        }
        &.show {
            .modal-dialog {
                right: 0;
            }
        }
    }
}
.sidebarModal {
    position: relative;
    &.modal {
        display: block;
        .modal-body {
            padding: 70px 30px 30px 30px;
            .sidebar-content {
                margin-top: 35px;
                margin-bottom: 30px;
                p {
                    margin-bottom: 30px;
                    color: $white-color;
                }
                h3 {
                    font-size: 22px;
                    margin-bottom: 15px;
                    color: $white-color;
                    font-family: $heading-font-family-5;
                }
                .sidebar-btn {
                    margin-top: 25px;
                }
            }
            .sidebar-contact-info {
                margin-top: 30px;
                h3 {
                    font-size: 22px;
                    margin-bottom: 0;
                    font-family: $heading-font-family-5;
                    color: $white-color;
                }
                .info-list {
                    padding-left: 0;
                    margin-top: 30px;
                    margin-bottom: 0;
                    li {
                        list-style-type: none;
                        font-size: 14px;
                        color: $white-color;
                        font-weight: 500;
                        margin-bottom: 20px;
                        position: relative;
                        padding-left: 22px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        a {
                            color: $white-color;
                            &:hover {
                                color: $photography-main-color;
                            }
                        }
                        i {
                            position: absolute;
                            left: 0;
                            top: 2px;
                            color: $photography-main-color;
                        }
                    }
                }
            }
            .sidebar-social-list {
                list-style-type: none;
                padding-left: 0;
                margin-bottom: 0;
                margin-top: 30px;
                li {
                    display: inline-block;
                    margin-right: 8px;
                    &:last-child {
                        margin-right: 0;
                    }
                    i {
                        display: inline-block;
                        height: 35px;
                        width: 35px;
                        line-height: 40px;
                        font-size: 16px;
                        background: $photography-main-color;
                        text-align: center;
                        color: $white-color;
                        border-radius: 50%;
                        transition: $transition;
                        &:hover {
                            transform: translateY(-5px);
                            background-color: $heading-color;
                            color: $white-color;
                        }
                    }
                }
            }
            .contact-form {
                margin-top: 30px;
                h3 {
                    font-size: 22px;
                    margin-bottom: 25px;
                    color: $white-color;
                    font-family: $heading-font-family-5;
                }
                #contactForm {
                    .form-group {
                        margin-bottom: 20px;
                        .form-control {
                            padding: 15px;
                        }
                    }
                    .default-btn {
                        border: none;
                    }
                    .list-unstyled  {
                        color: #ee1010;
                        font-size: 14px;
                        margin-top: 8px;
                    }
                    #msgSubmit {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

/*Default btn
======================================================*/
.sass-default-btn {
    background-color: $sass-main-color;
    color: $white-color;
    padding: 15px 30px;
    transition: $transition;
    border-radius: 7px;
    display: inline-block;
    &:hover {
        background-color: $sass-secondary-color;
        color: $white-color;
    }
}
.app-default-btn {
    background-color: $app-secondary-color;
    color: $white-color;
    padding: 15px 30px;
    transition: $transition;
    border-radius: 10px;
    display: inline-block;
    text-decoration: none;
    &:hover {
        background-color: $app-main-color;
        color: $white-color;
    }
    &.style2 {
        background-color: $app-main-color;
        color: $white-color;
        &:hover {
            background-color: $white-color;
            color: $app-main-color;
        }
    }
    &.style3 {
        background-color: $white-color;
        color: $app-main-color;
        &:hover {
            background-color: $app-main-color;
            color: $white-color;
        }
    }
    &.style3 {
        display: block;
        padding: 15px 30px;
        border: 1px solid #efedf0;
        text-align: center;
        color: $app-main-color;
        transition: $transition;
        border-radius: 10px;
        &:hover {
            background-color: $app-main-color;
            color: $white-color;
            border-color: $app-main-color;
        }
    }
}
.agency-default-btn {
    background-color: $app-main-color;
    color: $white-color;
    padding: 15px 30px;
    transition: $transition;
    border-radius: 10px;
    &:hover {
        background-color: $heading-color-2;
        color: $white-color;
    }
    i {
        position: relative;
        top: 2px;
        padding-left: 7px;
    }
    &.style2 {
        background-color: transparent;
        border: 1px solid $heading-color-2;
        color: $heading-color-2;
        &:hover {
            background-color: $app-main-color;
            color: $white-color;
            border-color: $app-main-color;
        }
    }
    &.style3 {
        background-color: $heading-color-2;
        color: $white-color;
        &:hover {
            background-color: $app-main-color;
            color: $white-color;
        }
    }
    &.style4 {
        background-color: transparent;
        border: 1px solid #eeeeee;
        color: $app-main-color;
        &:hover {
            background-color: $app-main-color;
            color: $white-color;
            border-color: $app-main-color;
        }
    }
}
.consulting-default-btn {
    background-color: $consulting-main-color;
    color: $white-color;
    padding: 15px 30px;
    transition: $transition;
    border-radius: 10px;
    &:hover {
        background-color: $heading-color-3;
        color: $white-color;
    }
    i {
        position: relative;
        top: 3px;
        padding-left: 7px;
    }
    &.style2 {
        background-color: $heading-color;
        color: $white-color;
        &:hover {
            color: $white-color;
            background-color: $consulting-main-color;
        }
    }
}
.photography-default-btn {
    background-color: $photography-main-color;
    color: $white-color;
    padding: 15px 30px;
    transition: $transition;
    border-radius: 0;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    &:hover {
        background-color: $heading-color;
        color: $white-color;
    }
    i {
        position: relative;
        top: 2px;
        padding-left: 7px;
    }
    &.style2 {
        background-color: transparent;
        border: 1px solid #9C5451;
        color: $photography-main-color;
        width: 100%;
        &:hover {
            background-color: $photography-main-color;
            color: $white-color;
        }
    }
}
.political-default-btn {
    background-color: $political-main-color;
    color: $white-color;
    padding: 15px 30px;
    transition: $transition;
    border-radius: 0;
    font-weight: 500;
    font-size: 15px;
    font-family: $heading-font-family-2;
    &:hover {
        background-color: $heading-color-4;
        color: $white-color;
    }
    &.style2 {
        background-color: $heading-color-4;
        color: $white-color;
        &:hover {
            background-color: $political-main-color;
            color: $white-color;
        }
    }
}
.currency-default-btn {
    background-color: #0b6dff;
    color: $white-color;
    padding: 15px 30px;
    transition: $transition;
    border-radius: 7px;
    font-weight: 500;
    font-size: 15px;
    font-family: $heading-font-family-2;
    &:hover {
        background-color: #6094e1;
        color: $white-color;
        border-color: #6094e1;
    }
    &.style2 {
        background-color: #0e1013;
        color: $white-color;
        &:hover{
            background-color: $currency-main-color;
            color: $white-color;
        }
    }
}
.nft-default-btn {
    background: linear-gradient(90deg, #06DBAC 0%, #1EA5FF 100%);
    color: $white-color;
    padding: 15px 30px;
    transition: $transition;
    -o-transition: all .4s ease-in-out;
    border-radius: 10px;
    font-weight: 500;
    font-size: 15px;
    font-family: $heading-font-family-2;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &::before {
        position: absolute;
        content: '';
        background: linear-gradient(90deg, #1EA5FF 0%, #06DBAC 100%);
        height: 100%;
        width: 0%;
        left: 0;
        top: 0;
        transition: $transition;
        z-index: -1;
    }
    &::after {
        position: absolute;
        content: '';
        background: linear-gradient(90deg, #1EA5FF 0%, #06DBAC 100%);
        height: 0%;
        width: 100%;
        left: 0;
        top: 0;
        transition: $transition;
        z-index: -1;
    }
    i {
        color: $white-color;
        position: relative;
        top: 2px;
        padding-left: 7px;
    }
    &:hover {
        color: $white-color;
        box-shadow: 0 4px 15px 0 rgba(6, 219, 172, .75);
        &::before {
            width: 100%;
        }
        &::after {
            height: 100%;
        }
    }
    &.style2 {
        background: transparent;
        color: $white-color;
        border: 1px solid $nft-main-color;
        transition: $transition;
        &:hover{
            color: $white-color;
        }
    }
}
.exchange-default-btn {
    background-color: $exchange-main-color;
    color: $white-color;
    padding: 15px 30px;
    transition: $transition;
    border-radius: 10px;
    font-weight: 500;
    font-size: 15px;
    font-family: $heading-font-family-2;
    position: relative;
    z-index: 1;
    i {
        color: $white-color;
        position: relative;
        top: 2px;
        padding-left: 7px;
    }
    &:hover {
        background-color: #030734;
        color: $white-color;
    }
    &.style2 {
        background-color: #030734;
        color: $white-color;
        &:hover {
            background-color: $exchange-main-color;
            color: $white-color;
        }
    }
}
.read-more-2 {
    color: $consulting-main-color;
    i {
        position: relative;
        padding-left: 7px;
        top: 3px;
    }
}

/*Banner Style
======================================================*/
.sass-banner-area {
    padding-top: 200px;
    padding-bottom: 170px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    &.bg-1 {
        background-color: #f8f8f8;
        background-size: cover;
        background-position: center center;
    }
    .container-fluid {
        max-width: 1690px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .banner-shape {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        z-index: -1;
    }
}
.sass-banner-content {
    position: relative;
    span {
        color: $sass-main-color;
        display: inline-block;
        margin-bottom: 15px;
    }
    h1 {
        font-size: 80px;
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 30px;
    }
    .content-shape {
        position: absolute;
        right: 150px;
        top: -30px;
        animation: movebounce2 5s linear infinite;
    }
}
.sass-banner-image {
    position: relative;
    z-index: 1;
    .shape {
        .banner-shape-1 {
            position: absolute;
            top: -50px;
            left: -30px;
            animation: ripple 4s linear infinite;
        }
        .banner-shape-2 {
            position: absolute;
            top: 0;
            right: 300px;
            animation: movebounce 4s linear infinite;
            width: 100px
        }
        .banner-shape-3 {
            position: absolute;
            bottom: -70px;
            right: 370px;
            animation: movebounce 5s linear infinite;
            width: 100px
        }
        .banner-shape-4 {
            position: absolute;
            bottom: -50px;
            right: 0;
            z-index: -1;
            animation: movebounce2 4s linear infinite;

        }
        .banner-shape-5 {
            position: absolute;
            bottom: -100px;
            left: -100px;
            animation: movebounce2 5s linear infinite;
        }
        .banner-shape-6 {
            position: absolute;
            top: -50px;
            left: 300px;
            animation: fadeInDown 4s linear infinite;
        }
    }
}

.app-banner-area {
    background-color: #36234b;
    padding-top: 100px;
    position: relative;
    .container-fluid {
        max-width: 1540px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .app-banner-shape-1 {
        position: absolute;
        bottom: 150px;
        right: 50%;
        transform: translateX(-50%);
        animation: movebounce 5s linear infinite;
    }
    .app-banner-shape-2 {
        position: absolute;
        top: 300px;
        right: 120px;
        animation: rotateme 5s linear infinite;
    }
    .app-banner-shape-3 {
        position: absolute;
        bottom: 150px;
        left: 60px;
        animation: movebounce 5s linear infinite;
    }
}
.app-banner-content {
    margin-bottom: 15px;
    span {
        color: $app-main-color;
        margin-bottom: 15px;
        display: inline-block;
    }
    h1 {
        font-size: 64px;
        font-family: $heading-font-family-2;
        color: $white-color;
        margin-bottom: 20px;
    }
    p {
        color: $white-color;
        margin-bottom: 30px;
    }
    .download-links {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                margin-right: 15px;
                img {
                    border-radius: 12px;
                }
            }
        }
    }
}

/*Partner Style
======================================================*/
.partner-slider {
    .partner-logo {
        opacity: .80;
        transition: $transition;
        &:hover {
            opacity: 1;
        }
    }
}

/*Features Style
======================================================*/
.sass-single-features-card {
    text-align: center;
    padding: 30px;
    box-shadow: $box-shadow;
    margin-bottom: 30px;
    transition: $transition;
    border-radius: 10px;
    img {
        max-width: 80px;
        margin-bottom: 15px;
    }
    h3 {
        font-size: 22px;
        margin-bottom: 13px;
    }
    &:hover {
        transform: translateY(-10px);
    }
}
.sass-features-area {
    .container {
        position: relative; 
        .features-shape {
            position: absolute;
            top: 50px;
            right: 22%;
            animation: movebounce2 5s linear infinite;
        }
    }
}
.single-app-features-box {
    position: relative;
    z-index: 1;
    transition: $transition;
    &::before {
        content: '';
        position: absolute;
        background-color: #f5f3f6;
        width: 100%;
        height: 70%;
        z-index: -1;
        top: -20px;
        left: 0;
        border-radius: 10px;
    }
    .features-content {
        text-align: center;
        background-color: $white-color;
        padding: 30px;
        box-shadow: $box-shadow;
        margin: 20px 20px 30px 20px;
        position: relative;
        z-index: 2;
        border-radius: 10px;
        i {
            color: $app-main-color;
            font-size: 34px;
            margin-bottom: 18px;
            display: inline-block;
            line-height: 1;
        }
        h3 {
            font-family: $heading-font-family-2;
            margin-bottom: 13px;
            font-size: 22px;
        }
    }
    &:hover {
        transform: translateY(-10px);
    }
}

/*Data Analysis Style
======================================================*/
.sass-data-content {
    .data-section-title {
        span {
            color: $sass-main-color;
            display: inline-block;
            margin-bottom: 10px;
        }
        h2 {
            font-size: 40px;
            margin-bottom: 20px;
        }
        p {
            margin-bottom: 20px;
        }
    }
    .data-list {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                list-style-type: none;
                padding: 30px;
                margin-bottom: 15px;
                box-shadow: $box-shadow;
                padding-left: 80px;
                position: relative;
                transition: $transition;
                border-radius: 10px;
                background-color: $white-color;
                font-weight: 500;
                color: $heading-color;
                .icon {
                    color: $sass-main-color;
                    height: 50px;
                    width: 50px;
                    line-height: 50px;
                    border-radius: 100%;
                    background-color: #f8f8f8;
                    text-align: center;
                    position: absolute;
                    left: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    i {
                        font-size: 20px;
                        position: relative;
                        top: 3px;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &:hover {
                    background-color: $sass-main-color;
                    color: $white-color;
                }
            }
        }
    }
}

/*Solution Style
======================================================*/
.sass-solution-single-card {
    padding: 30px;
    margin-bottom: 30px;
    box-shadow: $box-shadow;
    background-color: $white-color;
    border-radius: 10px;
    transition: $transition;
    position: relative;
    .solution-content {
        position: relative;
        padding-left: 80px;
        .icon {
            height: 60px;
            width: 60px;
            line-height: 63px;
            border-radius: 100%;
            text-align: center;
            color: $sass-main-color;
            font-size: 30px;
            border: 1px solid $sass-main-color;
            position: absolute;
            left: 0;
            transition: $transition;
        }
        h3 {
            font-size: 22px;
            margin-bottom: 15px;
            transition: $transition;
        }
        p {
            font-size: 15px;
            transition: $transition;
        }
        a {
            color: $sass-main-color;
            transition: $transition;
            display: inline-block;
            text-decoration: none;
        }
    }
    &::before {
        position: absolute;
        content: '';
        height: 100%;
        height: 100%;
        border-radius: 10px;
        width: 0;
        top: 0;
        right: 0;
        background-color: $sass-main-color;
        transition: $transition;
    }
    &:hover {
        &::before {
            width: 100%;
            border-radius: 0;
            left: 0;
            right: auto;
            border-radius: 10px;
        }
        .solution-content {
            .icon {
                color: $white-color;
                border-color: $white-color;
            }
            h3 {
                color: $white-color;
            }
            p {
                color: $white-color;
            }
            a {
                color: $white-color;
            }
        }
    }
}

/*Core Statics Style
======================================================*/
.core-statics-content {
    .statis-title {
        span {
            color: $sass-main-color;
            display: inline-block;
            margin-bottom: 10px;
        }
        h2 {
            font-size: 40px;
            margin-bottom: 20px;
        }
        p {
            margin-bottom: 20px;
        }
    }
    .core-list {
        ul {
            padding-left: 0;
            margin-bottom: 30px;
            li {
                list-style-type: none;
                margin-bottom: 20px;
                position: relative;
                padding-left: 30px;
                h3 {
                    font-size: 22px;
                    margin-bottom: 12px;
                }
                .icon {
                    color: $sass-main-color;
                    position: absolute;
                    left: 0;
                    top: 3px;
                }
            }
        }
    }
}
.core-img {
    position: relative;
    z-index: 1;
    .core-shape-1 {
        position: absolute;
        left: 30px;
        top: 150px;
        animation: movebounce 4s linear infinite;
    }
    .core-shape-2 {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        animation: movebounce2 4s linear infinite;
    }
}

/*Video Style
======================================================*/
.entro-video-area {
    position: relative;
    &.video-bg {
        background-image: url(/images/video-img/video-bg.png);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .video-shape {
        position: absolute;
        right: 150px;
        bottom: 120px;
        animation: movebounce 4s linear infinite;
    }
}
.intro-video {
    .image {
        position: relative;
        overflow: hidden;
        border-radius: 12px;
        img {
            overflow: hidden;
            transition: $transition;
        }
        .icon {
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            display: flex;
            z-index: 1;
            .play-btn {
                font-size: 24px;
                background-color: $sass-main-color;
                color: $white-color;
                text-align: center;
                height: 60px;
                width: 60px;
                line-height: 65px;
                border-radius: 100%;
                transition: $transition;
                cursor: pointer;
                i {
                    position: relative;
                    left: 3px;
                    top: 1px;
                }
                &:hover {
                    background-color: $white-color;
                    color: $sass-main-color;
                }
            }
            &::before {
                position: absolute;
                content: '';
                height: 70px;
                width: 70px;
                border-radius: 100%;
                background-color: rgba(7, 151, 255, .60%);
                animation: ripple 4s linear infinite;
                z-index: -1;
            }
            &::after {
                position: absolute;
                content: '';
                height: 80px;
                width: 80px;
                border-radius: 100%;
                background-color: rgba(7, 151, 255, .40%);
                animation: ripple 3s linear infinite;
                z-index: -1;
            }
        }
        &:hover {
            img {
                transform: scale(1.2);
                filter: blur(3px);
            }
        }
    }
}

/*Counter Style
======================================================*/
.single-counter-box {
    margin-bottom: 30px;
    .counter-content {
        position: relative;
        padding-left: 80px;
        .icon {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            i {
                font-size: 50px;
                color: $sass-main-color;
            }
            &::before {
                content: '';
                position: absolute;
                height: 60px;
                width: 60px;
                border-left: 20px solid transparent;
                border-top: 20px solid transparent;
                border-bottom: 35px solid $sass-main-color;
                border-right: 35px solid $sass-main-color;
                border-radius: 15px;
                opacity: .2;
                right: -10px;
                transform: rotate(10deg);

            }
        }
        h1 {
            margin-bottom: 10px;
            font-size: 44px;
        }
    }
}

/*Pricing Style
======================================================*/
.single-pricing-box {
    text-align: center;
    padding: 50px 30px;
    box-shadow: $box-shadow;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    background-color: $white-color;
    border-radius: 5px;
    h3 {
        font-size: 20px;
        margin-bottom: 22px;
    }
    h1 {
        font-size: 48px;
        color: $sass-main-color;
        margin-bottom: 5px;
    }
    p {
        margin-bottom: 22px;
    }
    .pricing-list {
        margin-bottom: 30px;
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                list-style-type: none;
                margin-bottom: 13px;
                color: $heading-color;
                i {
                    color: $sass-main-color;
                    padding-right: 10px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .pricing-btn {
        padding: 15px 50px;
        border: 1px solid $sass-main-color;
        color: $sass-main-color;
        font-weight: 500;
        display: inline-block;
        border-radius: 10px;
        transition: $transition;
        text-decoration: none;
        &:hover {
            background-color: $sass-main-color;
            color: $white-color;
        }
    }
    &.active {
        .pricing-btn {
            background-color: $sass-main-color;
            color: $white-color;
        }
    }
    .popular {
        position: absolute;
        background-color: $sass-main-color;
        padding: 5px 40px;
        text-align: center;
        color: $white-color;
        top: 20px;
        right: -32px;
        transform: rotate(45deg);
    }

}
.pricing-area {
    .tab {
        .react-tabs__tab-list {
            list-style-type: none;
            margin: auto auto 50px auto;
            text-align: center;
            max-width: 225px;
            background-color: $white-color;
            box-shadow: $box-shadow;
            padding-left: 0;
            padding: 15px 0;
            border-radius: 10px;
            li {
                display: inline-block;
                color: $heading-color;
                padding: 10px 20px;
                background: transparent;
                transition: $transition;
                border-radius: 5px;
                text-transform: capitalize;
                font-weight: 600;
                margin: 0 2px;

                &:hover, &:focus {
                    background: $sass-secondary-color;
                    color: $white-color;
                    border-color: $sass-secondary-color;
                }
            
                &.react-tabs__tab--selected {
                    background: $sass-secondary-color;
                    color: $white-color;
                    border-color: $sass-secondary-color;
                }
            }
        }
    }
    .container {
        position: relative;
        .pricing-shape {
            position: absolute;
            left: 100px;
            top: 140px;
            animation: movebounce2 5s linear infinite;
        }
    }
}

/*Reviews Style
======================================================*/
.single-reviews-box {
    padding: 0 30px 30px 30px;
    margin-bottom: 30px;
    margin-top: 25px;
    border-radius: 10px;
    transition: $transition;
    box-shadow: $box-shadow;
    
    .reviews-top-content {
        padding-bottom: 25px;
        margin-bottom: 25px;
        border-bottom: 1px solid #eeeeee;
        .client-img {
            position: relative;
            top: -25px;
            img {
                width: 80px;
                height: 80px;
                border-radius: 100%;
            }
        }
    }
    .reviews-bottom-content {
        h3 {
            font-size: 20px;
            margin-bottom: 5px;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            text-align: right;
            li {
                display: inline-block;
                padding-left: 5px;
                i {
                    color: #ffc107;
                }
            }
        }
    }
}
.reviews-slider {
    .swiper-pagination {
        position: initial;
        line-height: 1;
        .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            &.swiper-pagination-bullet-active {
                background: $sass-main-color;
            }
        }
    }
}

/*Blog Style
======================================================*/
.single-blog-card {
    background-color: $white-color;
    box-shadow: $box-shadow;
    margin-bottom: 30px;
    border-radius: 10px;
    .blog-img {
        overflow: hidden;
        position: relative;
        img {
            overflow: hidden;
            transition: $transition;
        }
        span {
            position: absolute;
            background-color: $sass-main-color;
            color: $white-color;
            padding: 10px 20px;
            top: 30px;
            right: 30px;
            border-radius: 10px;
            display: inline-block;
        }
    }
    .blog-content {
        padding: 30px;
        .name-and-date {
            margin-bottom: 15px;
            ul {
                padding-left: 0;
                margin-bottom: 0;
                li {
                    position: relative;
                    padding-left: 25px;
                    display: inline-block;
                    margin-right: 15px;
                    font-size: 14px;
                    i {
                        color: $sass-main-color;
                        position: absolute;
                        left: 0;
                        top: 2px;
                    }
                    a {
                        color: $heading-color;
                        font-weight: 500;
                        padding-left: 7px;
                        transition: $transition;
                        text-decoration: none;
                        &:hover {
                            color: $sass-main-color;
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        h2 {
            font-size: 22px;
            line-height: 1.4;
            margin-bottom: 15px;
            a {
                color: $heading-color;
                transition: $transition;
                text-decoration: none;
                &:hover {
                    color: $sass-main-color;
                }
            }
        }
        p {
            margin-bottom: 15px;
        }
        .learn-more {
            color: $sass-main-color;
            transition: $transition;
            text-decoration: none;
            &:hover {
                color: $sass-secondary-color;
            }
        }
    }
    &:hover {
        .blog-img {
            img {
                transform: scale(1.3);
            }
        }
    }
}
.blog-area {
    position: relative;
    .shape {
        .blog-shape-1 {
            position: absolute;
            right: 130px;
            top: 35%;
            animation: movebounce 5s linear infinite;
        }
    }
}

/*Contact Style
======================================================*/
.contact-and-subscribe-area {
    position: relative;
    z-index: 1;
    .contact-widget {
        background-color: #f8f8f8;
        padding: 50px;
        border-radius: 10px;
        margin-bottom: 30px;
        .contact-title {
            text-align: start;
            span {
                color: $sass-main-color;
                margin-bottom: 10px;
                display: inline-block;
            }
            h3 {
                margin-bottom: 30px;
                font-size: 30px;
            }
        }
        .contact-form {
            .form-group {
                margin-bottom: 25px;
                label {
                    margin-bottom: 7px;
                }
            }
        }
        .newsletter-img {
            margin-bottom: 30px;
        }
        .newsletter-form {
            .form-control {
                margin-bottom: 25px;
            }
        }
        .sass-default-btn {
            width: 100%;
            background-color: $sass-main-color;
            color: $white-color;
            display: block;
            &:hover {
                background-color: $heading-color;
                color: $white-color;
            }
        }
    }
    .shape {
        .contact-shape-1 {
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            animation: movebounce 5s linear infinite;
        }
        .contact-shape-2 {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 120px;
            z-index: -1;
            animation: movebounce2 5s linear infinite;
        }
    }
}
.list-unstyled {
    color: #ff0707;
    margin-top: 5px;
}
#msgSubmit {
    margin-top: 7px !important;
}

/*Footer Style
======================================================*/
.logo-area {
    margin-bottom: 25px;
    .logo-2 {
        display: none;
    }
    img {
        margin-bottom: 30px;
    }
    p {
        margin-bottom: 20px;
    }
    .social-links {
        ul {
            margin-bottom: 0;
            padding-left: 0;
            li {
                display: inline-block;
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    height: 40px;
                    width: 40px;
                    line-height: 45px;
                    background-color: $white-color;
                    border-radius: 100%;
                    box-shadow: $box-shadow;
                    display: inline-block;
                    text-align: center;
                    font-size: 15px;
                    transition: $transition;
                    text-decoration: none;
                    &:hover {
                        background-color: $sass-main-color;
                        color: $white-color;
                        transform: translateY(-5px);
                    }
                }
            }
        }
    }
    &.style2 {
        .social-links {
            ul {
                li {
                    a {
                        color: $body-color;
                        text-decoration: none;

                        &:hover {
                            color: $white-color;
                            background-color: $app-main-color;
                        }
                    }
                }
            }
        }
    }
    &.style3 {
        .social-links {
            ul {
                li {
                    a {
                        text-decoration: none;
                        &:hover {
                            background-color: $consulting-main-color;
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
    &.style4 {
        p {
            color: $white-color;
            font-family: $heading-font-family-2;
        }
        .social-links {
            ul {
                li {
                    a {
                        text-decoration: none;
                        color: $political-main-color;
                        background-color: rgba(255, 255, 255, 0.1);
                        &:hover {
                            background-color: $political-main-color;
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
    &.style5 {
        h3 {
            font-family: $body-font-family;
            color: $white-color;
        }
        p {
            color: $white-color;
            font-family: $heading-font-family-2;
        }
        .social-links {
            ul {
                li {
                    a {
                        text-decoration: none;
                        color: $white-color;
                        border: 1px solid rgba(255, 255, 255, 0.05);
                        background-color: transparent;
                        &:hover {
                            background-color: $currency-main-color;
                            color: $white-color;
                        }
                    }
                }
            }
        }
        .newsletter-form {
            position: relative;
            margin-bottom: 30px;
            .currency-default-btn {
                position: absolute;
                right: 0;
                top: 0;
                height: 55px;
                padding: 16px 30px;
                border-radius: 5px;
                border: none;
                background-color: $currency-main-color;
                color: $white-color;
            }
        }
    }
    &.style6 {
        h3 {
            font-family: $body-font-family;
            color: $white-color;
        }
        p {
            color: $white-color;
            font-family: $heading-font-family-2;
        }
        .social-links {
            ul {
                li {
                    a {
                        color: $white-color;
                        border: 1px solid rgba(255, 255, 255, 0.05);
                        background-color: transparent;
                        text-decoration: none;
                        &:hover {
                            background-color: $nft-main-color;
                            color: $white-color;
                        }
                    }
                }
            }
        }
        .newsletter-form {
            position: relative;
            margin-bottom: 30px;
            .nft-default-btn  {
                position: absolute;
                right: 0;
                top: 0;
                background-color: $nft-main-color;
                color: $white-color;
            }
        }
    }
}
.footer-widjet {
    margin-bottom: 30px;
    h3 {
        font-size: 22px;
        margin-bottom: 30px;
    }
    .link-list {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                list-style-type: none;
                position: relative;
                padding-left: 20px;
                margin-bottom: 12px;
                i {
                    position: absolute;
                    left: 0;
                    top: 2px;
                    font-size: 14px;
                }
                a {
                    color: $body-color;
                    transition: $transition;
                    text-decoration: none;
                    i {
                        color: $sass-main-color;
                    }
                    &:hover {
                        color: $sass-main-color;
                    }
                }
            }
        }
    }
    &.style2 {
        .link-list {
            ul {
                li {
                    i {
                        color: $app-main-color;
                    }
                    a {
                        &:hover {
                            color: $app-main-color;
                        }
                    }
                }
            }
        }
    }
    &.style3 {
        h3 {
            font-family: $heading-font-family-4;
        }
        ul {
            li {
                i {
                    color: $consulting-main-color;
                }
                a {
                    &:hover {
                        color: $consulting-main-color;
                    }
                }
            }
        }
    }
    &.style4 {
        h3 {
            color: $white-color;
            font-family: $body-font-family;
        }
        .link-list {
            ul {
                li {
                    i {
                        color: $political-main-color;
                    }
                    a {
                        color: $white-color;
                        &:hover {
                            color: $political-main-color;
                        }
                    }
                }
            }
        }
    }
    &.style5 {
        h3 {
            color: $white-color;
            font-family: $body-font-family;
        }
        .link-list {
            ul {
                li {
                    i {
                        color: $currency-main-color;
                    }
                    a {
                        color: $white-color;
                        &:hover {
                            color: $currency-main-color;
                        }
                    }
                }
            }
        }
        &.copany {
            padding-left: 30px;
        }
    }
    &.style6 {
        h3 {
            color: $white-color;
            font-family: $body-font-family;
        }
        .link-list {
            ul {
                li {
                    i {
                        color: $nft-main-color;
                    }
                    a {
                        color: $white-color;
                        font-family: $heading-font-family-2;
                        &:hover {
                            color: $nft-main-color;
                        }
                    }
                }
            }
        }
       
    }
}
.get-in-touch {
    margin-bottom: 30px;
    h3 {
        font-size: 22px;
        margin-bottom: 30px;
        font-family: $heading-font-family-4;
    }
    ul {
        padding-left: 0;
        margin-bottom: 0;
        li {
            margin-bottom: 15px;
            list-style-type: none;
            position: relative;
            padding-left: 25px;
            a {
                color: $body-color;
                transition: $transition;
                text-decoration: none;
                &:hover {
                    color: $app-main-color;
                }
            }
            i {
                position: absolute;
                color: $app-main-color;
                font-size: 16px;
                left: 0;
                top: 2px;
            }
            p {
                margin-bottom: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &.style3 {
        h3 {
            color: $heading-color-3;
        }
        ul {
            li {
                a {
                    &:hover {
                        color: $consulting-main-color;
                    }
                }
                i {
                    color: $consulting-main-color;
                }
            }
        }
    }
    &.style4 {
        h3 {
            color: $white-color;
            font-family: $body-font-family;
        }
        ul {
            li {
                color: $white-color;
                a {
                    color: $white-color;
                    &:hover {
                        color: $currency-main-color;
                    }
                }
                i {
                    color: $currency-main-color;
                }
                p {
                    color: $white-color;
                }
            }
        }
    }
}
.download-link {
    h3 {
        font-size: 22px;
        margin-bottom: 30px;
    }
    .fotter-img {
        a {
            display: block;
        }
        img {
            margin-bottom: 30px;
        }
    }
}
.agency-subscribe {
    margin-bottom: 30px;
    h3 {
        font-size: 22px;
        margin-bottom: 30px;
        font-family: $heading-font-family-3;
    }
    .newsletter-form {
        background-color: $white-color;
        box-shadow: $box-shadow;
        border-radius: 10px;
        padding: 30px;
        .form-control {
            margin-bottom: 30px;
        }
        .agency-default-btn {
            width: 100%;
        }
        
    }
    &.style2 {
        h3 {
            color: $white-color;
            font-family: $body-font-family;
        }
        .newsletter-form {
            border-radius: 0;
            .political-default-btn {
                width: 100%;
                background-color: $political-main-color;
                color: $white-color;
                position: relative;
                &:hover {
                    background-color: $heading-color-4;
                    color: $white-color;
                }
            }
        }
    }
    &.style3 {
        h3 {
            color: $white-color;
            font-family: $body-font-family;
        }
        .newsletter-form {
            background: rgba(255, 255, 255, 0.06);
            .form-control {
                background-color: transparent;
                color: $white-color;
                border-color: #eeeeee;
                &::placeholder {
                    color: $white-color;
                }
            }
            .exchange-default-btn {
                background-color: $exchange-main-color;
                color: $white-color;
                width: 100%;
                &:hover {
                    background-color: #030734;
                    color: $white-color;
                }
            }
        }
    }
}
.copy-right-area {
    text-align: center;
    p {
        padding-top: 25px;
        padding-bottom: 25px;
        border-top: 1px solid #eeeeee;
        a {
            color: $sass-main-color;
        }
    }
    &.style3 {
        p {
            a {
                color: $app-main-color;
            }
        }
    }
    &.style4 {
        p {
            a {
                color: $consulting-main-color;
            }
        }
    }
    &.style5 {
        background-color: #19191b;
        p {
            color: $white-color;
            border-top: 0;
            a {
                color: $photography-main-color !important;
            }
        }
    }
    &.style5 {
        p {
            a {
                color: #025de4;
            }
        }
    }
    &.style7 {
        background-color: $heading-color-4;
        border-top: 1px solid rgba(255, 255, 255, 0.05);
        p {
            color: $white-color;
            border: none;
            a {
                color: $political-main-color;
            }
        }
    }
    &.style8 {
        border: 1px solid rgba(255, 255, 255, 0.05);
        p {
            color: $white-color;
            border: none;
            a {
                color: $currency-main-color;
            }
        }
    }
    &.style9 {
        border: 1px solid rgba(255, 255, 255, 0.05);
        p {
            color: $white-color;
            border: none;
            a {
                color: $nft-main-color;
            }
        }
    }
}
.agency-footer-area {
    position: relative;
    z-index: 1;
    .footer-shape-1 {
        position: absolute;
        bottom: 0;
        left: 100px;
        z-index: -1;
    }
    .footer-shape-2 {
        position: absolute;
        bottom: 0;
        right: 130px;
        z-index: -1;
    }
}
.validation-danger {
    color: #ff0707;
    padding-top: 7px;
}

/*
Go To Top Style
======================================================*/
.go-top {
	position: fixed;
	cursor: pointer;
	bottom: 20px;
	right: 20px;
    background-color: $sass-main-color;
	z-index: 9999;
	width: 40px;
	text-align: center;
	height: 40px;
	line-height: 40px;
    border-radius: 100%;
	transition: .9s;

    &.style2 {
        background-color: $app-main-color;
    }
    &.style3 {
        background-color: $consulting-main-color;
    }
    &.style5 {
        background-color: $consulting-main-color;
    }
    &.style5 {
        background-color: $photography-main-color;
    }
    &.style7 {
        background-color: $political-main-color;
    }
    &.style8 {
        background-color: $currency-main-color;
    }
    &.style9 {
        background-color: $nft-main-color;
    }
    &.style10 {
        background-color: $exchange-main-color;
    }
	i {
		color: $white-color;
		transition: 0.5s;
		font-size: 22px;
	}
	&:hover {
		color: #ffffff;
        background: $heading-color;
		i {
            color: $white-color;
		}
	}
}


/*Acquaintance Style
======================================================*/
.acquaintance-content {
    .acquaintance-title {
        span {
            color: $app-main-color;
            display: inline-block;
            margin-bottom: 10px;
        }
        h2 {
            font-size: 40px;
            margin-bottom: 20px;
            font-family: $heading-font-family-2;
        }
        p {
            margin-bottom: 25px;
        }
    }
    .acquaintance-list {
        margin-bottom: 35px;
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                list-style-type: none;
                position: relative;
                padding-left: 70px;
                margin-bottom: 25px;
                .icon {
                    color: $white-color;
                    background-color: $app-main-color;
                    text-align: center;
                    height: 45px;
                    width: 45px;
                    line-height: 48px;
                    font-size: 20px;
                    border-radius: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                h3 {
                    font-size: 22px;
                    font-family: $heading-font-family-2;
                    margin-bottom: 10px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

/*Design Style
======================================================*/
.design-content {
    .design-title {
        span {
            color: $app-main-color;
            display: inline-block;
            margin-bottom: 10px;
        }
        h2 {
            font-size: 40px;
            margin-bottom: 25px;
            font-family: $heading-font-family-2;
        }
    }
    .design-text {
        margin-bottom: 30px;
    }
}
.design-image {
    position: relative;
    .doller {
        position: absolute;
        background-color: $white-color;
        box-shadow: $box-shadow;
        padding: 30px;
        right: -20px;
        top: 40%;
        transform: translateY(-50%);
        border-radius: 10px;
        animation: movebounce 5s linear infinite;
        img {
            border-radius: 10px;
            margin-bottom: 15px;
        }
        h1 {
            font-size: 28px;
        }
    }
    .design-shape {
        position: absolute;
        right: 80px;
        bottom: 20px;
        animation: movebounce2 5s linear infinite;
    }
}

/*Pricing Style
======================================================*/
.wrapper-full {
    &.hide {
        display: none;
    }
}
.app-single-pricing-card {
    padding: 30px;
    box-shadow: $box-shadow;
    margin-bottom: 30px;
    border-radius: 10px;
    background-color: $white-color;
    .pricing-top-content {
        position: relative;
        padding-left: 85px;
        margin-bottom: 30px;
        .icon {
            background-color: #efedf0;
            color: $app-main-color;
            font-size:35px;
            height: 67px;
            width: 70px;
            line-height: 70px;
            text-align: center;
            border-radius: 10px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        p {
            margin-bottom: 10px;
        }
        h2 {
            font-family: $heading-font-family-2;
            font-size: 30px;
            span {
                font-size: 13px;
                font-weight: 500;
            }
        }
    }
    .pricing-list {
        margin-bottom: 30px;
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                list-style-type: none;
                position: relative;
                padding-left: 20px;
                margin-bottom: 12px;
                i {
                    color: $app-main-color;
                    position: absolute;
                    left: 0;
                    top: 2px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    
    &.active {
        .app-default-btn {
            background-color: $app-main-color;
            color: $white-color;
        }
    }
}

/*Reviews Style
======================================================*/
.app-reviews-section {
    span {
        color: $app-main-color;
        display: inline-block;
        margin-bottom: 10px;
    }
    h2 {
        font-size: 40px;
        margin-bottom: 30px;
        font-family: $heading-font-family-2;
    }

}
.app-reviews-slider {
    .swiper-pagination {
        position: initial;
        text-align: left;
        .swiper-pagination-bullet {
            width: 11px;
            height: 11px;
            background: #cccccc;
            opacity: 1;

            &.swiper-pagination-bullet-active {
                background: $app-main-color;
            }
        }
    }
}
.app-reviews-box {
    margin-bottom: 40px;
    .rating-star {
        margin-bottom: 15px;
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                margin-right: 5px;
                i {
                    color: #ffc107;
                }
            }
        }
    }
    p {
        margin-bottom: 30px;
    }
    .clients-profile {
        position: relative;
        padding-left: 100px;
        margin-bottom: 10px;
        .clients-img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 80px;
            height: 80px;
        }
        h3 {
            font-family: $heading-font-family-2;
            font-size: 22px;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 0;
        }
    }
}
.app-reviews-img {
    position: relative;
    padding-right: 50px;
    .video-player {
        background-color: $app-main-color;
        padding: 10px;
        padding-right: 20px;
        border-radius: 40px;
        position: absolute;
        right: 0;
        top: 50px;
        .play-btn {
            color: $white-color;
            position: relative;
            top: -4px;
            transition: $transition;
            cursor: pointer;
            i {
                color: $app-main-color;
                background-color: $white-color;
                height: 50px;
                width: 50px;
                line-height: 55px;
                border-radius: 100%;
                display: inline-block;
                text-align: center;
                margin-right: 10px;
                padding-left: 2px;
                position: relative;
                top: 4px;
                transition: $transition;
            }
        }
        &:hover {
            .play-btn {
                i {
                    background-color: $app-secondary-color;
                    color: $white-color;
                }
            }
        }
    }
}

/*Download Style
======================================================*/
.app-download-area {
    background-color: $app-secondary-color;
    position: relative;
    z-index: 1;
    &::before {
        position: absolute;
        content: '';
        background-color: $white-color;
        height: 100px;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }
    .download-shape-6 {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
}
.download-image-content {
    position: relative;
    .dowload-shape-1 {
        position: absolute;
        bottom: 200px;
        right: 60px;
        animation: movebounce 5s linear infinite;
    }
    .dowload-shape-2 {
        position: absolute;
        bottom: 350px;
        right: 90px;
        animation: movebounce2 6s linear infinite;
    }
    .dowload-shape-3 {
        position: absolute;
        bottom: 500px;
        right: 50px;
        animation: movebounce 7s linear infinite;
    }
    .dowload-shape-4 {
        position: absolute;
        bottom: 200px;
        left: -30px;
        animation: rotateme 9s linear infinite;

    }
    .dowload-shape-5 {
        position: absolute;
        top: 30px;
        left: -10px;
        animation: movebounce2 6s linear infinite;
    }
}
.download-content {
    margin-top: 30px;
    .download-title {
        span {
            color: $app-main-color;
            display: inline-block;
            margin-bottom: 10px;
        }
        h2 {
            font-size: 40px;
            margin-bottom: 30px;
            font-family: $heading-font-family-2;
            color: $white-color;
        }
    }
    p {
        margin-bottom: 30px;
        color: $white-color;
    }
    .download-links {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                margin-right: 15px;
                img {
                    border-radius: 12px;
                }
            }
        }
    }
}

/*Team Style
======================================================*/
.single-team-card {
    text-align: center;
    box-shadow: $box-shadow;
    border-radius: 100% 100% 10px 10px;
    margin-bottom: 30px;
    transition: $transition;
    .team-content {
        padding: 30px;
        h3 {
            font-size: 22px;
            font-family: $heading-font-family-2;
            margin-bottom: 20px;
            span {
                color: $body-color;
                font-size: 14px;
                font-weight: 400;
                font-family: $body-font-family;
            }
        }
        .social-links {
            ul {
                padding-left: 0;
                margin-bottom: 0;
                li {
                    display: inline-block;
                    margin-right: 10px;
                    a {
                        height: 40px;
                        width: 40px;
                        line-height: 42px;
                        border: 1px solid #eeeeee;
                        color: $body-color;
                        display: inline-block;
                        border-radius: 100%;
                        transition: $transition;
                        text-decoration: none;
                        &:hover {
                            background-color: $app-main-color;
                            color: $white-color;
                            border-color: $app-main-color;
                            transform: translateY(-7px);
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    &:hover {
        transform: translateY(-10px);
    }
}
.team-area {
    .container {
        position: relative;
        .team-shape {
            position: absolute;
            top: -50px;
            right: 50px;
            animation: movebounce 5s linear infinite;
        }
    }
}

/*Watch Video Style
======================================================*/
.watch-video-area {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    text-align: center;
    &::before {
        position: absolute;
        content: '';
        height: 100%;
        width: 100%;
        background-color: $heading-color;
        opacity: .60;
        top: 0;
        left: 0;
    }
    .app-section-title {
        h2 {
            color: $white-color;
        }
    }
    .video-icon {
        .icon {
            position: relative;
            z-index: 1;
            .play-btn {
                font-size: 24px;
                background-color: $app-main-color;
                color: $white-color;
                text-align: center;
                height: 60px;
                width: 60px;
                line-height: 65px;
                border-radius: 100%;
                transition: $transition;
                display: inline-block;
                position: relative;
                top: 5px;
                left: 10px;
                cursor: pointer;
                i {
                    position: relative;
                    left: 3px;
                    top: 1px;
                }
                &:hover {
                    background-color: $white-color;
                    color: $app-main-color;
                }
                &::before {
                    position: absolute;
                    content: '';
                    height: 70px;
                    width: 70px;
                    margin: auto;
                    border-radius: 100%;
                    background-color: rgba(245, 116, 41, .60%);
                    animation: ripple 4s linear infinite;
                    z-index: -1;
                    left: -5px;
                    top: -5px;
                } 
            }
        }
    }
}

/*Choose Video Style
======================================================*/
.choose-area {
    .app-section-title {
        margin-bottom: 50px;
    }
}
.class-for-frame {
    position: relative;
    .choose-img-frame {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        margin: 0 auto;
        width: auto;
        height: auto;
        top: -10px;
        z-index: 2;
    }
    .choose-img {
        margin-bottom: 40px;
        text-align: center;
    }
}
.choose-slider {
    .swiper-pagination {
        position: initial;
        .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            opacity: 1;
            background: #cccccc;

            &:hover, &.swiper-pagination-bullet-active {
                background: $app-main-color;
            }
        }
    }
}

/*More With Us Style
======================================================*/
.more-us-content {
    background-image: url(../../images/shape/shape-10.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $app-secondary-color;
    padding: 100px;
    border-radius: 20px;
    .more-us-title {
        span {
            color: $app-main-color;
            display: inline-block;
            margin-bottom: 10px;
        }
        h2 {
            font-size: 40px;
            font-family: $heading-font-family-2;
            color: $white-color;
            margin-bottom: 0;
        }
    }
    .more-us-btn {
        text-align: right;
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                margin-right: 15px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

/*Blog Style
======================================================*/
.app-single-blog-card {
    box-shadow: $box-shadow;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 30px;
    .blog-img {
        overflow: hidden;
        transition: $transition;
        border-radius: 10px;
        img {
            transition: $transition;
            border-radius: 10px;
        }
    }
    .blog-content {
        padding: 15px;
        padding-top: 30px;
        .date-and-comment {
            margin-bottom: 20px;
            ul {
                padding-left: 0;
                margin-bottom: 0;
                li {
                    display: inline-block;
                    position: relative;
                    margin-right: 15px;
                    padding-left: 20px;
                    i {
                        position: absolute;
                        color: $app-main-color;
                        left: 0;
                        top: 2px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        h3 {
            font-size: 22px;
            font-family: $heading-font-family-2;
            margin-bottom: 25px;
            line-height: 1.4;
            a {
                color: $heading-color;
                transition: $transition;
                text-decoration: none;
                &:hover {
                    color: $app-main-color;
                }
            }
        }
    }
    &:hover {
        .blog-img {
            img {
                transform: scale(1.2);
            }
        }
    }
}
.app-copy-right-area {
    .container {
        border-top: 1px solid #dad9d9;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .download-links {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                margin-right: 15px;
                &:last-child {
                    margin-right: 0;
                }
                img {
                    border-radius: 10px;
                }
            }
        }
    }
    p {
        a {
            color: $app-main-color;
        }
    }
}

/*Agency Demo Style
=================================================
================================================*/

/*Banner Style
======================================================*/
.agency-banner-area {
    padding-top: 200px;
    position: relative;
    z-index: 1;

    .container-fluid {
        max-width: 1690px;
        padding-left: 30px;
        padding-right: 30px;
    }
    &::before {
        position: absolute;
        content: '';
        background-color: #f9f5f4;
        height: 80px;
        width: 100%;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
}
.agency-banner-content {
    span {
        color: $app-main-color;
        margin-bottom: 10px;
        font-family: $body-font-family-2;
        display: inline-block;
    }
    h1 {
        font-size: 72px;
        font-family: $heading-font-family-3;
        color: $heading-color-2;
        font-weight: 800;
        margin-bottom: 20px;
    }
    p {
        font-family: $body-font-family-2;
        margin-bottom: 30px;
    }
}
.trasted-content {
    padding: 50px;
    box-shadow: $box-shadow;
    border-radius: 10px;
    background-color: $white-color;
    .trasted-text {
        h3 {
            font-size: 22px;
            font-family: $heading-font-family-3;
            color: $heading-color-2;
        }
    }
}
.trasted-slider {
    &.owl-carousel {
        .owl-item {
            img {
                width: auto;
                margin: auto;
            }
        }
    }
}

/*Features Style
======================================================*/
.agency-single-features-card {
    text-align: center;
    box-shadow: $box-shadow;
    padding: 35px;
    margin-bottom: 30px;
    background-color: $white-color;
    border-radius: 10px;
    transition: $transition;
    .icon {
        height: 100px;
        width: 100px;
        line-height: 110px;
        text-align: center;
        background-color: #f9f5f4;
        border-radius: 100%;
        color: $app-main-color;
        font-size: 45px;
        margin: auto;
        margin-bottom: 30px;
        transition: $transition;
    }
    h3 {
        font-size: 22px;
        color: $heading-color-2;
        font-family: $heading-font-family-3;
        margin-bottom: 15px;
    }
    p {
        font-family: $body-font-family-2;
    }
    .btn {
        color: $app-main-color;
        position: relative;
        padding-right: 25px;
        i {
            position: absolute;
            right: 0;
            top: 8px;
        }
    }
    &:hover {
        transform: translateX(-10px);
        .icon {
            transform: rotate(30deg);
            background-color: $app-main-color;
            color: $white-color;
        }
    }
}
.agency-features-area {
    .container {
        position: relative;
        z-index: 1;
        .features-shape-1 {
            position: absolute;
            right: 0;
            top: 20px;
            animation: movebounce2 5s linear infinite;
        }
        .features-shape-2 {
            position: absolute;
            left: -30px;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
            animation: movebounce 4s linear infinite;
        }
    }
}

/*About Style
======================================================*/
.agency-about-content {
    .agency-about-title {
        span {
            color: $app-main-color;
            display: inline-block;
            margin-bottom: 10px;
            font-family: $body-font-family-2;
        }
        h2 {
            font-size: 40px;
            margin-bottom: 25px;
            font-family: $heading-font-family-3;
            color: $heading-color-2;
        }
    }
    .agency-text {
        margin-bottom: 30px;
        p {
            margin-bottom: 20px;
            font-family: $heading-font-family-2;
        }
    }
}
.agency-about-image {
    position: relative;
    .about-shape-1 {
        position: absolute;
        bottom: 50px;
        right: 200px;
        animation: movebounce2 6s linear infinite;
    }
    .about-shape-2 {
        position: absolute;
        top: 30px;
        left: 30px;
        animation: movebounce 5s linear infinite;
    }
}
.agency-about-area {
    position: relative;
    .about-shape-3 {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

/*Services Style
======================================================*/
.agency-services-area {
    overflow: hidden;
    .container-fluid {
        overflow: hidden;
        left: calc((100% - 1320px)/2);
        position: relative;
    }
}
.agency-single-services-card {
    background-color: $white-color;
    box-shadow: $box-shadow;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    overflow: hidden;

    .services-content {
        padding: 30px;
        padding-left: 10px;
        .icon {
            height: 70px;
            width: 70px;
            line-height: 75px;
            text-align: center;
            background-color: #f9f5f4;
            border-radius: 100%;
            color: $app-main-color;
            font-size: 30px;
            margin-bottom: 20px;
            transition: $transition;
        }
        h3 {
            font-size: 22px;
            color: $heading-color-2;
            font-family: $heading-font-family-3;
            margin-bottom: 15px;
        }
        p {
            font-family: $body-font-family-2;
            margin-bottom: 15px;
        }
        .read-btn {
            color: $app-main-color;
            position: relative;
            padding-right: 25px;
            i {
                position: absolute;
                right: 0;
                top: 2px;
            }
        }
    }
    .services-shape-1 {
        position: absolute;
        right: -30px;
        top: -30px;
        z-index: -1;
    }
    img {
        width: auto !important;
    }
}
.services-slider {
    .swiper-pagination {
        position: initial;
        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            &.swiper-pagination-bullet-active {
                background-color: $app-main-color;
            }
        }
    }
}

/*Choose Style
======================================================*/
.agency-choose-area {
    overflow: hidden;
    .container {
        position: relative;
        .choose-shape-1 {
            position: absolute;
            left: -100px;
            top: 50%;
            transform: translateY(-50%);
            animation: movebounce 4s linear infinite;
        }
    }
}
.agency-choose-content {
    .agency-choose-title {
        span {
            color: $app-main-color;
            display: inline-block;
            margin-bottom: 10px;
            font-family: $body-font-family-2;
        }
        h2 {
            font-size: 40px;
            margin-bottom: 20px;
            font-family: $heading-font-family-3;
            color: $heading-color-2;
        }
        p {
            margin-bottom: 25px;
            font-family: $heading-font-family-2;
        }
    }
    .choose-list {
        margin-bottom: 25px;
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                list-style-type: none;
                position: relative;
                padding-left: 35px;
                margin-bottom: 20px;
                i {
                    position: absolute;
                    color: $app-main-color;
                    font-size: 22px;
                    top: 1px;
                    left: 0;
                }
                h3 {
                    font-size: 22px;
                    color: $heading-color-2;
                    font-family: $heading-font-family-3;
                    margin-bottom: 10px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

/*Portfolio Style
======================================================*/
.portfolio-card {
    margin-bottom: 30px;
    .portfolio-img {
        position: relative;
        .caption-one {
            background-color: $white-color;
            padding: 15px;
            border-radius: 10px;
            text-align: left;
            position: absolute;
            bottom: 20px;
            left: 20px;
            right: 20px;
            transition: $transition;
            h3 {
                font-size: 18px;
                font-family: $heading-font-family-3;
                color: $heading-color-2;
            }
        }
        .caption-two {
            background-color: $heading-color-2;
            position: absolute;
            bottom: 30px;
            left: 20px;
            right: 20px;
            border-radius: 10px;
            padding: 25px;
            transition: $transition;
            h3 {
                font-size: 18px;
                font-family: $heading-font-family-3;
                color: $heading-color-2;
                color: $white-color;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 10px;
                color: $white-color;
            }
            a {
                color: $white-color;
                i {
                    position: relative;
                    top: 3px;
                    padding-left: 7px;
                }
            }
        }
    }
}
.portfolio-slider {
    .swiper-pagination {
        position: initial;
        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            &.swiper-pagination-bullet-active {
                background-color: $app-secondary-color;
            }
        }
    }
}

/*Video Style
======================================================*/
.agency-intro-video-area {
    position: relative;
    overflow: hidden;
    z-index: 1;
    .agrncy-video {
        position: relative;
            position: relative;
            overflow: hidden;
            border-radius: 18px;
            img {
                overflow: hidden;
                transition: $transition;
            }
            .icon {
                position: absolute;
                top: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                align-items: center;
                justify-content: center;
                display: flex;
                z-index: 1;
                .play-btn {
                    font-size: 24px;
                    background-color: $white-color;
                    color: $app-main-color ;
                    text-align: center;
                    height: 60px;
                    width: 60px;
                    line-height: 65px;
                    border-radius: 100%;
                    transition: $transition;
                    cursor: pointer;
                    i {
                        position: relative;
                        left: 3px;
                        top: 1px;
                    }
                    &:hover {
                        background-color: $heading-color-2;
                        color: $white-color;
                    }
                }
                &::before {
                    position: absolute;
                    content: '';
                    height: 70px;
                    width: 70px;
                    border-radius: 100%;
                    background-color: rgba(244, 115, 42, .60%);
                    animation: ripple 4s linear infinite;
                    z-index: -1;
                }
                &::after {
                    position: absolute;
                    content: '';
                    height: 80px;
                    width: 80px;
                    border-radius: 100%;
                    background-color: rgba(244, 115, 42, .40%);
                    animation: ripple 3s linear infinite;
                    z-index: -1;
                }
            }
            &:hover {
                img {
                    transform: scale(1.2);
                    filter: blur(3px);
                }
            }
    }
    .video-shape {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }
    .video-shape-2 {
        position: absolute;
        top: 0;
        left: 15%;
        animation: spin-reverse 9s linear infinite;
    }
    .video-shape-3 {
        position: absolute;
        top: 0;
        right: 15%;
        animation: movebounce 4s linear infinite;
    }
}

/*Counter Style
======================================================*/
.agency-counter-area {
    .container {
        position: relative;
        .counter-shape-1 {
            position: absolute;
            bottom: 20px;
            right: 15px;
            animation: movebounce 4s linear infinite;
        }
    }
}
.agency-counter-box {
    box-shadow: $box-shadow;
    padding: 20px;
    margin-left: 30px;
    margin-right: 30px;
    position: relative;
    background-color: $white-color;
    border-radius: 10px;
    z-index: 1;
    margin-bottom: 30px;
    .counter-content {
        padding: 20px;
        text-align: center;
        border: 8px solid #f9f5f4;
        z-index: 2;
        border-radius: 10px;
        background-color: $white-color;
        h1 {
            font-size: 48px;
            font-family: $heading-font-family-3;
            color: $app-main-color;
            margin-bottom: 5px;
        }
        p {
            font-family: $body-font-family-2;
        }
    }
    &::before {
        position: absolute;
        content: '';
        height: 100px;
        width: auto;
        left: -30px;
        right: -30px !important;
        top: 50%;
        border-radius: 10px;
        transform: translateY(-50%);
        border: 1px solid #eeeeee;
        z-index: -1;
    }
}

/*Testimonial Style
======================================================*/
.agency-testimonial-card {
    padding: 40px;
    box-shadow: $box-shadow;
    border-radius: 10px;
    margin: 10px 10px 30px 10px;
    .testimonial-content {
        .rating-star {
            margin-bottom: 15px;
            ul {
                padding-left: 0;
                margin-bottom: 0;
                li {
                    display: inline-block;
                    margin-right: 5px;
                    &:last-child {
                        margin-left: 0;
                    }
                    i {
                        color: #ffc107;
                    }
                }
            }
        }
        p {
            margin-bottom: 20px;
            font-family: $body-font-family-2;
        }
        h3 {
            font-family: $heading-font-family-3;
            font-size: 22px;
            color: $heading-color-2;
            margin-bottom: 10px;
        }
        span {
            font-family: $body-font-family-2;
        }
    }
    .testimonial-image {
        img {
            width: 200px;
            border-radius: 70%;
        }
    }
}
.agency-testimonial-slider {
    .swiper-button-next, .swiper-button-prev {
        &::after {
            color: $app-secondary-color;
            font-size: 25px;
        }
    }
}
.agency-testimonial-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
    .testimonial-shape-1 {
        position: absolute;
        top: 100px;
        right: 23%;
        z-index: -1;
        animation: movebounce 6s linear infinite;
    }
    .testimonial-shape-2 {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .testimonial-shape-3 {
        position: absolute;
        top: 50px;
        left: 20%;
        animation: movebounce2 6s linear infinite;
    }
}

/*Pricing Style
======================================================*/
.agency-pricing-area {
    .container {
        position: relative;
        z-index: 1;
        .pricing-shape-1 {
            position: absolute;
            bottom: -20px;
            right: -50px;
            z-index: -1;
            animation: movebounce 6s linear infinite;
        }
        .pricing-shape-2 {
            position: absolute;
            bottom: 220px;
            right: -50px;
            z-index: -1;
            animation: movebounce2 6s linear infinite;
        }
    }
}
.agency-pricing-tabs {
    .react-tabs {
        text-align: center;
        .react-tabs__tab-list {
            list-style-type: none;
            margin: 0 0 60px 0;
            text-align: center;
            background-color: $white-color;
            box-shadow: $box-shadow;
            padding: 0;
            border-radius: 10px;
            border: none;
            display: inline-block;

            li {
                display: inline-block;
                color: $heading-color;
                padding: 10px 20px;
                background: transparent;
                transition: $transition;
                border-radius: 5px;
                text-transform: capitalize;
                font-weight: 600;
                bottom: 0;

                &:hover, &:focus {
                    background: $app-main-color;
                    color: $white-color;
                    border-color: $app-main-color;
                    &::after {
                        background-color: transparent;
                    }
                }
                 
                &.react-tabs__tab--selected {
                    background: $app-main-color;
                    color: $white-color;
                    border-color: $app-main-color;
                }
            }
        }
    }
    .react-tabs__tab-panel {
        background-color: $white-color;
        border-radius: 10px;
        box-shadow: $box-shadow;
        margin-bottom: 30px;
    }
}
.agency-pricing-box {
    padding: 40px;
    transition: $transition;
    border-radius: 10px;
    position: relative;
    text-align: left;
    .pricing-content {
        h1 {
            font-size: 48px;
            color: $heading-color-2;
            font-family: $heading-font-family-3;
            margin-bottom: 20px;
            transition: $transition;
            span {
                color: $body-color;
                font-size: 16px;
                font-weight: 400;
                font-family: $body-font-family-2;
                transition: $transition;
            }
        }
        h6 {
            font-size: 22px;
            margin-bottom: 15px;
            transition: $transition;
        }
        p {
            font-family: $body-font-family-2;
            margin-bottom: 20px;
            transition: $transition;
        }
        .pricing-list {
            margin-bottom: 30px;
            ul {
                padding-left: 0;
                margin-bottom: 0;
                li {
                    list-style-type: none;
                    position: relative;
                    padding-left: 25px;
                    margin-bottom: 13px;
                    transition: $transition;
                    font-family: $body-font-family-2;
                    i {
                        color: $app-main-color;
                        position: absolute;
                        left: 0;
                        transition: $transition;
                        &.flaticon-cancel {
                            color: $body-color;
                            font-size: 12px;
                            transition: $transition;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    del {
                        color: #8d8c8c;
                    }
                }
            }
        }
    }
    &:hover, &.active {
        background-color: $heading-color-2;
        transform: translateY(-30px);
        .pricing-content {
            h1 {
                color: $white-color;
                span {
                    color: $white-color;
                }
            }
            h6 {
                color: $white-color;
            }
            p {
                color: $white-color;
            }
            .pricing-list {
                ul {
                    li{
                        color:  $white-color;
                        i {
                            color: $app-main-color;
                            &.flaticon-cancel {
                                color: $white-color;
                            }
                        }
                        del {
                            color: $white-color;
                        }
                    }
                }
            }
            .agency-default-btn {
                background-color: $app-main-color;
                color: $white-color;
                border-color: $app-main-color;
            }
        }
    }
    .popular {
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: $white-color;
        color: $app-main-color;
        border-radius: 10px;
        padding: 5px 10px;
        font-size: 12px;
        font-family: $body-font-family-2;
    }
}

.pricing-tabs {
    .react-tabs__tab-list {
        border-bottom: 1px solid #e3d4d4;
        margin: 0 0 30px;
        text-align: center;

        .react-tabs__tab {
            padding: 6px 20px;
            color: #000;

            &:hover {
                color: $app-main-color;
            }
            &:focus {
                outline: 0;
                &::after {
                    background: transparent;
                }
            }

            &.react-tabs__tab--selected {
                background-color: $app-main-color;
                color: #fff;
                border-color: $app-main-color;
            }
        }
    }
}

/*Team Style
======================================================*/
.agency-team-card {
    background-color: #f9f5f4;
    padding: 10px;
    border-radius: 10px;
    margin: 10px 10px 30px 10px;
    .team-content {
        position: relative;
        .team-image {
            margin-right: 40px;
            margin-bottom: 40px;
        }
        .team-caption {
            background-color: $white-color;
            padding: 30px;
            position: absolute;
            bottom: -40px;
            right: 0;
            border-radius: 10px;
            box-shadow: $box-shadow;
            text-align: center;
            h3 {
                font-size: 22px;
                margin-bottom: 8px;
                font-family: $heading-font-family-3;
                color: $heading-color-2;
            }
            p {
                margin-bottom: 20px;
                font-family: $body-font-family-2;
            }
            .social-links {
                ul {
                    margin-bottom: 0;
                    padding-left: 0;
                    li {
                        display: inline-block;
                        margin-left: 2px;
                        margin-right: 2px;
                        a {
                            height: 40px;
                            width: 40px;
                            line-height: 43px;
                            border: 1px solid #eeeeee;
                            background-color: $white-color;
                            border-radius: 100%;
                            display: inline-block;
                            text-align: center;
                            font-size: 16px;
                            color: $body-color;
                            transition: $transition;
                            &:hover {
                                background-color: $app-main-color;
                                color: $white-color;
                                transform: translateY(-5px);
                            }
                        }
                    }
                }
            }
        }
    }
}
.agency-team-slider {
    .swiper-pagination {
        position: initial;
        .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            background: #cccccc;

            &.swiper-pagination-bullet-active {
                background: $app-main-color;
            }
        }
    }
}
.agency-team-area {
    overflow: hidden;
    .container {
        position: relative;
        .team-shape-1 {
            position: absolute;
            left: -80px;
            top: 35%;
            animation: movebounce 5s linear infinite;
        }
        .team-shape-2 {
            position: absolute;
            right: -60px;
            top: 200px;
            animation: movebounce 6s linear infinite;
        }
    }
}

/*Join Us Style
======================================================*/
.join-us-area {
    .container {
        background-color: $heading-color-2;
        position: relative;
        z-index: 1;
        border-radius: 10px;
        .join-us-shape-2 {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            animation: movebounce 5s linear infinite;
        }
    }
}
.join-us-content {
    padding-left: 90px;
    margin-bottom: 30px;
    h2 {
        font-family: $heading-font-family-3;
        color: $white-color;
        font-size: 48px;
        margin-bottom: 20px;
    }
    p {
        font-family: $body-font-family-2;
        color: $white-color;
        margin-bottom: 25px;
    }
    .join-us-form {
        .form-group {
            .form-control {
                border-radius: 0;
                &.redius {
                    border-radius: 10px 0 0 10px;
                }
            }
            .agency-default-btn {
               width: 100%;
               border-radius: 0 10px 10px 0;
               &:hover {
                   background-color: #555555;
                }
            }
        }
    }
}
.join-us-img {
    position: relative;
    margin-top: -50px;
    .join-us-shape-1 {
        position: absolute;
        bottom: 0;
        right: -20px;
        animation: movebounce2 5s linear infinite;
    }
}

/*Blog Style
======================================================*/
.agency-blog-card {
    box-shadow: $box-shadow;
    margin-bottom: 30px;
    border-radius: 10px;
    .blog-img {
        position: relative;
        overflow: hidden;
        border-radius: 10px 10px 0 0;
        img {
            transition: $transition;
            border-radius: 10px 10px 0 0;
        }
        .date {
            position: absolute;
            left: 15px;
            top: 15px;
            background-color: $app-main-color;
            color: $white-color;
            font-family: $body-font-family-2;
            padding: 7px 10px;
            border-radius: 7px;
        }
    }
    .blog-content {
        padding: 25px 30px 30px 30px;
        h3 {
            font-size: 22px;
            font-family: $heading-font-family-3;
            margin-bottom: 15px;
            line-height: 1.5;
            a {
                color: $heading-color-2;
                transition: $transition;
                &:hover {
                    color: $app-main-color;
                }
            }
        }
        p {
            margin-bottom: 15px;
            font-family: $body-font-family-2;
        }
        .list {
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                position: relative;
                padding-left: 20px;
                margin-right: 15px;
                font-size: 15px;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    color: $body-color;
                    transition: $transition;
                    &:hover {
                        color: $app-main-color;
                    }
                }
                i {
                    position: absolute;
                    left: 0;
                    top: 2px;
                    color: $app-main-color;
                }
            }
        }
    }
    &:hover {
        .blog-img {
            img {
                transform: scale(1.2) rotate(7deg);
            }
        }
    }
}
.agency-blog-area {
    .container {
        position: relative;
        .blog-shape-1 {
            position: absolute;
            right: -50px;
            top: 50%;
            transform: translateY(-50%);
            animation: movebounce 4s linear infinite;
        }
    }
}

/*Instagram Style
======================================================*/
.single-instagram-card {
    position: relative;
    .instagram-img {
        position: relative;
        .caption {
            background-color: rgba(0, 0, 0, 0.6);
           position: absolute;
           top: 0;
           left: 0;
           right: 0;
           width: 100%;
           height: 100%;
           justify-content: center;
           align-items: center;
           display: flex;
           text-align: center;
           z-index: 2;
           transform: scaleY(0);
           transition: $transition;
           .icon-and-text {
               i {
                   color: $white-color;
                   font-size: 30px;
                }
                h3 {
                    font-size: 22px;
                    color: $white-color;
                    font-family: $heading-font-family-3;
                }
            }
        }
        &:hover {
            .caption {
                transform: scaleY(1);
            }
        }
    }
}
 

/*Consulting Demo Style
=================================================
================================================*/

/*Banner Style
======================================================*/
.consulting-banner-area {
    padding-top: 100px;
    background-color: #f4f7ff;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    .container-fluid {
        max-width: 1690px;
        padding-left: 30px;
        padding-right: 30px;
    }
}
.consulting-banner-content {
    span {
        color: $consulting-main-color;
        display: inline-block;
        margin-bottom: 10px;
    }
    h1 {
        font-family: $heading-font-family-4;
        color: $heading-color-3;
        font-size: 72px;
        margin-bottom: 20px;
        font-weight: 900;
    }
    p {
        margin-bottom: 30px;
    }
    .join-us-btn {
        color: $heading-color-3;
        font-weight: 500;
        i {
            position: relative;
            top: 2px;
            padding-right: 7px;
            color: $consulting-main-color;
        }
        &:hover {
            color: $sass-secondary-color;
        }
    }
}
.consulting-banner-image {
    position: relative;
    .month-stars {
        width: 290px;
        position: absolute;
        background-color: $white-color;
        padding: 20px;
        top: 100px;
        right: 0;
        border-radius: 10px;
        animation: movebounce 5s linear infinite;
        .list1 {
            padding-left: 0;
            margin-bottom: 0;
            margin-bottom: 15px;
            li {
                list-style-type: none;
            }
        }
        .list2 {
            padding-left: 0;
            margin-bottom: 0;
            margin-bottom: 10px;
            li {
                list-style-type: none;
                font-weight: 600;
                color: $heading-color-3;
            }
        }
        .progress-bar {
            background-color: $consulting-main-color;
        }
    }
    .careear {
        position: absolute;
        bottom: 60px;
        left: 0;
        .content {
            background-color: $white-color;
            padding: 20px;
            width: 370px;
            position: relative;
            padding-left: 70px;
            border-radius: 10px;
            .icon {
                height: 100px;
                width: 100px;
                line-height: 110px;
                background-color: $consulting-main-color;
                text-align: center;
                border-radius: 100%;
                font-size: 40px;
                color: $white-color;
                position: absolute;
                left: -45px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .banner-shape-1 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        animation: movebounce 7s linear infinite;
    }
    .banner-shape-2 {
        position: absolute;
        top: 60%;
        transform: translateY(-60%);
        right: 50px;
        animation: movebounce2 8s linear infinite;
    }
}

/*Partner Style
======================================================*/
.consulting-partner-area {
    border-bottom: 1px solid #eeeeee;
}
.consulting-partner-slider {
    &.owl-carousel {
        .owl-item {
            img {
                width: auto;
                margin: auto;
            }
        }
    }
}

/*Features Style
======================================================*/
.features-content {
    box-shadow: $box-shadow;
    border-radius: 10px;
}
.consulting-features-card {
    text-align: center;
    padding: 60px 20px 30px 20px;
    position: relative;
    .icon {
        font-size: 35px;
        color: $consulting-main-color;
        margin-bottom: 15px;
        position: relative;
        z-index: 1;
        &::before {
            position: absolute;
            content: '';
            height: 60px;
            width: 60px;
            border-radius: 100%;
            background-color: $consulting-main-color;
            opacity: .1;
            top: -20px;
            right: 32%;
            transform: translateX(-23%);
            z-index: -1;
        }
    }
    h3 {
        font-size: 22px;
        margin-bottom: 15px;
        font-family: $heading-font-family-4;
        color: $heading-color-3;
    }
    p {
        margin-bottom: 20px;
    }
    &::before {
        position: absolute;
        content: '';
        background-color: #eeeeee;
        height: 70%;
        width: 1px;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
    }

}
.col-lg-4 {
    &:last-child {
        .consulting-features-card {
            &::before {
                display: none;
            }
        }
    }
}

/*About Style
======================================================*/
.consulting-about-content {
    .consulting-about-title {
        position: relative;
        margin-bottom: 50px;
        span {
            font-size: 16px;
            margin-bottom: 10px;
            display: inline-block;
            color: $consulting-main-color;
            font-family: $body-font-family-2;
            letter-spacing: 3px;
            font-weight: 500;
        }
        h2 {
            font-size: 40px;
            font-family: $heading-font-family-4;
            color: $heading-color-3;
        }
        &::before {
            position: absolute;
            content: '';
            height: 3px;
            width: 100px;
            background-color: $consulting-main-color;
            bottom: -20px;
        }
        &::after {
            position: absolute;
            content: '';
            height: 3px;
            width: 7px;
            bottom: -20px;
            background-color: $white-color;
            animation: movebounce2 4s linear infinite;
        }
    }
    p {
        margin-bottom: 25px;
    }

    .about-profile {
        position: relative;
        margin-bottom: 30px;
        padding-left: 75px;
        img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 60px;
            height: 60px;
        }
        h3 {
            font-size: 20px;
            margin-bottom: 5px;
        }
        p {
            margin-bottom: 0;
        }
    }
    .download-btn {
        color: $heading-color-3;
        font-weight: 500;
        padding-left: 20px;
        transition: $transition;
        i {
            position: relative;
            top: 2px;
            padding-left: 7px;
        }
        &:hover {
            color: $sass-secondary-color;
        }
    }
}
.consulting-about-image {
    position: relative;
    padding-right: 50px;
    .customer {
        position: absolute;
        background-color: $white-color;
        box-shadow: $box-shadow;
        padding: 30px;
        border-radius: 10px;
        bottom: 20px;
        right: -10px;
        .customer-content {
            position: relative;
            padding-left: 75px;
            h1 {
                font-size: 30px;
                margin-bottom:10px;
                font-family: $heading-font-family-4;
                color: $heading-color-3;
            }
            img {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .about-shape-3 {
        position: absolute;
        bottom: -20px;
        left: -30px;
        animation: movebounce2 4s linear infinite;
    }
}
.consulting-about-area {
    position: relative;
    z-index: 1;
    .about-shape-1 {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
    .about-shape-2 {
        position: absolute;
        right: 100px;
        bottom: 100px;
        z-index: -1;
        animation: movebounce 4s linear infinite;
    }
}

/*Services Style
======================================================*/
.consulting-services-card {
    padding: 10px;
    border-radius: 10px;
    margin: 0 5px 30px 5px;
    border: 1px solid #eeeeee;
    .services-img {
        position: relative;
        .icon {
            font-size: 35px;
            height: 70px;
            width: 70px;
            line-height: 75px;
            box-shadow: $box-shadow;
            border-radius: 100%;
            color: $consulting-main-color;
            position: absolute;
            bottom: -30px;
            right: 20px;
            background-color: $white-color;
            box-shadow: $box-shadow;
            text-align: center;
        }
    }
    .services-content {
        padding: 20px;
        padding-top: 30px;
        h3 {
            font-size: 22px;
            font-family: $heading-font-family-4;
            color: $heading-color-3;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 15px;
        }
    }
}
.consulting-services-slider {
    .swiper-pagination {
        position: initial;
        .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            background: #cccccc;
            opacity: 1;

            &.swiper-pagination-bullet-active {
                background-color: $consulting-main-color;
            }
        }
    }
}

/*Choose Style
======================================================*/
.start-consuling-choose {
    background-color: $heading-color-3;
}
.consulting-choose-content {
    .choose-content-title {
        position: relative;
        margin-bottom: 50px;
        span {
            font-size: 16px;
            margin-bottom: 10px;
            display: inline-block;
            color: $consulting-main-color;
            font-family: $body-font-family-2;
            letter-spacing: 3px;
            font-weight: 500;
            text-transform: uppercase;
        }
        h2 {
            font-size: 40px;
            font-family: $heading-font-family-4;
            color: $white-color;
        }
        &::before {
            position: absolute;
            content: '';
            height: 3px;
            width: 100px;
            background-color: $consulting-main-color;
            bottom: -20px;
        }
        &::after {
            position: absolute;
            content: '';
            height: 3px;
            width: 7px;
            bottom: -20px;
            background-color: $heading-color-3;
            animation: movebounce2 4s linear infinite;
        }

    }
    p {
        color: $white-color;
        margin-bottom: 30px;
    }
}
.consult-choose-img {
    position: relative;
    margin-right: 60px;
    img {
        border-radius: 10px;
    }
    .icon {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        z-index: 1;
        .play-btn {
            font-size: 24px;
            background-color: $consulting-main-color;
            color: $white-color;
            text-align: center;
            height: 60px;
            width: 60px;
            line-height: 65px;
            border-radius: 100%;
            transition: $transition;
            cursor: pointer;
            i {
                position: relative;
                left: 3px;
                top: 1px;
            }
            &:hover {
                background-color: $white-color;
                color: $sass-main-color;
            }
        }
        &::before {
            position: absolute;
            content: '';
            height: 70px;
            width: 70px;
            border-radius: 100%;
            background-color: rgba(53, 97, 228, .60%);
            animation: ripple 4s linear infinite;
            z-index: -1;
        }
        &::after {
            position: absolute;
            content: '';
            height: 80px;
            width: 80px;
            border-radius: 100%;
            background-color: rgba(53, 97, 228, .40%);
            animation: ripple 3s linear infinite;
            z-index: -1;
        }
    }
    .choose-shape-1 {
        position: absolute;
        bottom: 60px;
        right: -60px;
    }
    .choose-shape-2 {
        position: absolute;
        bottom: -20px;
        left: -20px;
        animation: movebounce2 4s linear infinite;
    }
}
.choose-counter-card {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #000000;
    border-radius: 10px;
    .counter-content {
        position: relative;
        padding-left: 40px;
        .icon {
            font-size: 70px;
            color: $consulting-main-color;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            opacity: .2;
            line-height: 1;
        }
        h1 {
            font-size: 48px;
            font-family: $heading-font-family-4;
            color: $white-color;
            margin-bottom: 5px;
        }
        p {
            color: $white-color;
            margin-bottom: 0;
        }
    }
}

/*Project Style
======================================================*/
.consulting-project-card {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    .project-content {
        position: absolute;
        bottom: 30px;
        text-align: center;
        top: 100%;
        left: 0;
        transition: $transition;
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 100%;
        padding: 15px;
        h3 {
            font-size: 22px;
            color: $white-color;
            font-family: $heading-font-family-4;
        }
        p {
            color: $white-color;
        }
        .view {
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            color: #fff;
            background-color: $consulting-main-color;
            display: inline-block;
            transition: $transition;
            &:hover {
                background-color: #fff;
                border-radius: 100px;
                color: #000;
            }
        }
    }
    &:hover {
        .project-content {
            top: 0;
        }
    }
}
.consulting-project-slider {
    .swiper-pagination {
        position: initial;
        .swiper-pagination-bullet {
            background: #cccccc;
            width: 12px;
            height: 12px;
            opacity: 1;
            &.swiper-pagination-bullet-active {
                background: $consulting-main-color;
            }
        }
    }
}


/*Quote Style
======================================================*/
.consulting-quote-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
    &::before {
        position: absolute;
        content: '';
        background-color: #f9f5f4;
        height: 60%;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: -1;
    }
    .container {
        background-color: $heading-color-3;
        z-index: 1;
        position: relative;
        border-radius: 10px;
        &::before {
            position: absolute;
            content: '';
            background-color: $white-color;
            height: 30px;
            width: 100%;
            left: 0;
            top: 0;
            z-index: -1;
        }
        &::after {
            position: absolute;
            content: '';
            background-color: #f9f5f4;
            height: 30px;
            width: 100%;
            left: 0;
            bottom: 0;
            z-index: -1;
        }
    }
}
.quote-content {
    padding-left: 50px;
    .quote-title {
        position: relative;
        margin-bottom: 50px;
        span {
            font-size: 16px;
            margin-bottom: 10px;
            display: inline-block;
            color: $consulting-main-color;
            font-family: $body-font-family-2;
            font-weight: 500;
            letter-spacing: 3px;
            text-transform: uppercase;
        }
        h2 {
            font-size: 40px;
            font-family: $heading-font-family-4;
            color: $white-color;
        }
        &::before {
            position: absolute;
            content: '';
            height: 3px;
            width: 100px;
            background-color: $consulting-main-color;
            bottom: -20px;
        }
        &::after {
            position: absolute;
            content: '';
            height: 3px;
            width: 7px;
            bottom: -20px;
            background-color: $white-color;
            animation: movebounce2 4s linear infinite;
        }
    }
}
.quote-img {
    animation: movebounce2 5s linear infinite;

}

/*Team Style
======================================================*/
.consulting-team-card {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    border-radius: 10px;
    transition: $transition;
    img {
        border-radius: 10px;
    }
    .team-content {
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;
        text-align: center;
        h3 {
            font-size: 22px;
            color: $white-color;
            font-family: $heading-font-family-4;
            margin-bottom: 10px;
        }
        p {
            color: $white-color;
        }
        &::before {
            position: absolute;
            content: '';
            height: 2px;
            width: 80px;
            background-color: $white-color;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .social-links {
        position: absolute;
        right: 30px;
        top: 30px;
        transition: $transition;
        transform: rotate(30deg);
        opacity: 0;
        ul {
            margin-bottom: 0;
            padding-left: 0;
            li {
                list-style-type: none;
                margin-bottom: 10px;
                a {
                    height: 40px;
                    width: 40px;
                    line-height: 43px;
                    border: 1px solid #eeeeee;
                    background-color: $white-color;
                    border-radius: 100%;
                    display: inline-block;
                    text-align: center;
                    font-size: 16px;
                    color: $consulting-main-color;
                    transition: $transition;
                    &:hover {
                        background-color: $consulting-main-color;
                        color: $white-color;
                        transform: translateY(-5px);
                    }
                }
            }
        }
    }
    &::before {
        position: absolute;
        content: '';
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: $heading-color-3;
        opacity: .5;
        border-radius: 10px;
        transition: $transition;
    }
    &:hover {
        .team-content {
            &::before {
                background-color: $consulting-main-color;
            }
        }
        .social-links {
            opacity: 1;
            top: 50px;
            transform: rotate(0);
            right: 50px;
        }
    }
}

/*Testimonial Style
======================================================*/
.consulting-testimonial-area {
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    .container {
        position: relative;
        .testimonial-shape-1 {
            position: absolute;
            top: 100px;
            left: 50px;
            animation: movebounce2 4s linear infinite;
        }
    }
}
.consulting-testimonial-card {
    padding: 30px;
    position: relative;
    border-radius: 10px;
    border: 1px solid #eeeeee;
    margin-bottom: 30px;
    background-color: $white-color;
    transition: $transition;
    p {
        margin-bottom: 30px;
    }
    .cliens-profile {
        position: relative;
        padding-left: 100px;
        img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 80px;
            height: 80px;
        }
        h3 {
            font-size: 20px;
            margin-bottom: 10px;
            font-family: $heading-font-family-4;
            color: $heading-color-3;
        }
        p {
            margin-bottom: 0;
        }
    }
    .icon {
        font-size: 60px;
        color: $sass-secondary-color;
        position: absolute;
        right: 30px;
        bottom: 0;
    }
}
.consulting-testimonial-slider {
    position: relative;
    margin-top: 50px;

    .swiper-pagination {
        position: initial;
        .swiper-pagination-bullet {
            background: #cccccc;
            width: 12px;
            height: 12px;
            opacity: 1;
            &.swiper-pagination-bullet-active {
                background: $consulting-main-color;
            }
        }
    }
}
 

/*Join Us Style
======================================================*/
.consulting-join-us-area {
    background-color: $heading-color-3;
    position: relative;
    z-index: 1;
    .join-us-shape-1 {
        position: absolute; 
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
    }
}
.consulting-join-content {
    border-left: 3px solid $white-color;
    padding-left: 30px;
    h2 {
        font-size: 48px;
        color: $white-color;
        font-family: $heading-font-family-4;
        margin-bottom: 20px;
    }
    p {
        color: $white-color;
    }
}
.consulting-join-us-form {
    .newsletter-form {
        position: relative;
        .form-control {
            border-radius: 10px;
            height: 54px;
        }
        .consulting-default-btn {
            position: absolute;
            background-color: $consulting-main-color;
            color: $white-color;
            right: 0;
            top: -1px;
            &:hover {
                background-color: $heading-color;
                color: $white-color;
            }
        }
    }
}

/*Blog Style
======================================================*/
.consulting-blog-card {
    .blog-content {
        padding-top: 30px;
        padding-bottom: 30px;
        .list {
            padding-left: 0;
            margin-bottom: 14px;
            li {
                display: inline-block;
                margin-right: 15px;
                padding-left: 22px;
                position: relative;
                i {
                    position: absolute;
                    left: 0;
                    top: 1px;
                    color: $consulting-main-color;
                }
                a {
                    color: $body-color;
                    transition: $transition;
                    &:hover {
                        color: $consulting-main-color;
                    }
                }
            }
        }
        h3 {
            font-size: 22px;
            font-family: $heading-font-family-4;
            margin-bottom: 15px;
            line-height: 1.5;
            a {
                color: $heading-color-3;
                transition: $transition;
                &:hover {
                    color: $consulting-main-color;
                }
            }
        }
    }
    .blog-img  {
        border-radius: 10px;
        overflow: hidden;
        img {
            border-radius: 10px;
            transition: $transition;
        }
    }
    &:hover {
        .blog-img {
            img {
                transform: scale(1.2);
            }
        }
    }
}

/*Photograpy Demo Style
=================================================
================================================*/

//Banner

/*Banner Style
======================================================*/
.photography-banner-area {
    background-color: #191919;
}
.photography-banner-content {
    max-width: 800px;
    margin-right: 0;
    margin-left: auto;
    padding-top: 40px;
    padding-left: 30px;
    span {
        color: $white-color;
        display: inline-block;
        margin-bottom: 10px;
        letter-spacing: 3px;
        font-family: $body-font-family-3;
    }
    h1 {
        font-size: 86px;
        color: $white-color;
        font-family: $heading-font-family-5;
        margin-bottom: 20px;
    }
    p {
        color: $white-color;
        margin-bottom: 25px;
        font-family: $body-font-family-3;
        padding-right: 30px;
    }
    a {
        color: $white-color;
        letter-spacing: 3px;
        i {
            position: relative;
            top: 3px;
            padding-left: 7px;
        }
    }
}
.photography-banner-img {
    .swiper-button-next, .swiper-button-prev {
        &::after {
            font-size: 30px;
            color: #fff;
        }
    }
}


/*Services Style
======================================================*/
.photography-services-list {
    > div {
        &:nth-child(1), &:nth-child(3), &:nth-child(5), &:nth-child(7), &:nth-child(9), &:nth-child(11), &:nth-child(13), &:nth-child(15), &:nth-child(17), &:nth-child(19), &:nth-child(21), &:nth-child(23), &:nth-child(25), &:nth-child(27), &:nth-child(29), &:nth-child(31), &:nth-child(33), &:nth-child(35), &:nth-child(37), &:nth-child(39) {
            margin-top: 30px;
        }
    }
}
.photograpy-services-card {
    &.mt-30 {
        margin-top: 30px;
    }
    .services-content {
        padding-top: 30px;
        padding-bottom: 30px;
        span {
            letter-spacing: 3px;
            color: $photography-main-color;
            font-size: 16px;
            margin-bottom: 15px;
            font-weight: 500;
            text-transform: uppercase;
            display: inline-block;
            font-family: $body-font-family-3;
        }
        h2 {
            font-size: 22px;
            font-family: $heading-font-family-5;
            font-weight: 600;
            margin-bottom: 20px;
        }
        .icon-btn {
            font-size: 20px;
            color: $photography-main-color;
            transition: $transition;
        }
    }
}
.photography-services-area {
    position: relative;
    z-index: 1;
    .services-shape-1 {
        position: absolute;
        left: 100px;
        z-index: -1;
        bottom: 250px;
        animation: movebounce 4s linear infinite;
    }
}

/*About Style
======================================================*/
.photograpy-about-content {
    position: relative;
    .photography-about-title {
        span {
            color: $photography-main-color;
            font-size: 16px;
            text-transform: uppercase;
            display: inline-block;
            margin-bottom: 10px;
        }
        h2 {
            font-size: 40px;
            margin-bottom: 25px;
            font-family: $heading-font-family-5;
        }
    }
    .about-text {
        margin-bottom: 30px;
        p {
            font-family: $body-font-family-3;
            margin-bottom: 20px;
        }
    }
}
.photography-about-area {
    .container {
        position: relative;
        z-index: 1;
        .about-shape-1 {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: -1;
            animation: movebounce2 4s linear infinite;
        }
    }
}

/*Portfolio Style
======================================================*/
.photography-portfolio-area {
    position: relative;
    z-index: 1;
    .portfolio-shape-1 {
        position: absolute;
        right: 100px;
        bottom: 300px;
        animation: movebounce 4s linear infinite;
        z-index: -1;
    }
    &.style2 {
        .photography-section-title {
            h2 {
                font-family: $body-font-family;
            }
            span {
                font-family: $heading-font-family-2;
            }
        }
        .photography-portfolio-card {
            .portfolio-content {
                h3 {
                    font-family: $body-font-family;
                }
                p {
                    font-family: $heading-font-family-2;
                }
            }
        }
    }
}
.photography-portfolio-card {
    margin-bottom: 30px;
    .portfolio-img {
        position: relative;
        .icon {
            position: absolute;
            align-items: center;
            justify-content: center;
            display: flex;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: $transition;
            i {
                color: $white-color;
                font-size: 30px;
            }
        }
        &::before {
            position: absolute;
            content: '';
            height: 100%;
            width: 100%;
            left: 0;
            right: 0;
            background-color: #000000;
            opacity: .6;
            transform: scaleX(0);
            transition: $transition;
        }
        &:hover {
            .icon {
                opacity: 1;
            }
            &::before {
                transform: scaleX(1);
            }
        }
    }
    .portfolio-content {
        padding-top: 20px;
        h3 {
            font-size: 20px;
            margin-bottom: 15px;
            font-family: $heading-font-family-5;
        }
        p {
            font-family: $body-font-family-3;
        }
    }
}
.mfp-title {
    display: none;
}

/*Watch Video Style
======================================================*/
.photography-video-area {
    position: relative;
    z-index: 1;
    .photography-bg {
        background-size: cover;
        background-position: center center;
        width: 100%;
    }
    &::before {
        position: absolute;
        content: '';
        background-color: #f9f9f9;
        width: 100%;
        height: 50%;
        left: 0;
        bottom: 0;
        z-index: -1;
    }
}
.photograpy-video-content {
    position: relative;
    padding-top: 200px;
    .video-text {
        padding: 30px;
        background-color: $white-color;
        bottom: 0;
        left: 0;
        box-shadow: $box-shadow;
        h2 {
            font-size: 40px;
            font-family: $heading-font-family-5;
            margin-bottom: 30px;
        }
    }
}
.video-icon-content {
    position: relative;
    .icon {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        z-index: 1;
        .play-btn {
            font-size: 24px;
            background-color: $photography-main-color;
            color: $white-color;
            text-align: center;
            height: 60px;
            width: 60px;
            line-height: 65px;
            border-radius: 100%;
            transition: $transition;
            cursor: pointer;
            i {
                position: relative;
                left: 3px;
                top: 1px;
            }
            &:hover {
                background-color: $white-color;
                color: $photography-main-color;
            }
        }
        &::before {
            position: absolute;
            content: '';
            height: 70px;
            width: 70px;
            border-radius: 100%;
            background-color: rgba(156, 84, 81, .50%);
            animation: ripple 4s linear infinite;
            z-index: -1;
        }
        &::after {
            position: absolute;
            content: '';
            height: 80px;
            width: 80px;
            border-radius: 100%;
            background-color: rgba(156, 84, 81, .30%);
            animation: ripple 3s linear infinite;
            z-index: -1;
        }
    }
}

/*Testimonial Style
======================================================*/
.photography-testimonial-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
    .testimonial-shape-1 {
        position: absolute;
        left: 120px;
        top: 100px;
        z-index: -1;
        animation: movebounce 4s linear infinite;
    }
}
.photograpy-testimonial-card {
    padding: 40px;
    background-color: $white-color;
    margin-bottom: 30px;
    .rating {
        padding-left: 0;
        margin-bottom: 0;
        margin-bottom: 15px;
        li {
            display: inline-block;
            margin-right: 5px;
            &:last-child {
                margin-right: 0;
            }
            i {
                color: #ffc107;
            }
        }
    }
    h3 {
        font-size: 22px;
        font-family: $heading-font-family-5;
        margin-bottom: 20px;
    }
    p {
        font-family: $body-font-family-3;
        margin-bottom: 30px;
    }
    .clients-profile {
        position: relative;
        padding-left: 100px;
        margin-bottom: 10px;
        img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: auto;
        }
        h3 {
            font-size: 20px;
            font-family: $heading-font-family-5;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 0;
        }
    }
}
.photograpy-testimonial-slider {
    .swiper-pagination {
        position: initial;
        .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            
            &.swiper-pagination-bullet-active {
                background-color: $photography-main-color;
            }
        }
    }
}

/*Team Style
======================================================*/
.photography-team-card {
    position: relative;
    transition: $transition;
    .team-caption1 {
        text-align: center;
        padding: 30px;
        background-color: $white-color;
        transition: $transition;
        transform: scaleX(1);
        h3 {
            font-size: 22px;
            margin-bottom: 15px;
            font-family: $heading-font-family-5;
        }
    }
    .team-caption2 {
        text-align: center;
        transform: scaleY(0);
        padding: 30px;
        background-color: $white-color;
        transition: $transition;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        h3 {
            font-size: 22px;
            margin-bottom: 15px;
            font-family: $heading-font-family-5;
        }
        .social-links {
            transition: $transition !important;
            ul {
                margin-bottom: 0;
                padding-left: 0;
                li {
                    display: inline-block;
                    margin: 0 3px;
                    a {
                        height: 45px;
                        width: 45px;
                        line-height: 48px;
                        background-color: $white-color;
                        color: $body-color;
                        border-radius: 100%;
                        box-shadow: $box-shadow;
                        display: inline-block;
                        text-align: center;
                        font-size: 18px;
                        transition: $transition;
                        &:hover {
                            background-color: $photography-main-color;
                            color: $white-color;
                            transform: translateY(-5px);
                        }
                    }
                }
            }
        }
    }
    &:hover {
        .team-caption1 {
            transform: scaleX(0);
        }
        .team-caption2 {
            transform: scaleY(1);
        }
    }
}
.photography-team-area {
    position: relative;
    z-index: 1;
    .team-shape-1 {
        position: absolute;
        right: 120px;
        bottom: 250px;
        z-index: -1;
        animation: movebounce 4s linear infinite;
    }
}

/*Pricing Style
======================================================*/
.photography-pricing-area {
    position: relative;
    z-index: 1;
    .pricing-shape-1 {
        position: absolute;
        left: 120px;
        bottom: 250px;
        z-index: -1;
        animation: movebounce 4s linear infinite;
    }
}
.photography-pricing-card {
    padding: 40px;
    background-color: $white-color;
    span {
        color: $photography-main-color;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 3px;
        text-transform: uppercase;
        margin-bottom: 15px;
        display: inline-block;
    }
    h1 {
        font-size: 48px;
        font-family: $heading-font-family-5;
        margin-bottom: 30px;
    }
    .pricing-list {
        padding-left: 0;
        margin-bottom: 35px;
        li {
            position: relative;
            list-style-type: none;
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
            i {
                position: absolute;
                right: 0;
                font-size: 13px;
                top: 2px;
                color: $photography-main-color;
            }
        }
    }
    &.active {
        margin-top: 30px;
        .photography-default-btn {
            background-color: $photography-main-color;
            color: $white-color;
        }
    }
}

/*Contact Style
======================================================*/
.photography-contact-area {
    position: relative;
    .contact-shape-1 {
        position: absolute;
        bottom: 300px;
        right: 120px;
        animation: movebounce 4s linear infinite;
    }
}
.photography-contact-form {
    h3 {
        font-size: 22px;
        font-family: $heading-font-family-5;
        margin-bottom: 30px;
    }
    .form-group {
        margin-bottom: 25px;
        .form-control {
            color: $body-color;
            &:focus {
                border: 1px solid $photography-main-color;
                box-shadow: none;
            }
            &::placeholder {
                color: $body-color;
            }
        }
    }
}

/*Blog Style
======================================================*/
.photography-blog-card {
    margin-bottom: 30px;
    .blog-content {
        padding-top: 25px;
        span {
            color: $photography-main-color;
            font-size: 16px;
            letter-spacing: 3px;
            display: inline-block;
            margin-bottom: 15px;
        }
        h2 {
            font-size: 22px;
            font-family: $heading-font-family-5;
            margin-bottom: 20px;
            a {
                color: $heading-color;
                transition: $transition;
                &:hover {
                    color: $photography-main-color;
                }
            }
        }
        a {
            color: $photography-main-color;
            font-size: 20px;
        }
    }
}

/*Fotter Style
======================================================*/
.photography-fotter-area {
    background-color: $heading-color;
    border-bottom: 1px solid #575757;
    padding-top: 20px;
    padding-bottom: 20px;
    .photography-logo {
        text-align: left;
    }
    .photography-quick-links {
        text-align: center;
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                margin-right: 15px;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    color: $white-color;
                    transition: $transition;
                    &:hover {
                        color: $photography-main-color;
                    }
                }
            }
        }
    }
    .photograpy-social-links {
        text-align: right;
        .social-links {
            transition: $transition !important;
            ul {
                margin-bottom: 0;
                padding-left: 0;
                li {
                    display: inline-block;
                    margin-right: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        height: 45px;
                        width: 45px;
                        line-height: 48px;
                        border: 1px solid #575757;
                        color: $white-color;
                        border-radius: 100%;
                        box-shadow: $box-shadow;
                        display: inline-block;
                        text-align: center;
                        font-size: 18px;
                        transition: $transition;
                        &:hover {
                            background-color: $photography-main-color;
                            color: $white-color;
                            transform: translateY(-5px);
                            border-color: $photography-main-color;
                        }
                    }
                }
            }
        }
    }
}

/*Coming Soon Style
======================================================*/
.coming-soon-area {
    padding-top: 200px;
    position: relative;
    z-index: 1;
    &::before {
        position: absolute;
        content: '';
        height: 420px;
        width: 100%;
        left: 0;
        top: 0;
        background-color: #f7f7f7;
        z-index: -1;
    }
    .container {
        position: relative;
        .coming-soon-shape-1 {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 3;
        }
        .coming-soon-shape-2 {
            position: absolute;
            right: 0;
            top: 45%;
            transform: translateY(-50%);
            z-index: 3;
        }
        .coming-soon-shape-3 {
            position: absolute;
            left: 0;
            top: 50px;
            animation: movebounce 4s linear infinite;
        }
        .coming-soon-shape-4 {
            position: absolute;
            left: 0;
            top: 30%;
            animation: movebounce 5s linear infinite;
        }
        .coming-soon-shape-5 {
            position: absolute;
            right: 0;
            top: 18%;
            animation: movebounce 5s linear infinite;
        }
    }
}
.coming-soon-counter {
    max-width: 880px;
    margin: auto;
    border: 30px solid $white-color;
    background-color: #f7f7f7;
    box-shadow: $box-shadow;
    padding-top: 100px;
    padding-bottom: 80px;
    border-radius: 10px;
    position: relative;
    z-index: 2;
    margin-bottom: 30px;
    &::before {
        position: absolute;
        content: '';
        height: 100%;
        width: auto;
        left: -60px !important;
        right: 0;
        bottom: -60px ;
        border-radius: 10px;
        border: 1px solid #eeeeee;
        z-index: -1;
    }
    .list {
        ul {
            margin-bottom: 0;
            padding-left: 0;
            li {
                margin: 0 30px;
                font-size: 48px;
                font-weight: 700;
                margin-bottom: 30px;
                color: $heading-color-3;
                position: relative;
                span {
                   font-size: 15px;
                   font-weight: 500;
                   color: $body-color;
                }
                &::before {
                    position: absolute;
                    content: '';
                    height: 6px;
                    width: 6px;
                    background-color: $body-color;
                    border-radius: 100%;
                    right: -30px;
                    top: 42px;
                }
                &::after {
                    position: absolute;
                    content: '';
                    height: 6px;
                    width: 6px;
                    background-color: $body-color;
                    border-radius: 100%;
                    right: -30px;
                    top: 55px;
                }
                &:last-child {
                    &::before {
                        display: none;
                    }
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
    .shape-1 {
        position: absolute;
        top: -40px;
        right: 0;
        animation: movebounce 5s linear infinite;
    }
    .shape-2 {
        position: absolute;
        top: 30px;
        left: 30px;
        animation: movebounce2 5s linear infinite;
    }
    .shape-3 {
        position: absolute;
        top: -80px;
        left: 300px;
        animation: rotateme 9s linear infinite;
    }

}
.coming-soon-content {
    max-width: 880px;
    margin: auto;
    text-align: center;
    .top-content {
        max-width: 620px;
        margin: auto;
        span {
            color: $white-color;
            background-color: #dc3545;
            display: inline-block;
            padding: 10px 20px;
            border-radius: 10px;
            font-size: 20px;
            font-family: $heading-font-family-5;
            font-weight: 400;
            margin-bottom: 20px;
        }
        h1 {
            font-size: 60px;
            margin-bottom: 30px;
            font-family: $heading-font-family-5;
            font-weight: 600;
        }
    }
    .newsletter-form {
        position: relative;
        margin-bottom: 30px;
        z-index: 4;
        .form-control {
            height: 80px;
        }
        .subscribe-btn {
            background-color: #025de4;
            color: $white-color;
            padding: 15px 30px;
            transition: $transition;
            border: none;
            border-radius: 10px;
            font-weight: 500;
            font-size: 15px;
            position: absolute;
            right: 13px;
            top: 13px;
            &:hover {
                background-color: $heading-color;
                color: $white-color;
            }
            i {
                position: relative;
                top: 2px;
                padding-left: 7px;
            }
        }
    }
    .social-links {
        ul {
            margin-bottom: 0;
            padding-left: 0;
            li {
                display: inline-block;
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    height: 45px;
                    width: 45px;
                    line-height: 48px;
                    background-color: $white-color;
                    border-radius: 100%;
                    color: $body-color;
                    box-shadow: $box-shadow;
                    border: 1px solid #eeeeee;
                    display: inline-block;
                    text-align: center;
                    font-size: 18px;
                    transition: $transition;
                    &:hover {
                        background-color: #025de4;
                        color: $white-color;
                        transform: translateY(-5px);
                    }
                }
            }
        }
    }
}

/*Political Demo Style
=================================================
================================================*/

/*Header Style
======================================================*/
.plitical-header-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    .navbar {
        position: unset;
    }
    .navbar-light {
        &.nav-style7 {
            &.is-sticky {
                background-color: $white-color !important;
                position: fixed !important;
                .container-fluid {
                    box-shadow: unset;
                }
            }
        }
    }
}
.political-top-header-area {
    padding-top: 10px;
    padding-bottom: 10px;
    .container-fluid {
        max-width: 1690px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .political-header-left-content {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    color: $body-color;
                    transition: $transition;
                    &:hover {
                        color: $political-main-color;
                    }
                }
                i {
                    position: relative;
                    top: 1px;
                    padding-right: 10px;
                    color: $political-main-color;
                }
            }
        }
    }
    .political-header-right-content {
        text-align: right;
        .social-links {
            ul {
                margin-bottom: 0;
                padding-left: 0;
                li {
                    display: inline-block;
                    margin-right: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        height: 40px;
                        width: 40px;
                        line-height: 43px;
                        background-color: $white-color;
                        border-radius: 100%;
                        color: $body-color;
                        box-shadow: $box-shadow;
                        border: 1px solid #eeeeee;
                        display: inline-block;
                        text-align: center;
                        font-size: 16px;
                        transition: $transition;
                        &:hover {
                            background-color: $political-main-color;
                            color: $white-color;
                            transform: translateY(-3px);
                        }
                    }
                }
            }
        }
    }

}

/*Banner Style
======================================================*/
.political-banner-area {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding-top: 200px;
    .container-fluid {
        max-width: 1690px;
        padding-left: 30px;
        padding-right: 30px;
    }
}
.political-banner-content {
    margin-bottom: 40px;
    span {
        display: inline-block;
        margin-bottom: 15px;
        position: relative;
        padding-left: 20px;
        &::before {
            position: absolute;
            content: '';
            left: 0;
            height: 7px;
            width: 7px;
            top: 8px;
            border-radius: 100%;
            background-color: $political-main-color;
        }
    }
    h1 {
        font-size: 85px;
        font-family: $body-font-family;
        color: $heading-color-4;
        margin-bottom: 15px;
    }
    p {
        font-family: $heading-font-family-2;
        margin-bottom: 30px;
    }
    .banner-btn {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                margin-right: 30px;
                &:last-child {
                    margin-right: 0;
                }
                .play-btn {
                    position: relative;
                    z-index: 1;
                    top: 3px;
                    cursor: pointer;
                    i {
                        position: relative;
                        color: $white-color;
                        background-color: $heading-color-4;
                        height: 50px;
                        width: 50px;
                        line-height: 50px;
                        padding-top: 2px;
                        padding-left: 1px;
                        border-radius: 100%;
                        display: inline-block;
                        text-align: center;
                        transition: $transition;
                    }
                    &::before {
                        position: absolute;
                        content: '';
                        height: 55px;
                        width: 55px;
                        border-radius: 100%;
                        background-color: rgba(12, 26, 60, .40%);
                        z-index: -1;
                        top: -3px;
                        left: -3px;
                        animation: ripple 4s linear infinite;
                        transition: $transition;
                    }
                    &:hover {
                        i {
                            background-color: $political-main-color;
                            color: $white-color;
                        }
                        &::before {
                            background-color: rgba(221, 19, 26, .40%);
                        }
                    }
                }
            }
        }
    }
}

/*Movement Style
======================================================*/
.political-movement-card {
    margin-bottom: 30px;
    .movement-content {
        text-align: center;
        padding-top: 30px;
        padding-bottom: 20px;
        h1 {
            font-size: 42px;
            font-family: $body-font-family;
            color: $heading-color-4;
            margin-bottom: 5px;
        }
        p {
            font-family: $heading-font-family-2;
        }
    }
}

/*About Style
======================================================*/
.political-about-content {
    .political-about-title{
        span {
            color: $political-main-color;
            display: inline-block;
            margin-bottom: 15px;
            font-family: $heading-font-family-2;
        }
        h2 {
            font-size: 40px;
            margin-bottom: 25px;
            font-family: $body-font-family;
            color: $heading-color-4;
        }
    }
    .text-content {
        margin-bottom: 30px;
        p {
            font-family: $heading-font-family-2;
            margin-bottom: 20px;
        }
    }
}
.political-about-image {
    position: relative;
    padding-left: 70px;
    .description-box {
        background-color: $white-color;
        box-shadow: $box-shadow;
        position: absolute;
        left: 0;
        bottom: 30px;
        width: 350px;
        padding: 30px;
        h4 {
            font-size: 16px;
            margin-bottom: 20px;
            font-family: $body-font-family;
            color: $heading-color-4;
        }
        p {
            font-size: 15px;
            margin-bottom: 20px;
            font-family: $heading-font-family-2;
        }
        h3 {
            font-size: 18px;
            margin-bottom: 10px;
            font-family: $body-font-family;
            color: $heading-color-4;
        }
        span {
            font-size: 15px;
        }
    }
}

/*Project Style
======================================================*/
.political-project-card {
    position: relative;
    margin-bottom: 40px;
    .project-content {
        padding: 30px;
        background-color: $white-color;
        position: absolute;
        left: 0;
        bottom: 0;
        padding-right: 50px;
        transition: $transition;
        span {
            color: $political-main-color;
            font-family: $heading-font-family-2;
            display: inline-block;
            margin-bottom: 10px;
            transition: $transition;
        }
        h3 {
            font-size: 22px;
            color: $heading-color-4;
            font-family: $body-font-family;
            transition: $transition;
        }
        .link-icon {
            position: absolute;
            top: 0;
            right: 0;
            i {
                font-weight: 700;
                height: 45px;
                width: 45px;
                line-height: 48px;
                text-align: center;
                background-color: #eef1f6;
                display: inline-block;
                color: $body-color;
                transition: $transition;
            }
        }
    }
    &:hover {
        .project-content {
            background-color: $political-main-color;
            span {
                color: $white-color;
            }
            h3 {
                color:  $white-color;
            }
            .link-icon {
                i {
                    background-color: $heading-color-4;
                    color: $white-color;
                }
            }
        }
    }
}
.political-project-slider {
    .swiper-pagination {
        position: initial;
        .swiper-pagination-bullet {
            background-color: #ccc;
            width: 12px;
            height: 12px;
            opacity: 1;

            &.swiper-pagination-bullet-active {
                background: $political-main-color;
            }
        }
    }
}

.btn {
    &:focus {
        box-shadow: none !important;
    }
}

/*Video Style
======================================================*/
.political-donation-and-video-area {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    background-attachment: fixed;
    z-index: 1;
    &::before {
        position: absolute;
        content: '';
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background-color: $heading-color-4;
        opacity: .7;
        z-index: -1;
    }
    &::after {
        position: absolute;
        content: '';
        height: 150px;
        width: 100%;
        left: 0;
        top: 0;
        background-color: $white-color;
        z-index: -1;
    }
}
.select-and-donate {
    ul {
        padding-left: 0;
        margin-bottom: 0;
        li {
            display: inline-block;
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
            .btn-check:active+.btn, .btn-check:checked+.btn, .btn.active, .btn.show, .btn:active {
                color: $political-main-color;
                background-color: $white-color;
                border-color: $white-color;
            }
            .btn-check:focus+.btn, .btn:focus {
                color: $political-main-color;
                background-color: $white-color;
                border-color: $white-color;
                box-shadow: none;
            }
            .btn-outline-primary  {
                padding: 15px 20px;
                border-color: $white-color;
                border-radius: 0;
                color: $white-color;
                transition: $transition;
                &:hover {
                    color: $political-main-color;
                    background-color: $white-color;
                }
            }
        }
    }
}
.political-donation-content {
    background-color: $political-main-color;
    padding: 100px;
    background-size: cover;
    background-position: center center;
    position: relative;
    .donate-text {
        span {
            color: $white-color;
            display: inline-block;
            margin-bottom: 15px;
        }
        h2 {
            color: $white-color;
            font-size: 48px;
            font-family: $body-font-family;
        }
    }
}
.political-intro-video {
    text-align: center;
    max-width: 700px;
    margin: auto;
    .political-section-title {
        span {
            color: $white-color;
        }
        h2 {
            color: $white-color;
        }
    }
    .play-btn {
        position: relative;
        z-index: 1;
        cursor: pointer;
        i {
            position: relative;
            color: $white-color;
            background-color: $political-main-color;
            height: 70px;
            width: 70px;
            line-height: 70px;
            padding-top: 3px;
            font-size: 25px;
            padding-left: 1px;
            border-radius: 100%;
            display: inline-block;
            text-align: center;
            transition: $transition;
        }
        &::before {
            position: absolute;
            content: '';
            height: 80px;
            width: 80px;
            border-radius: 100%;
            background-color: rgba(221, 19, 26, .50%);
            z-index: -1;
            top: -5px;
            left: auto;
            margin-left: -5px;
            right: auto;
            animation: ripple 4s linear infinite;
            transition: $transition;
        }
        &:hover {
            i {
                background-color: $heading-color-4;
                color: $white-color;
            }
            &::before {
                background-color: rgba(12, 26, 60, .50%);
            }
        }
    }
}

/*Volunteer Style
======================================================*/
.political-volunteer-card {
    box-shadow: $box-shadow;
    margin-bottom: 30px;
    .volunteer-content {
        text-align: center;
        padding: 30px;
        h3 {
            font-size: 22px;
            margin-bottom: 10px;
            font-family: $body-font-family;
            color: $heading-color-4;
        }
        p {
            font-family: $heading-font-family-2;
            margin-bottom: 15px;
        }
        .social-links {
            ul {
                margin-bottom: 0;
                padding-left: 0;
                li {
                    display: inline-block;
                    margin-right: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        height: 40px;
                        width: 40px;
                        line-height: 43px;
                        background-color: $white-color;
                        border-radius: 100%;
                        box-shadow: $box-shadow;
                        border: 1px solid #eeeeee;
                        color: $body-color;
                        display: inline-block;
                        text-align: center;
                        font-size: 18px;
                        transition: $transition;
                        &:hover {
                            background-color: $political-main-color;
                            color: $white-color;
                            transform: translateY(-5px);
                        }
                    }
                }
            }
        }
    }
}

/*Freedom Style
======================================================*/
.political-freedom-content {
    background-color: $heading-color-4;
    background-size: cover;
    background-position: left;
}
.freedom-contet {
    padding-left: 60px;
    span {
        color: $white-color;
        font-family: $heading-font-family-2;
        margin-bottom: 15px;
        display: inline-block;
    }
    h2 {
        font-size: 40px;
        font-family: $body-font-family;
        color: $white-color;
        margin-bottom: 25px;
    }
}

/*Testimonial Style
======================================================*/
.political-testimonial-card {
    padding: 40px;
    padding-bottom: 50px;
    background-color: $white-color;
    margin-bottom: 30px;
    .ratinig-star {
        margin-bottom: 20px;
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                margin-right: 5px;
                &:last-child {
                    margin-right: 0;
                }
                i {
                    color: #ffc107;
                }
            }
        }
    }
    p {
        margin-bottom: 35px;
        font-family: $heading-font-family-2;
    }
    .clients-profile {
        position: relative;
        padding-left: 100px;
        img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: auto;
        }
        h3 {
            font-size: 22px;
            margin-bottom: 10px;
            font-family: $body-font-family;
            color: $heading-color-4;
        }
    }
}
.political-testimonial-slider {
    .swiper-pagination {
        position: initial;
        .swiper-pagination-bullet {
            background: #cccccc;
            width: 12px;
            height: 12px;
            opacity: 1;
            &.swiper-pagination-bullet-active {
                background: $political-main-color;
            }
        }
    }
}

/*News Style
======================================================*/
.political-join-us-form {
    padding: 30px;
    box-shadow: $box-shadow;
    h3 {
        font-size: 22px;
        margin-bottom: 30px;
        font-family: $body-font-family;
    }
    .form-group {
        margin-bottom: 25px;
        label {
            margin-bottom: 7px;
        }
    }
}
.political-news-card {
    .news-img {
        overflow: hidden;
        transition: $transition;
        img {
            transition: $transition;
            overflow: hidden;
        }
    }
    .news-content {
        padding-top: 30px;
        padding-bottom: 25px;
        .list {
            padding-left: 0;
            margin-bottom: 15px;
            li {
                display: inline-block;
                margin-right: 15px;
                position: relative;
                padding-left: 23px;
                font-size: 14px;
                a {
                    color: $heading-color-4;
                    transition: $transition;
                    &:hover {
                        color: $political-main-color;
                    }
                }
                i {
                    position: absolute;
                    left: 0;
                    top: 1px;
                    color: $political-main-color;
                }
            }
        }
        h2 {
            font-size: 22px;
            line-height: 1.4;
            a {
                color: $heading-color-4;
                transition: $transition;
                &:hover {
                    color: $political-main-color;
                }
            }
        }
    }
    &:hover {
        .news-img {
            img {
                transform: scale(1.2);
            }
        }
    }
}

/*Footer Style
======================================================*/
.political-footer-area {
    background-color: $heading-color-4;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

/*Crypto Currency Demo Style
=================================================
================================================*/

/*Banner Style
======================================================*/
.currency-banner-area {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding-top: 180px;
    padding-bottom: 50px;
    .container-fluid {
        max-width: 1690px;
        padding-left: 30px;
        padding-right: 30px;
    }
}
.currency-banner-content {
    margin-bottom: 60px;
    max-width: 720px;
    position: relative;
    span {
        color: $currency-main-color;
        font-family: $heading-font-family-2;
        display: inline-block;
        margin-bottom: 10px;
    }
    h1 {
        font-size: 85px;
        color: $white-color;
        font-family: $body-font-family;
        margin-bottom: 30px;
    }
    p {
        font-family: $heading-font-family-2;
        margin-bottom: 30px;
        color: $white-color;
    }
    .banner-shape-3 {
        position: absolute;
        left: -80px;
        top: 40%;
        animation: movebounce 5s linear infinite;
    }
    .banner-shape-4 {
        position: absolute;
        right: 0;
        bottom: -50px;
        animation: ripple 9s linear infinite;
    }
}
.currency-banner-image {
    position: relative;
    .banner-shape-1 {
        position: absolute;
        top: -50px;
        left: 0;
        animation: movebounce2 4s linear infinite;
    }
    .banner-shape-2 {
        position: absolute;
        top: 0;
        right: 150px;
        animation: movebounce 5s linear infinite;
    }
    .currency-form {
        max-width: 500px;
        padding: 30px;
        background: linear-gradient(112.92deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 252, 252, 0.1) 100%);
        backdrop-filter: blur(50px);
        border-radius: 10px;
        position: absolute;
        right: 0;
        bottom: 200px;
        .form-group {
            border: 1px solid $white-color;
            padding: 10px;
            margin-bottom: 25px;
            border-radius: 10px;
            position: relative;
            label {
                color: $white-color;
                margin-bottom: 0;
                padding-left: 10px;
            }
            .form-control {
                background-color: transparent;
                border: none;
                color: $white-color;
                font-weight: 700;
                height: auto;
                font-size: 18px;
                padding: 0;
                padding-left: 15px;
                &::placeholder {
                    color: $white-color;
                    font-size: 18px;
                }
            }
            .form-select {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                padding: 15px;
                font-weight: 600;
                color: $heading-color-4;
                width: 110px;
                border-radius: 0 10px 10px 0;
                box-shadow: none;
                background-image: url(../../images/svg-icon/icon-5.png);
            }
        }
        .currency-default-btn {
            width: 100%;
        }
    }
}

.cryptocurrency-contact-form {
    background-color: #25282e;
}

/*Features Style
======================================================*/
.currency-featured-card {
    padding: 30px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    text-align: center;
    margin-bottom: 30px;
    img {
        margin-bottom: 10px;
        transition: $transition;
    }
    h3 {
        font-size: 22px;
        font-family: $body-font-family;
        color: $white-color;
        margin-bottom: 20px;
    }
    p {
        font-family: $heading-font-family-2;
        color: $white-color;
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}

/*About Style
======================================================*/
.currency-about-content {
    .currency-about-title {
        span {
            font-size: 16px;
            color: $currency-main-color;
            letter-spacing: 3px;
            text-transform: uppercase;
            font-weight: 500;
            display: inline-block;
            margin-bottom: 10px;
            font-family: $heading-font-family-2;
        }
        h2 {
            font-size: 40px;
            font-family: $body-font-family;
            color: $white-color;
            font-weight: 700;
            margin-bottom: 25px;
        }
    }
    .about-text {
        margin-bottom: 30px;
        p {
            margin-bottom: 20px;
            color: $white-color;
            font-family: $heading-font-family-2;
        }
    }
}


/*Buy And Sell Area Style
======================================================*/
.currency-buy-and-sell-area {
    .scrollmenu {
        overflow: auto;
        flex-wrap: unset;
    }
    /* Firefox */
    * {
        scrollbar-width: auto;
        scrollbar-color: #1c2029 #0e1013;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 16px;
    }

    *::-webkit-scrollbar-track {
        background: #0e1013;
    }

    *::-webkit-scrollbar-thumb {
        background-color: #1c2029;
        border-radius: 0;
        border: 3px solid #0e1013;
    }
}
.currency-buy-card {
    display: inline-block;
    margin-bottom: 50px;
    margin-right: 20px;
    background-color: #0e1013;
    &:last-child {
        margin-right: 0;
    }
    .curency-buy-content {
        padding: 30px;
        h3 {
            font-size: 22px;
            color: $white-color;
            margin-bottom: 18px;
            font-family: $body-font-family;
        }
        p {
            color: $white-color;
            font-family: $heading-font-family-2;
            max-width: 410px;
        }
    }
}
.currency-intro-video-area {
    position: relative;
    &::before {
        position: absolute;
        content: '';
        background-color: #0e1013;
        left: 0;
        bottom: 0;
        height: 50%;
        width: 100%;
    }
    .video-image {
        position: relative;
        position: relative;
        overflow: hidden;
        img {
            overflow: hidden;
            transition: $transition;
        }
        .icon {
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            display: flex;
            z-index: 1;
            .play-btn {
                font-size: 26px;
                background-color: $currency-main-color;
                color: $white-color ;
                text-align: center;
                height: 70px;
                width: 70px;
                line-height: 75px;
                border-radius: 100%;
                transition: $transition;
                cursor: pointer;
                i {
                    position: relative;
                    left: 3px;
                    top: 1px;
                }
                &:hover {
                    background-color: $white-color;
                    color: $currency-main-color;
                }
            }
            &::before {
                position: absolute;
                content: '';
                height: 80px;
                width: 80px;
                border-radius: 100%;
                background-color: rgba(11, 109, 255, .60%);
                animation: ripple 4s linear infinite;
                z-index: -1;
            }
            &::after {
                position: absolute;
                content: '';
                height: 90px;
                width: 90px;
                border-radius: 100%;
                background-color: rgba(11, 109, 255, .40%);
                animation: ripple 3s linear infinite;
                z-index: -1;
            }
        }
        &:hover {
            img {
                transform: scale(1.2);
                filter: blur(3px);
            }
        }
    }
}
.currecy-user-content {
    text-align: center;
    position: relative;
    .currency-user-list {
        padding-left: 0;
        margin-bottom: 0;
        text-align: left;
        li {
            position: absolute;
            list-style-type: none;
            top: 38%;
            left: 245px;
            transition: $transition;
            .user-img {
                position: relative;
                border-radius: 100%;
                height: 82px;
                width: 82px;
                z-index: 1;
                transition: $transition;
                cursor: pointer;
                img {
                    width: 70px;
                    height: 70px;
                    border-radius: 100%;
                }
                &::before {
                    position: absolute;
                    content: '';
                    height: 78px;
                    width: 78px;
                    top: -10px;
                    left: -10px;
                    border-radius: 100%;
                    background-color: rgba(11, 109, 255, .60%);
                    z-index: -1;
                    opacity: 0;
                    transition: $transition;
                }
            }
            .user-content {
                max-width: 260px;
                padding: 30px;
                background-color: $white-color;
                text-align: left;
                border-radius: 10px;
                position: relative;
                top: -30px;
                left: 37px;
                display: none;
                visibility: hidden;
                transition: $transition;
                z-index: 3;
                .rating-list {
                    margin-bottom: 8px;
                    position: relative;
                    i {
                        color: #ffc107;
                        font-size: 14px;
                        margin-left: 3px;
                        &:first-child {
                            margin-left: 0;
                        }

                    }
                }
                p {
                    font-size: 14px;
                    font-family: $heading-font-family-2;
                    margin-bottom: 12px;
                }
                h3 {
                    font-size: 16px;
                    margin-bottom: 5px;
                }
                span {
                    font-size: 14px;
                }
                &::before {
                    position: absolute;
                    content: '';
                    height: 50px;
                    width: 50px;
                    border-left: 15px solid $white-color;
                    border-right: 15px solid $white-color;
                    border-top: 15px solid transparent;
                    border-bottom: 15px solid transparent;
                    left: -10px;
                    top: 15px;
                    transform: rotate(-20deg);
                }
            }
            &:hover {
                visibility: visible;
                .user-img {
                    border: 6px solid $currency-main-color;
                    &::before {
                        opacity: 1;
                        animation: ripple 4s linear infinite;
                    }
                }
                .user-content {
                    display: block;
                    visibility: visible;
                }
            }
            &:nth-child(2) {
                left: 280px;
                top: 66%;
            }
            &:nth-child(3) {
                left: 360px;
                top: 75%;
            }
            &:nth-child(4) {
                left: 400px;
                top: 10%;
                .user-img {
                    border: 6px solid $currency-main-color;
                    &::before {
                        opacity: 1;
                        animation: ripple 4s linear infinite;
                    }
                }
                
            }
            &:nth-child(5) {
                left: 680px;
                top: 35%;
            }
            &:nth-child(6) {
                left: 600px;
                top: 60%;
            }
            &:nth-child(7) {
                left: 720px;
                top: 20%;
            }
            &:nth-child(8) {
                left: 880px;
                top: 35%;
            }
            &:nth-child(9) {
                left: 1000px;
                top: 76%;
            }
            
            
        }
    }
}

/*Buy And Value Style
======================================================*/
.buy-and-value-table {
    margin-bottom: 40px;
    overflow-y: hidden;
    thead {
        tr {
            border: 1px solid rgba(255, 255, 255, .10%);
            th {
                color: $white-color;
                vertical-align: middle;
                white-space: nowrap;
                text-align: center;
                padding: 15px 15px;
            }
        }
    }
    tbody {
        tr {
            border: 1px solid rgba(255, 255, 255, .10%);
            td {
                vertical-align: middle;
                white-space: nowrap;
                padding: 15px 15px;
                color: $white-color;
                text-align: center;
                &.serial {
                    font-weight: 500;
                    color: $white-color;
                }
                &.name {
                    a {
                        position: relative;
                        padding-left: 25px;
                        display: inline-block;
                        i {
                            position: absolute;
                            color: $currency-main-color;
                            font-size: 18px;
                            top: 0;
                            left: 0;
                        }
                    }
                    p {
                        color: $white-color;
                        font-weight: 500;
                        span {
                            font-weight: 400;
                            color: #cccccc;
                        }
                        &:hover {
                            color: $currency-main-color;
                        }
                    }
                }
                &.price {
                    p {
                        color: $white-color;
                        span {
                            color: #cccccc;
                            display: inline-block;
                        }
                    }
                }
                &.change {
                    color: $currency-main-color;
                }
            }
        }
    }
}

/*Download Style
======================================================*/
.currency-download-area {
    .container {
        background-color: #0e1013;
        padding-left: 50px;
        padding-right: 50px;
        border-radius: 10px;
        z-index: 1;
        position: relative;
        &::before {
            position: absolute;
            content: '';
            background-color: #1c2029;
            height: 40px;
            width: 100%;
            left: 0;
            top: 0;
            z-index: -1;
        }
        &::after {
            position: absolute;
            content: '';
            background-color: #1c2029;
            height: 40px;
            width: 100%;
            left: 0;
            bottom: 0;
            z-index: -1;
        }
    }
}
.currency-download-content {
    .download-title {
        span {
            font-size: 16px;
            color: $currency-main-color;
            letter-spacing: 3px;
            text-transform: uppercase;
            font-weight: 500;
            display: inline-block;
            margin-bottom: 10px;
            font-family: $heading-font-family-2;
        }
        h2 {
            font-size: 40px;
            font-family: $body-font-family;
            color: $white-color;
            font-weight: 700;
            margin-bottom: 25px;
        }
    }
    p {
        margin-bottom: 30px;
        color: $white-color;
        font-family: $heading-font-family-2;
    }
    .download-btn {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }

                img {
                    border-radius: 5px;
                }
            }
        }
    }
}

/*Team Style
======================================================*/
.currency-team-card {
    position: relative;
    margin-bottom: 30px;
    .team-image {
        img {
            border-radius: 10px;
        }
    }
    .team-content {
        padding: 30px;
        position: absolute;
        bottom: 30px;
        left: 30px;
        right: 30px;
        border-radius: 10px;
        background-color: #0e1013;
        text-align: center;
        h3 {
            font-size: 22px;
            font-family: $body-font-family;
            color: $white-color;
            margin-bottom: 10px;
        }
        span {
            color: $white-color;
            font-family: $heading-font-family-2;
            display: inline-block;
            margin-bottom: 20px;
        }
        .social-links {
            ul {
                margin-bottom: 0;
                padding-left: 0;
                li {
                    margin: 0 3px;
                    display: inline-block;
                    a {
                        height: 40px;
                        width: 40px;
                        line-height: 43px;
                        background-color: transparent;
                        border: 1px solid rgba(255, 255, 255, 0.1);
                        border-radius: 100%;
                        box-shadow: $box-shadow;
                        color: $white-color;
                        display: inline-block;
                        text-align: center;
                        font-size: 16px;
                        transition: $transition;
                        &:hover {
                            background-color: $currency-main-color;
                            border-color: $currency-main-color;
                            color: $white-color;
                            transform: translateY(-5px);
                        }
                    }
                }
            }
        }
    }
}

/*Coin Sell Style
======================================================*/
.currency-coin-sell-content {
    .coin-sell-title {
        span {
            font-size: 16px;
            color: $currency-main-color;
            letter-spacing: 3px;
            text-transform: uppercase;
            font-weight: 500;
            display: inline-block;
            margin-bottom: 10px;
            font-family: $heading-font-family-2;
        }
        h2 {
            font-size: 40px;
            font-family: $body-font-family;
            color: $white-color;
            font-weight: 700;
            margin-bottom: 20px;
        }
        p {
            font-family: $heading-font-family-2;
            color: $white-color;
            margin-bottom: 30px;
        }
    }
    .coin-sell-btn {
        margin-bottom: 30px;
    }
    .bank-links {
        a {
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.currency-time-list {
    margin-bottom: 30px;
    ul {
        margin-bottom: 0;
        padding-left: 0;
        li {
            margin: 0 30px;
            height: 70px;
            width: 90px;
            font-size: 36px;
            font-weight: 700;
            margin-bottom: 30px;
            color: $white-color;
            position: relative;
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 10px;
            padding: 10px 20px;
            span {
               font-size: 15px;
               font-weight: 500;
               color: $white-color;
               position: absolute;
               bottom: -35px;
            }
            &::before {
                position: absolute;
                content: '';
                height: 6px;
                width: 6px;
                background-color: $white-color;
                border-radius: 100%;
                right: -30px;
                top: 26px;
            }
            &::after {
                position: absolute;
                content: '';
                height: 6px;
                width: 6px;
                background-color: $white-color;
                border-radius: 100%;
                right: -30px;
                top: 40px;
            }
            &:last-child {
                &::before {
                    display: none;
                }
                &::after {
                    display: none;
                }
            }
            &:first-child {
                margin-left: 0;
            }
        }
    }
}

/*Testimonial Style
======================================================*/
.cryptocurrency-testimonials {
    .swiper-pagination {
        position: initial;
        .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            background-color: #3e3e3e;
            opacity: 1;
            &.swiper-pagination-bullet-active {
                background-color: #fff;
            }
        }
    }
}
.currency-testimonial-card {
    background-color: #1c2029;
    padding: 40px;
    padding-bottom: 50px;
    margin-bottom: 40px;
    .rating-list {
        padding-left: 0;
        margin-bottom: 15px;
        li {
            display: inline-block;
            color: #ffc107;
            margin-right: 5px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    p {
        color: $white-color;
        margin-bottom: 35px;
    }
    .cliens-profile {
        position: relative;
        padding-left: 85px;
        img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 70px;
            height: 70px;
            border-radius: 100%;
        }
        h3 {
            font-size: 20px;
            font-family: $body-font-family;
            color: $white-color;
            margin-bottom: 10px;
        }
        span {
            color: #bdbdbd;
            font-family: $heading-font-family-2;
            font-size: 10px;
        }
    }
}

/*Blog Style
======================================================*/
.currency-blog-area {
    .text-center {
        a {
            color: $white-color;
            transition: $transition;
            &:hover {
                color: $currency-main-color;
            }
        }
    }
}
.currency-blog-card {
    margin-bottom: 30px;
    .blog-img {
        overflow: hidden;
        transition: $transition;
        border-radius: 10px;
        img {
            overflow: hidden;
            transition: $transition;
            border-radius: 10px;
        }
    }
    .blog-content {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding-top: 25px;
        padding-bottom: 30px;
        h2 {
            font-size: 22px;
            font-family: $body-font-family;
            margin-bottom: 15px;
            line-height: 1.5;
            a {
                color: $white-color;
                transition: $transition;
                &:hover {
                    color: $currency-main-color;
                }
            }
        }
        p {
            color: $white-color;
            font-family: $heading-font-family-2;
            margin-bottom: 15px;
        }
        a {
            color: $currency-main-color;
            font-family: $heading-font-family-2;
        }
    }
    &:hover {
        .blog-img {
            img {
                transform: scale(1.1);
            }
        }
    }
}

/*NFT Demo Style
=================================================
================================================*/

/*Banner Style
======================================================*/
.nft-banner-area {
    background-color: #0a0a0a;
    padding-top: 100px;
    .container-fluid {
        max-width: 1690px;
        padding-left: 30px;
        padding-right: 30px;
    }
}
.nft-banner-content {
    padding-right: 30px;
    span {
        color: $nft-main-color;
        font-family: $heading-font-family-2;
        font-size: 16px;
        margin-bottom: 15px;
        font-weight: 500;
        display: inline-block;
    }
    h1 {
        font-size: 80px;
        font-family: $body-font-family;
        margin-bottom: 15px;
        color: $white-color;
    }
    p {
        color: #cccccc;
        margin-bottom: 30px;
    }
}
.nft-banner-image {
    text-align: right;
    position: relative;
    margin-left: 50px;
    .current-bid {
        position: absolute;
        background: linear-gradient(112.92deg, rgba(255, 255, 255, 0.09) 0%, rgba(12, 10, 12, 0.8) 100%);
        backdrop-filter: blur(50px);
        max-width: 330px;
        padding: 30px;
        padding-top: 50px;
        left: 0;
        bottom: 200px;
        text-align: left;
        border-radius: 10px;
        .profile {
            position: relative;
            padding-left: 80px;
            margin-bottom: 50px;
            img {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 70px;
                height: 70px;
                border-radius: 100%;
            }
            h3 {
                font-size: 18px;
                font-family: $body-font-family;
                color: $white-color;
            }
        }
        .bid-list {
            padding-left: 0;
            margin-bottom: 20px;
            li {
                list-style-type: none;
                position: relative;
                &::before {
                    position: absolute;
                    content: '';
                    height: 50px;
                    width: 1px;
                    background-color: $white-color;
                    right: -32px;
                    top: 7px;
                }
                p {
                    color: #1ea5ff;
                    margin-bottom: 10px;
                }
                span {
                    color: $white-color;
                }
                &:last-child {
                    &::before {
                        display: none;
                    }
                    p {
                        color: $nft-main-color;
                    }
                }
            }
        }
        a {
            color: $white-color;
            border-bottom: 1px solid $white-color;
            padding-bottom: 7px;
            transition: $transition;
            i {
                position: relative;
                top: 2px;
                padding-left: 7px;
            }
            &:hover {
                color: $nft-main-color;
                border-color: $nft-main-color;
            }
        }
    }
}

/*Tranding Style
======================================================*/
.trnding-top-content {
    width: 100%;
    .tranding-btn {
        text-align: right;
        margin-bottom: 40px;
    }
}
.nft-tranding-card {
    background-color: #0a0a0a;
    border-radius: 10px;
    margin-bottom: 30px;
    .tanding-img {
        position: relative;
        img {
            border-radius: 10px 10px 0 0;
        }
        .tranding-list {
            position: absolute;
            left: 30px;
            bottom: -23px;
            ul {
                padding-left: 0;
                margin-bottom: 0;
                li {
                    display: inline-block;
                    margin-left: -20px;
                    &:first-child {
                        margin-left: 0;
                    }
                    img {
                        height: 50px;
                        width: 50px;
                        border: 3px solid $white-color;
                        border-radius: 100%;
                        cursor: pointer;
                        transition: $transition;
                        &:hover {
                            transform: scale(1.2);
                        }
                    }
                }
            }
        }
    }
    .tranding-content {
        padding: 30px;
        padding-top: 50px;
        .left-content {
            h3 {
                font-size: 22px;
                color: $white-color;
                font-family: $body-font-family;
                margin-bottom: 15px;
            }
            span {
                color: $white-color;
                font-size: 16px;
                font-family: $heading-font-family-2;
            }
        }
        .right-content {
            text-align: right;
            h4 {
                font-size: 16px;
                color: $white-color;
                margin-bottom: 15px;
                i {
                    color: $nft-main-color;
                    font-size: 16px;
                    position: relative;
                    top: 2px;
                    padding-right: 7px;
                }
            }
            span {
                color: $white-color;
                font-size: 16px;
                font-family: $heading-font-family-2;
            }
        }
    }
}

/*Top Sellers Style
======================================================*/
.top-sellers-top-content {
    .top-sellers-btn {
        margin-bottom: 40px;
        text-align: right;
    }
}
.nft-top-sellers-area {
    overflow: hidden;
}
.top-sellers-box {
    padding: 40px;
    border: 1px solid rgba(75, 75, 75, 0.8);
    border-radius: 10px;
    position: relative;
    margin-bottom: 30px;

    .number {
        position: absolute;
        font-size: 140px;
        font-weight: 700;
        left: 15px;
        top: -25px;
        opacity: .1;
    }
    .seller-address {
        position: relative;
        padding-left: 85px;
        > img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 70px;
            height: 70px;
            border-radius: 100%;
        }
        h3 {
            font-size: 19px;
            color: $white-color;
            font-family: $body-font-family;
            margin-bottom: 10px;
        }
        span {
            color: $white-color;
            font-family: $heading-font-family-2;
            img {
                position: relative;
                top: -3px;
                padding-right: 5px;
            }
        }
    }
    .sell {
        text-align: end;
        span {
            background: rgba(6, 219, 173, 0.15);
            color: $nft-main-color;
            display: inline-block;
            padding: 10px 20px;
            border-radius: 10px;
            border: 1px solid #11b18f;
        }
    }
}

@keyframes text-scrolling {
    0% {
        transform: translate3d(-100%, 0, 0);
    }
    100% {
        transform: translate3d(120%, 0, 0);
    }
}
@keyframes text-scrolling2 {
    100% {
        transform: translate3d(-100%, 0, 0);
    }
    0% {
        transform: translate3d(120%, 0, 0);
    }
}

/*Live Auction Style
======================================================*/
.auction-top-content {
    .auction-btn {
        text-align: right;
        margin-bottom: 40px;
    }
}
.live-auction-card {
    background-color: #0a0a0a;
    border-radius: 7px;
    .auction-image {
        position: relative;
        .live-auctions-countdown {
            position: absolute;
            padding-top: 20px;
            padding-bottom: 15px;
            bottom: 20px;
            left: 20px;
            right: 20px;
            background: linear-gradient(90.03deg, rgba(0, 0, 0, 0.5) 2.49%, rgba(0, 0, 0, 0.5) 116.75%);
            backdrop-filter: blur(4px);
            text-align: center;
            border-radius: 5px;
            padding-left: 0;
            li {
                position: relative;
                margin: 0 8px;
                span {
                height: 40px;
                width: 45px;
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 10px;
                color: $white-color;
                text-align: center;
                border: 1px solid rgba(255, 255, 255, 0.1);
                background-color: #0a0a0a;
                border-radius: 5px;
                padding: 7px;
                display: inline-block;
                }
                p {
                    font-size: 11px;
                    color: $white-color;
                }
                &::before {
                    position: absolute;
                    content: '';
                    height: 3px;
                    width: 3px;
                    background-color: $white-color;
                    border-radius: 100%;
                    right: -12px;
                    top: 15px;
                }
                &::after {
                    position: absolute;
                    content: '';
                    height: 3px;
                    width: 3px;
                    background-color: $white-color;
                    border-radius: 100%;
                    right: -12px;
                    top: 24px;
                }
                &:last-child {
                    margin-right: 0;
                    &::before {
                        display: none;
                    }
                    &::after {
                        display: none;
                    }
                }
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
    .auction-content {
        padding: 25px;
        .top-content {
            margin-bottom: 20px;
            .img-list {
                padding-left: 0;
                margin-bottom: 0;
                li {
                    display: inline-block;
                    margin-left: -13px;
                    &:first-child {
                        margin-left: 0;
                    }
                    img {
                        height: 32px;
                        width: 32px;
                        border: 3px solid $white-color;
                        border-radius: 100%;
                        cursor: pointer;
                        transition: $transition;
                        &:hover {
                            transform: scale(1.2);
                        }
                    }
                }
            }
            span{
                color: $white-color;
                font-size: 13px;
                position: relative;
                margin-left: -15px;
                top: 7px;
            }
            .more-btn {
                text-align: right;
                a {
                    color: $white-color;
                    font-size: 18px;
                    transition: $transition;
                    &:hover {
                        color: $nft-main-color;
                    }
                }
            }
        }
        h3 {
            font-size: 22px;
            color: $white-color;
            font-family: $body-font-family;
        }
        span {
            color: $white-color;
            font-family: $heading-font-family-2;
            display: inline-block;
            margin-bottom: 18px;
        }
        .blottom-content {
            ul {
                padding-left: 0;
                margin-bottom: 0;
                li {
                    list-style-type: none;
                    color: $white-color;
                    i {
                        position: relative;
                        color: $nft-main-color;
                        top: 3px;
                        padding-right: 7px;
                    }
                }
            }
        }
    }
}
.live-action-slider {
    .swiper-pagination {
        position: initial;
        margin-top: 30px;
        .swiper-pagination-bullet {
            background-color: #ccc;
            width: 12px;
            height: 12px;
            opacity: 1;
            &.swiper-pagination-bullet-active {
                background-color: $nft-main-color;
            }
        }
    }
}

/*Create And Sell Style
======================================================*/
.creat-and-sell-card {
    padding: 30px;
    background: linear-gradient(137.43deg, rgba(255, 255, 255, 0.3) 3.89%, rgba(255, 255, 255, 0.2) 100%);
    backdrop-filter: blur(23.2936px);
    border-radius: 7px;
    position: relative;
    margin-bottom: 30px;
    img {
        margin-bottom: 20px;
    }
    h3 {
        font-size: 22px;
        font-family: $body-font-family;
        color: $white-color;
        margin-bottom: 15px;
    }
    p {
        color: $white-color;
        margin-bottom: 0;
    }
    .number {
        font-size: 90px;
        color: $white-color;
        opacity: .1;
        position: absolute;
        font-weight: 700;
        top: 10px;
        right: 20px;
    }
}

/*Author Style
======================================================*/
.nft-author-top-content {
    .author-btn {
        text-align: right;
        margin-bottom: 40px;
    }
}
.nft-author-card {
    text-align: center;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) -1.52%, rgba(255, 255, 255, 0.024) 104.35%);
    backdrop-filter: blur(79.2003px);
    margin-bottom: 30px;
    border-radius: 7px;
    .author-img {
        position: relative;
        img {
            border-radius: 7px 7px 0 0;
        }
        .author-profile-img {
            position: absolute;
            bottom: -70px;
            left: 50%;
            transform: translateX(-50%);
            img {
                width: 150px;
                height: 150px;
                border-radius: 100%;
                border: 3px solid $white-color;
            }
        }
    }
    .author-content {
        padding: 100px 30px 35px 30px;
        h3 {
            font-size: 22px;
            color: $white-color;
            margin-bottom: 20px;
        }
        span {
            color: $nft-main-color;
            margin-bottom: 30px;
            display: inline-block;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                list-style-type: none;
                h3 {
                    font-size: 30px;
                    color: $white-color;
                    margin-bottom: 10px;
                }
                span {
                    margin-bottom: 30px;
                }
            }
        }
    }
}

/*Collection Style
======================================================*/
.collection-card {
    padding: 25px;
    background: linear-gradient(90deg, rgba(184, 183, 184, 0.1) -1.52%, rgba(105, 104, 107, 0.1) 104.35%);
    backdrop-filter: blur(79.2003px);
    margin-bottom: 30px;
    border-radius: 7px;
    .collction-top-content {
        margin-bottom: 25px;
        .author-profile {
            position: relative;
            padding-left: 70px;
            img {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 60px;
                height: 60px;
                border-radius: 100%;
            }
            h3 {
                font-size: 22px;
                color: $heading-color;
                font-family: $body-font-family;
            }
            span {
                font-family: $heading-font-family-2;
            }
        }
        .heart-and-number {
            text-align: right;
            span {
                color: #bd00ff;
                font-size: 16px;
                background-color: $white-color;
                border-radius: 5px;
                padding: 10px 15px;
                display: inline-block;
                i {
                    position: relative;
                    padding-right: 7px;
                    top: 2px;
                }
            }
        }
    }
    .collection-img-wrap {
        img {
            width: 100%;
        }
        .collection-img {
            margin-bottom: 24px;
            &.bg-1 {
                background-image: url(/images/collection/cullection-1.jpg);
                background-size: cover;
                background-position: center center;
                height: 100%;
                width: 100%;
                border-radius: 5px;
            }
            &.bg-2 {
                background-image: url(/images/collection/cullection-5.jpg);
                background-size: cover;
                background-position: center center;
                height: 100%;
                width: 100%;
                border-radius: 5px;
            }
            &.bg-3 {
                background-image: url(/images/collection/cullection-9.jpg);
                background-size: cover;
                background-position: center center;
                height: 100%;
                width: 100%;
                border-radius: 5px;
            }
        }
    }
}

/*Priducts Style
======================================================*/
.shorting {
    .mix {
        display: none;
    }
}
.products-top-content {
    .shoting-btn {
        text-align: right;
        margin-bottom: 40px;
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                button {
                    background-color: transparent;
                    border: 1px solid #eeeeee;
                    padding: 10px 30px;
                    border-radius: 5px;
                    transition: $transition;
                    &:hover, &:focus, &.active {
                        border-color: $nft-main-color;
                    }
                }
            }
        }
    }
}
.nft-product-card {
    background-color: #0a0a0a;
    margin-bottom: 30px;
    .product-img {
        position: relative;
        .number {
            position: absolute;
            top: 20px;
            left: 20px;
            span {
               color: $sass-main-color; 
               font-size: 16px;
               background-color: $white-color;
               padding: 8px 12px;
               border-radius: 5px;
               i {
                position: relative;
                top: 2px;
                padding-right: 7px;
               }
            }
        }
        .products-bid-btn {
            position: absolute;
            top: 50%;
            left: 24%;
            transition: $transition;
            transform: scaleY(0);
        }
    }
    .product-content {
        padding: 25px;
        .img-and-bid {
            position: relative;
            padding-left: 130px;
            margin-bottom: 40px;
            .img-list {
                padding-left: 0;
                margin-bottom: 0;
                position: absolute;
                left: 0;
                li {
                    display: inline-block;
                    margin-left: -20px;
                    &:first-child {
                        margin-left: 0;
                    }
                    img {
                        height: 40px;
                        width: 40px;
                        border: 3px solid $white-color;
                        border-radius: 100%;
                        cursor: pointer;
                        transition: $transition;
                        &:hover {
                            transform: scale(1.2);
                        }
                    }
                }
            }
            span {
                position: relative;
                top: 10px;
                color: $white-color;
            }
        }
        h3 {
            font-size: 22px;
            color: $white-color;
            font-family: $body-font-family;
            margin-bottom: 20px;
        }
        .bottom-list {
            padding-left: 0;
            margin-bottom: 0;
            li {
                list-style-type: none;
                span {
                    color: $white-color;
                    font-family: $heading-font-family-2;
                }
            }
        }
    }
    &:hover {
        .product-img {
            .products-bid-btn {
                transform: scaleY(1);

            }
        }
    }
}
.tranding-slider {
    .swiper-pagination {
        position: initial;
        .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            &.swiper-pagination-bullet-active {
                background-color: $nft-main-color;
            }
        }
    }
}

/*Currency Exchange Demo Style
=================================================
================================================*/

/*Header Style
======================================================*/
.currency-exchange-header-area {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    .navbar {
        position: unset;
    }
    .navbar-light {
        &.nav-style10 {
            &.is-sticky {
                background-color: #030734 !important;
                position: fixed !important;
            }
        }
    }
}
.currency-exchange-top-header-area {
    padding-top: 15px;
    padding-bottom: 15px;
    .container-fluid {
        max-width: 1690px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .exchange-header-left-content {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    color: $body-color;
                    transition: $transition;
                    &:hover {
                        color: $exchange-main-color;
                    }
                }
                i {
                    position: relative;
                    top: 1px;
                    padding-right: 10px;
                    color: $exchange-main-color;
                }
            }
        }
    }
    .exchange-header-middle-content {
        text-align: center;
        a {
            color: $exchange-main-color;
        }
    }
    .exchange-header-right-content {
        text-align: right;
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                margin-right: 30px;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    color: $body-color;
                    transition: $transition;
                    &:hover {
                        color: $exchange-main-color;
                    }
                }
                .language {
                    position: relative;
                    padding-left: 17px;
                    cursor: pointer;
                    top: 0;
                    select {
                        border: none;
                        background-color: transparent;
                        cursor: pointer;
                        &:focus {
                            outline: none;
                        }
                    }
                    i {
                        position: absolute;
                        left: 0;
                        top: 0;
                        color: $exchange-main-color;
                    }
                }
            }
        }
    }

}

/*Banner Style
======================================================*/
.currency-exchaange-banner {
    background-color: #f5f5f5;
    background-size: cover;
    padding-top: 60px;
    .container-fluid {
        max-width: 1690px;
        padding-left: 30px;
        padding-right: 30px;
    }
}
.currency-exchange-banner-content {
    margin-bottom: 60px;
    position: relative;
    span {
        color: $exchange-main-color;
        display: inline-block;
        margin-bottom: 10px;
        font-family: $heading-font-family-2;
    }
    h1 {
        font-size: 75px;
        font-family: $body-font-family;
        margin-bottom: 25px;
    }
    p {
        margin-bottom: 30px;
        font-family: $heading-font-family-2;
    }
    .banner-btn {
        margin-bottom: 40px;
    }
    .riviews {
        position: relative;
        padding-left: 170px;
        .img-list {
            padding-left: 0;
            margin-bottom: 0;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            li {
                display: inline-block;
                margin-left: -20px;
                &:first-child {
                    margin-left: 0;
                }
                img {
                    height: 50px;
                    width: 50px;
                    border: 3px solid $white-color;
                    border-radius: 100%;
                    cursor: pointer;
                    transition: $transition;
                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }
        }
        span {
            color: $body-color;
            position: relative;
            top: 6px;
        }
    }
    .banner-shape-4 {
        position: absolute;
        top: 10px;
        left: -50px;
        animation: rotateme 8s linear infinite;
    }
}
.currency-exchange-banner-image {
    position: relative;
    z-index: 1;
    .currency-converter-form {
        background-color: $white-color;
        padding: 30px;
        width: 400px;
        box-shadow: $box-shadow;
        border-radius: 10px;
        position: absolute;
        left: 30px;
        bottom: 120px;
        z-index: 1;
        h3 {
            font-size: 22px;
            font-family: $body-font-family;
            margin-bottom: 20px;
        }
        .form-group {
            margin-bottom: 25px;
            label {
                margin-bottom: 7px;
            }
            .form-control {
                background-color: $white-color;
            }
            &.icon-add1 {
                position: relative;
                .form-control {
                    padding-left: 40px;
                }
                img {
                    position: absolute;
                    left: 15px;
                    top: 48px;
                }
            }
            &.icon-add2 {
                position: relative;
                .form-control {
                    padding-left: 40px;
                }
                img {
                    position: absolute;
                    left: 15px;
                    top: 48px;
                }
            }
        }
        .exchange-default-btn {
            width: 100%;
        }
    }
    .banner-shape-1 {
        position: absolute;
        left: -30px;
        bottom: 130px;
        animation: movebounce 8s linear infinite;
        z-index: -1;

    }
    .banner-shape-2 {
        position: absolute;
        left: -50px;
        top: 160px;
        animation: movebounce 8s linear infinite;
    }
    .banner-shape-3 {
        position: absolute;
        right: 0;
        bottom: 200px;
        animation: rotateme 8s linear infinite;
    }
}

/*Features Style
======================================================*/
.exchange-features-card {
    padding: 30px;
    box-shadow: $box-shadow;
    border-left: 5px solid $exchange-main-color;
    border-radius: 10px;
    margin-bottom: 20px;
    .top-content {
        position: relative;
        padding-left: 50px;
        margin-bottom: 20px;
        span {
            background-color: $exchange-main-color;
            color: $white-color;
            height: 35px;
            width: 35px;
            line-height: 35px;
            border-radius: 100%;
            text-align: center;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        h3 {
            font-size: 22px;
            font-family: $body-font-family;
        }
    }
    p {
        margin-bottom: 15px;
    }
    a {
        color: $exchange-main-color;
        font-family: $heading-font-family-2;
        i {
            position: relative;
            top: 2px;
            padding-left: 7px;
        }
    }
}

/*About Style
======================================================*/
.exchainge-about-content {
    position: relative;
    .exchainge-about-title {
        span {
            color: $exchange-main-color;
            font-size: 16px;
            display: inline-block;
            margin-bottom: 10px;
            font-family: $heading-font-family-2;
        }
        h2 {
            font-size: 40px;
            font-family: $body-font-family;
            margin-bottom: 20px;
        }
    }
    .about-text {
        margin-bottom: 30px;
        p {
            margin-bottom: 20px;
            font-family: $heading-font-family-2;
        }
    }
    .about-shape-1 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -130px;
        animation: movebounce 8s linear infinite;

    }
}
.exchange-specialist-card {
    box-shadow: $box-shadow;
    border-radius: 7px;
    margin-bottom: 30px;
    transition: $transition;
    .specilist-img {
        position: relative;
        border-radius: 5px 5px 0 0;
        img {
            border-radius: 5px 5px 0 0;
        }
        .icon-link {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: $transition;
            opacity: 0;
            a {
                color: $white-color;
                font-weight: 700;
                font-size: 20px;
            }
        }
    }
    img {
        border-radius: 7px 7px 0  0;
    }
    .specialist-content {
        padding: 30px;
        h3 {
            font-size: 22px;
            font-family: $heading-font-family-2;
            margin-bottom: 17px;
        }
        p {
            margin-bottom: 18px;
        }
        a {
            color: $exchange-main-color;
            font-family: $heading-font-family-2;
            font-weight: 500;
            i {
                position: relative;
                top: 2px;
                padding-left: 7px;
            }
        }
    }
    &:hover {
        transform: translateY(-10px);
        .specilist-img {
            .icon-link {
                opacity: 1;
            }
            &::before {
                width: 100%;
            }
        }
    }
}

/*Choose Style
======================================================*/
.exchange-choose-card {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    margin-bottom: 30px;
    img {
        margin-bottom: 20px;
    }
    h3 {
        font-size: 22px;
        font-family: $body-font-family;
        margin-bottom: 15px;
    }
    p {
        font-family: $heading-font-family-2;
    }
}

/*Testimonial Style
======================================================*/
.exchange-testimonials-card {
    padding: 30px;
    padding-bottom: 40px;
    background-color: $white-color;
    margin-bottom: 40px;
    border-radius: 5px;
    position: relative;
    .rating-list {
        padding-left: 0;
        margin-bottom: 0;
        margin-bottom: 12px;
        li {
            display: inline-block;
            margin-right: 5px;
            &:last-child {
                margin-right: 0;
            }
            i {
                color: #ffc107;
            }
        }
    }
    p {
        margin-bottom: 25px;
        font-family: $heading-font-family-2;
    }
    .clients-profile {
        position: relative;
        padding-left: 90px;
        img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: auto;
            width: 70px;
            height: 70px;
            border-radius: 100%;
        }
        h3 {
            font-size: 18px;
            margin-bottom: 10px;
            font-family: $body-font-family;
        }
        p {
            margin-bottom: 0;
        }
    }
    .icon {
        position: absolute;
        bottom: 10px;
        right: 30px;
        i {
            font-size: 50px;
            color: $exchange-main-color;
        }
    }
}
.exchange-testimonials-slider {
    .swiper-pagination {
        position: initial;
        .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            &.swiper-pagination-bullet-active {
                background: $exchange-main-color;
            }
        }
    }
}

/*Blog Style
======================================================*/
.exchange-blog-card {
    background-color: $white-color;
    box-shadow: $box-shadow;
    border-radius: 7px;
    margin-bottom: 30px;
    transition: $transition;
    .blog-img {
        position: relative;
        border-radius: 5px 5px 0 0;
        img {
            border-radius: 5px 5px 0 0;
        }
        .icon-link {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: $transition;
            opacity: 0;
            a {
                color: $white-color;
                font-weight: 700;
                font-size: 20px;
            }
        }
        &::before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            height: 100%;
            width: 0%;
            border-radius: 5px 5px 0 0;
            background: rgba(3, 7, 52, 0.75);
            transition: $transition;
        }
    }
    .blog-contenet {
        padding: 30px;
        .list {
            padding-left: 0;
            margin-bottom: 0;
            margin-bottom: 15px;
            li {
                display: inline-block;
                margin-right: 15px;
                position: relative;
                padding-left: 23px;
                i {
                    position: absolute;
                    left: 0;
                    top: 2px;
                    color: $exchange-main-color;
                }
                &:last-child {
                    margin-right: 0;
                }
                a {
                    color: $heading-color;
                    font-weight: 500;
                    transition: $transition;
                    &:hover {
                        color: $exchange-main-color;
                    }
                }
            }
        }
        h3 {
            font-size: 22px;
            margin-bottom: 15px;
            line-height: 1.5;
            font-family: $body-font-family;
            a {
                color: $heading-color;
                transition: $transition;
                &:hover {
                    color: $exchange-main-color;
                }
            }
        }
        a {
            color: $exchange-main-color;
            font-family: $heading-font-family-2;
            i {
                position: relative;
                top: 2px;
                padding-left: 7px;
            }
        }
    }
    &:hover {
        transform: translateY(-10px);
        .blog-img {
            .icon-link {
                opacity: 1;
            }
            &::before {
                width: 100%;
            }
        }
    }
}

/*Partner Style
======================================================*/
.exchange-partners-slider {
    &.owl-carousel {
        .owl-item {
            img {
                width: auto;
                margin: auto;
            }
        }
    }
}

/*Faq Style
======================================================*/
.faq-accordion {
    .accordion {
        border: none;
        .accordion__item {
            display: block;
            padding: 0;
            margin-bottom: 30px;
            border: none;
            box-shadow: $box-shadow;
            border-radius: 7px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .accordion__button {
            position: relative;
            display: block;
            font-size: 20px;
            font-weight: 600;
            font-family: $body-font-family;
            background-color: #fff;
            color: $heading-color;

            &:before {
                position: relative;
                float: right;
                top: 11px;
            }
        }
        .accordion__panel {
            padding: 0 20px 20px 20px;
            
            p {
                font-weight: 400;
            }
        }
    }
}

/*Converter Style
======================================================*/
.converter-area {
    background-color: #13287e;
    position: relative;
    z-index: 1;
    .input-area {
        max-width: 1000px;
        margin: auto;
        .form-group {
            .form-control {
                border: none;
                border-radius: 7px 0 0 7px;
            }
            .form-select {
                background-color: $exchange-main-color;
                border-radius: 0 7px 7px 0;
                color: $white-color;
                background-image: url(/images/svg-icon/icon-6.png);
            }
        }
    }
    .bgb {
        text-align: center;
        ul {
            padding-left: 0;
            margin-bottom: 0;
            margin-top: 30px;
            li {
                display: inline-block;
                margin-right: 20px;
                color: $white-color;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    &::before {
        position: absolute;
        content: '';
        background-color: $white-color;
        width: 100%;
        height: 250px;
        left: 0;
        bottom: 0;
        z-index: -1;
    }
}
#particles-js {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}
.converter-form {
    background-color: $white-color;
    box-shadow: $box-shadow;
    padding: 30px 30px 10px;
    border-radius: 7px;
    margin-top: 30px;
    overflow-y: hidden;
    thead {
        tr {
            border: 1px solid #eeeeee;
            th {
                color: $heading-color;
                vertical-align: middle;
                white-space: nowrap;
                text-align: center;
                padding: 15px 15px;
            }
            .flag {
                img {
                    width: 35px;
                    height: 35px;
                    border-radius: 100%;
                }
            }
        }
    }
    tbody {
        tr {
            border: 1px solid #eeeeee;
            td {
                vertical-align: middle;
                white-space: nowrap;
                padding: 15px 15px;
                color: $body-color;
                text-align: center;
                &.code {
                    a {
                        font-weight: 500;
                        color: $exchange-main-color;
                    }
                }
            }
        }
    }
    .list {
        margin-top: 30px;
        margin-bottom: 20px;
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                list-style-type: none;
                span {
                    display: inline-block;
                    position: relative;
                    padding-left: 20px;
                    &::before {
                        position: absolute;
                        content: '';
                        height: 8px;
                        width: 8px;
                        border-radius: 100%;
                        background-color: $exchange-main-color;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
                a {
                    color: $exchange-main-color;
                    i {
                        position: relative;
                        top: 2px;
                        padding-left: 7px;
                    }
                }
            }
        }
    }
}

/*Blog Details Style
======================================================*/
.news-details {
    .news-simple-card {
        img {
            margin-bottom: 30px;
        }
        .list {
            margin-bottom: 15px;
            ul {
                padding-left: 0;
                margin-bottom: 0;
                li {
                    display: inline-block;
                    margin-right: 15px;
                    font-size: 15px;
                    i {
                        padding-right: 7px;
                        color: $sass-main-color;
                    }
                    a {
                        color: $body-color;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .news-details-content {
        margin-bottom: 30px;
        h1, h2, h3, h4, h5, h6 {
            margin-bottom: 15px;
        }
        h1 {
            font-size: 36px;
        }
        h2 {
            font-size: 33px;
        }
        h3 {
            font-size: 30px;
        }
        h4 {
            font-size: 27px;
        }
        h5 {
            font-size: 27px;
        }
        h6 {
            font-size: 23px;
        }
        p {
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        ul, ol {
            padding-left: 17px;
            li {
                margin-bottom: 10px;
                line-height: 1.8;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    

    .blog-img-and-content {
        margin-bottom: 30px;
        img {
            margin-bottom: 30px;
        }
    }
}
.tags-and-share {
    padding-bottom: 25px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 30px;
    .tags {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                margin-right: 10px;
                display: inline-block;
                span {
                    font-size: 18px;
                    font-weight: 500;
                    color: $heading-color;
                }
                a {
                    color: $body-color;
                    transition: $transition;
                    position: relative;
                    text-decoration: none;
                    &:hover {
                        color: $sass-main-color;
                    }
                    &::before {
                        position: absolute;
                        content: ',';
                        right: -4px;
                    }
                }
                &:last-child {
                    a {
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .share {
        text-align: right;
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                margin-left: 5px;
                span {
                    font-size: 18px;
                    font-weight: 500;
                    color: $heading-color;
                    margin-right: 5px;
                }
                a {
                    height: 35px;
                    width: 35px;
                    line-height: 38px;
                    border-radius: 100%;
                    background-color: #f4f6f9;
                    color: $sass-main-color;
                    display: inline-block;
                    text-align: center;
                    transition: $transition;
                    text-decoration: none;
                    &:hover {
                        background-color: $sass-main-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
}
.comments {
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 30px;
    h3 {
        font-size: 25px;
        margin-bottom: 20px;
    }
    .single-comments-box {
        padding-left: 100px;
        position: relative;
        margin-bottom: 30px;
    }
    img {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 80px;
        height: 80px;
    }
    .reply {
        position: absolute;
        right: 0;
        background-color: #f4f6f9;
        padding: 10px 15px;
        top: 15px;
        transition: $transition;
        a {
            color: $body-color;
            transition: $transition;
            text-decoration: none;
        }
        &:hover {
            background-color: $sass-main-color;
            a {
                color: $white-color;
            }
        }
    }
    h4 {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .date {
        margin-bottom: 10px;
    }
}
.reply-area {
    margin-bottom: 30px;
    h3 {
        font-size: 24px;
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 20px;
    }
    .reply-form {
        .form-group {
            margin-bottom: 25px;
            .form-control {
                background-color: #f4f6f9;
                border: 1px solid transparent;
                &:focus {
                    border-color: $sass-main-color;
                }
            }
        }
        .form-check {
            margin-bottom: 30px;
            .form-check-input {
                border-radius: 0;
                position: relative;
                top: -2px;
                &:checked {
                    background-color:$sass-main-color;
                    border-color: $sass-main-color;
                }
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
}
/*Page Banner Style
======================================================*/
.page-banner-area {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 200px;
    padding-bottom: 150px;
    &::before {
        position: absolute;
        content: '';
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: rgba(17, 29, 94, 0.8);
    }
}
.page-banner-content {
    position: relative;
    h1 {
        font-size: 50px;
        margin-bottom: 15px;
        color: $white-color;
    }
    ul {
        padding-left: 0;
        margin-bottom: 0;
        li {
            display: inline-block;
            position: relative;
            padding-right: 25px;
            font-size: 16px;
            font-weight: 500;
            color: $white-color;
            a {
                text-decoration: none;
                color: $white-color;
            }
            &::before {
                position: absolute;
                content: '/';
                right: 9px;
                top: 0;
            }
            &:last-child {
                color: $sass-main-color;
                &::before {
                    display: none;
                }
                padding-right: 0;
            }
        }
    }
}

/*Services Details Style
======================================================*/
.services-details {
    .top-image {
        margin-bottom: 30px;
        img {
            border-radius: 10px;
            width: 100%;
        }
    }
    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 15px;
    }
    h1 {
        font-size: 27px;
    }
    h2 {
        font-size: 26px;
    }
    h3 {
        font-size: 23px;
    }
    h4 {
        font-size: 20px;
    }
    h5 {
        font-size: 18px;
    }
    h6 {
        font-size: 16px;
    }
    ul, ol {
        li {
            margin-bottom: 10px;
            line-height: 1.6;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

/*Project Details Style
======================================================*/
.project-details-content {
    margin-bottom: 30px;
    .top-image {
        margin-bottom: 30px;
        img {
            border-radius: 10px;
        }
    }
    .project-details-text {
        h1, h2, h3, h4, h5, h6 {
            margin-bottom: 15px;
        }
        h1 {
            font-size: 27px;
        }
        h2 {
            font-size: 26px;
        }
        h3 {
            font-size: 23px;
        }
        h4 {
            font-size: 20px;
        }
        h5 {
            font-size: 18px;
        }
        h6 {
            font-size: 16px;
        }
        ul, ol {
            li {
                margin-bottom: 10px;
                line-height: 1.6;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.project-details {
    padding: 30px;
    box-shadow: $box-shadow;
    margin-bottom: 30px;
    h3 {
        font-size: 20px;
        margin-bottom: 25px;
    }
    ul {
        padding-left: 0;
        margin-bottom: 0;
        li {
            list-style-type: none;
            margin-bottom: 15px;
            span {
                color: $heading-color;
                font-weight: 500;
                padding-right: 5px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}


.main-text-content {
    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 15px;
    }
    h1 {
        font-size: 30px;
    }
    h2 {
        font-size: 27px;
    }
    h3 {
        font-size: 26px;
    }
    h4 {
        font-size: 23px;
    }
    h5 {
        font-size: 20px;
    }
    h6 {
        font-size: 18px;
    }
    ul, ol {
        li {
            margin-bottom: 10px;
            line-height: 1.6;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    a {
        color: $app-main-color;
    }
}

.about-content {
    .about-title {
        margin-bottom: 30px;
        h2 {
            font-size: 30px;
            margin-bottom: 15px;
        }
    }
    .about-text {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
        h4 {
            font-size: 20px;
            margin-bottom: 15px;
        }
        ul {
            margin: 0;
            li {
                margin-bottom: 10px;
                line-height: 1.8;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.contact-info-card {
    margin-bottom: 30px;
    background-color: #f7f7f7;
    text-align: center;
    padding: 50px 30px;
    border-radius: 10px;
    i {
        display: inline-block;
        background: $consulting-main-color;
        color: #fff;
        width: 80px;
        height: 80px;
        line-height: 80px;
        font-size: 30px;
        border-radius: 100%;
        margin-bottom: 20px;
    }
    p {
        font-size: 18px;
        a {
            color: #000;
            &:hover {
                color: $consulting-main-color;
            }
        }
    }
}

/* Sidebar Demo Modal Style */
.demos-btn {
    position: fixed;
    top: 30%;
    right: 0;
    background-color: red;
    color: #fff;
    z-index: 9999;
    display: inline-block;
    cursor: pointer;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-orientation: upright;
    text-transform: uppercase;
    padding: 10px 7px;
    border-radius: 5px 0 0 5px;
    -webkit-transition: all .5s;
    transition: all .5s;
}

.sidebar-demo-modal {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba(0, 0, 0, .95);
    padding: 100px 30px;
    overflow-y: auto;
    transition: $transition;

    &.show {
        right: 0;
        left: 0;
        transition: $transition;
    }

    .close-icon {
        background-color: red;
        color: #fff;
        position: absolute;
        top: 25px;
        right: 25px;
        text-align: center;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 100px;
        cursor: pointer;
        font-size: 25px;
        &:hover {
            background-color: #d50505;
        }
    }
    .card {
        background-color: #fff;
        text-align: center;
        h4 {
            padding: 20px;
            margin: 0;
            font-size: 20px;
            border-top: 1px solid #eee;
        }
        &:hover h4 {
            color: $app-main-color;
        }
    }
}
/* End Sidebar Demo Modal Style */