/*
@File: Cyrnetics Labs Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the template.

This files table contents are outlined below>>>>>

******************************************* 
** - Default-style
** - Navbar-style
** - Modal-style
** - Banner-style
** - Features-style
** - Partner-style
** - Data Analysis-style
** - Solution-style
** - Core-Statics-style
** - Counter-style
** - Pricing-style
** - Reviews-style
** - Blog-style
** - Contact-style
** - Footer-style
** - Acquaintance-style
** - Design-style
** - Download-style
** - Team-style
** - Choose-style
** - About-style
** - Services-style
** - Portfolio-style
** - Testimonial-style
** - Instagram-style
** - Coming-Soon-style
** - Header-style
** - Movement-style
** - Volunteer-style
** - Freedom-style
** - Buy-&-Sell-style
** - Coin-Sell-style
** - Tranding-style
** - Top-Sellers-style
** - Live-Auction-style
** - Author-style
** - Collection-style
** - Priducts-style
*******************************************
*/
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mukta:wght@200;300;400;500;600;700;800&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/*Default-style
======================================================*/
body {
  color: #555555;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

img {
  max-width: 100%;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.ptb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pl-15 {
  padding-left: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.mr-20 {
  margin-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.mlr-5 {
  margin-right: 5px;
  margin-left: 5px;
}

.mlrt-5 {
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 5px;
}

.right-15 {
  right: 15px !important;
}

h1, h2, h3, h4, h5, h6 {
  color: #19191b;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 700;
}

p {
  margin-bottom: 15px;
  font-size: 16px;
  color: #555555;
  font-family: "Poppins", sans-serif;
  line-height: 1.8;
}

p:last-child {
  margin-bottom: 0;
}

.form-control {
  height: 55px;
  border: 1px solid #eeeeee;
  padding-left: 15px;
}

.form-control:focus {
  border: 1px solid #0797ff;
  -webkit-box-shadow: none;
          box-shadow: none;
}

a {
  text-decoration: none;
}

textarea {
  height: auto !important;
}

button {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.bg-f7f7f7 {
  background-color: #f7f7f7;
}

/*Section-title
======================================================*/
.sass-section-title {
  max-width: 530px;
  margin: 0 auto 40px auto;
  text-align: center;
  position: relative;
}

.sass-section-title span {
  font-size: 16px;
  margin-bottom: 10px;
  display: inline-block;
  color: #0797ff;
}

.sass-section-title h2 {
  font-size: 40px;
}

.app-section-title {
  max-width: 530px;
  margin: 0 auto 40px auto;
  text-align: center;
  position: relative;
}

.app-section-title span {
  font-size: 16px;
  margin-bottom: 10px;
  display: inline-block;
  color: #f4732a;
}

.app-section-title h2 {
  font-size: 40px;
  font-family: "Roboto", sans-serif;
}

.agency-section-title {
  max-width: 530px;
  margin: -5px auto 40px auto;
  text-align: center;
  position: relative;
}

.agency-section-title span {
  font-size: 16px;
  margin-bottom: 10px;
  display: inline-block;
  color: #f4732a;
  font-family: "Karla", sans-serif;
}

.agency-section-title h2 {
  font-size: 40px;
  font-family: "Montserrat", sans-serif;
  color: #0b1460;
}

.consulting-section-title {
  max-width: 760px;
  margin: 0 auto 50px auto;
  text-align: center;
  position: relative;
}

.consulting-section-title span {
  font-size: 16px;
  margin-bottom: 10px;
  display: inline-block;
  color: #3561e4;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 500;
}

.consulting-section-title h2 {
  font-size: 40px;
  font-family: "Mulish", sans-serif;
  color: #181c27;
}

.consulting-section-title::before {
  position: absolute;
  content: '';
  height: 3px;
  width: 100px;
  background-color: #3561e4;
  bottom: -20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.consulting-section-title::after {
  position: absolute;
  content: '';
  height: 3px;
  width: 7px;
  bottom: -20px;
  background-color: #ffffff;
  -webkit-animation: movebounce2 4s linear infinite;
          animation: movebounce2 4s linear infinite;
}

.photography-section-title {
  max-width: 630px;
  margin: -5px auto 40px auto;
  text-align: center;
  position: relative;
}

.photography-section-title span {
  font-size: 16px;
  color: #9C5451;
  letter-spacing: 3px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 10px;
  font-family: "Mukta", sans-serif;
}

.photography-section-title h2 {
  font-size: 40px;
  font-family: "Playfair Display", serif;
  color: #19191b;
  font-weight: 600;
}

.political-section-title {
  text-align: center;
  position: relative;
  margin: -5px auto 40px auto;
}

.political-section-title span {
  font-size: 16px;
  color: #dd131a;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
}

.political-section-title h2 {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  color: #0c1a3c;
  font-weight: 700;
}

.currency-section-title {
  max-width: 730px;
  margin: 0 auto 40px auto;
  text-align: center;
  position: relative;
}

.currency-section-title span {
  font-size: 16px;
  color: #0b6dff;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
}

.currency-section-title h2 {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  font-weight: 700;
}

.nft-section-title {
  margin: -5px 0 40px 0;
  position: relative;
  padding-left: 50px;
}

.nft-section-title h2 {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  color: #19191b;
  font-weight: 700;
}

.nft-section-title::before {
  position: absolute;
  content: '';
  height: 30px;
  width: 30px;
  background: -webkit-gradient(linear, left top, right top, color-stop(4.17%, #06DBAC), to(#1EA5FF));
  background: linear-gradient(90deg, #06DBAC 4.17%, #1EA5FF 100%);
  border-radius: 100%;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.nft-section-title::after {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  background: #ffffff;
  border-radius: 100%;
  left: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.nft-section-title.style2 h2 {
  color: #ffffff;
}

.nft-section-title.style2::after {
  background-color: #0a0a0a;
}

.exchange-section-title {
  text-align: center;
  position: relative;
  margin: -5px auto 40px auto;
}

.exchange-section-title span {
  font-size: 16px;
  color: #00a9a4;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
}

.exchange-section-title h2 {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.exchange-section-title.style2 h2 {
  color: #ffffff;
}

.bg-color-f8f8f8 {
  background-color: #f8f8f8;
}

.bg-color-efedf0 {
  background-color: #efedf0;
}

.bg-color-f9f5f4 {
  background-color: #f9f5f4;
}

.bg-color-f9f9f9 {
  background-color: #f9f9f9;
}

.bg-color-eef1f6 {
  background-color: #eef1f6;
}

.bg-color-1c2029 {
  background-color: #1c2029;
}

.bg-color-0e1013 {
  background-color: #0e1013;
}

.bg-color-0a0a0a {
  background-color: #0a0a0a;
}

.bg-color-f5f5f5 {
  background-color: #f5f5f5;
}

/*Navbar-style
======================================================*/
.navbar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  padding: 15px 0;
  height: auto;
}

.navbar.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 0;
  z-index: 999;
  -webkit-box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
          animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.navbar .container-fluid {
  max-width: 1690px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}

.navbar .others-options a {
  background-color: #6064e3;
  color: #ffffff;
  padding: 15px 30px;
  border-radius: 7px;
  margin-left: 40px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  display: inline-block;
  text-decoration: none;
}

.navbar .others-options a:hover {
  background-color: #0797ff;
  color: #ffffff;
}

.navbar .others-options.style3 a {
  background-color: #0b1460;
  color: #ffffff;
}

.navbar .others-options.style3 a i {
  position: relative;
  right: 0;
  top: 2px;
  padding-left: 7px;
}

.navbar .others-options.style3 a:hover {
  background-color: #f4732a;
  color: #ffffff;
}

.navbar .others-options.style4 a {
  background-color: #181c27;
  color: #ffffff;
}

.navbar .others-options.style4 a i {
  position: relative;
  right: 0;
  top: 2px;
  padding-left: 7px;
}

.navbar .others-options.style4 a:hover {
  background-color: #3561e4;
  color: #ffffff;
}

.navbar .others-options.style7 a {
  background-color: #dd131a;
  color: #ffffff;
  border-radius: 0;
}

.navbar .others-options.style7 a i {
  position: relative;
  right: 0;
  top: 2px;
  padding-left: 7px;
}

.navbar .others-options.style7 a:hover {
  background-color: #181c27;
  color: #ffffff;
}

.navbar .others-options.style9 a {
  margin-left: 0;
}

.navbar .others-options.style10 a {
  background-color: #00a9a4;
  color: #ffffff;
}

.navbar .others-options.style10 a:hover {
  background-color: #ffffff;
  color: #030734;
}

.navbar .others-options.style10 a i {
  position: relative;
  top: 2px;
  padding-left: 7px;
}

.navbar .others-options .modal-btn {
  text-align: center;
  margin-left: 40px;
}

.navbar .others-options .modal-btn i {
  color: #ffffff;
  font-size: 30px;
  background-color: #222222;
  height: 50px;
  width: 60px;
  line-height: 50px;
  cursor: pointer;
  display: inline-block;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.navbar .others-options .modal-btn i:hover {
  color: #9C5451;
  background-color: #ffffff;
}

.bg-light {
  background-color: transparent !important;
}

.navbar-brand .white-logo {
  display: none;
}

.navbar-light.nav-style2 {
  background-color: #ffffff;
}

.navbar-light.nav-style2 .others-options a {
  background-color: #f4732a;
  color: #ffffff;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.navbar-light.nav-style2 .others-options a:hover {
  background-color: #36234b;
  color: #ffffff;
}

.navbar-light.nav-style2 .navbar-nav .nav-item .nav-link:hover, .navbar-light.nav-style2 .navbar-nav .nav-item .nav-link:focus, .navbar-light.nav-style2 .navbar-nav .nav-item .nav-link.active {
  color: #f4732a;
}

.navbar-light.nav-style2 .navbar-nav .nav-item.active .nav-link {
  color: #f4732a;
}

.navbar-light.nav-style2.is-sticky .navbar-nav .nav-item .nav-link:hover, .navbar-light.nav-style2.is-sticky .navbar-nav .nav-item .nav-link:focus, .navbar-light.nav-style2.is-sticky .navbar-nav .nav-item .nav-link.active {
  color: #f4732a;
}

.navbar-light.nav-style5 .navbar-nav .nav-item .nav-link:hover, .navbar-light.nav-style5 .navbar-nav .nav-item .nav-link:focus, .navbar-light.nav-style5 .navbar-nav .nav-item .nav-link.active {
  color: #f4732a;
}

.navbar-light.nav-style5 .navbar-nav .nav-item.active .nav-link {
  color: #f4732a;
}

.navbar-light.nav-style5.is-sticky .navbar-nav .nav-item .nav-link:hover, .navbar-light.nav-style5.is-sticky .navbar-nav .nav-item .nav-link:focus, .navbar-light.nav-style5.is-sticky .navbar-nav .nav-item .nav-link.active {
  color: #f4732a;
}

.navbar-light.nav-style3 {
  background-color: #ffffff;
}

.navbar-light.nav-style3 .navbar-nav .nav-item .nav-link:hover, .navbar-light.nav-style3 .navbar-nav .nav-item .nav-link:focus, .navbar-light.nav-style3 .navbar-nav .nav-item .nav-link.active {
  color: #3561e4;
}

.navbar-light.nav-style3 .navbar-nav .nav-item.active .nav-link {
  color: #3561e4;
}

.navbar-light.nav-style3.is-sticky .navbar-nav .nav-item .nav-link:hover, .navbar-light.nav-style3.is-sticky .navbar-nav .nav-item .nav-link:focus, .navbar-light.nav-style3.is-sticky .navbar-nav .nav-item .nav-link.active {
  color: #3561e4;
}

.navbar-light.nav-style4 {
  background-color: transparent;
}

.navbar-light.nav-style4 .others-options a {
  background-color: #f4732a;
  color: #ffffff;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.navbar-light.nav-style4 .others-options a:hover {
  background-color: #36234b;
  color: #ffffff;
}

.navbar-light.nav-style4 .navbar-nav .nav-item .nav-link {
  color: #ffffff;
}

.navbar-light.nav-style4 .navbar-nav .nav-item .nav-link:hover, .navbar-light.nav-style4 .navbar-nav .nav-item .nav-link:focus, .navbar-light.nav-style4 .navbar-nav .nav-item .nav-link.active {
  color: #9C5451;
}

.navbar-light.nav-style4.is-sticky {
  background-color: #191919 !important;
}

.navbar-light.nav-style4.is-sticky .nav-item .nav-link {
  color: #ffffff !important;
}

.navbar-light.nav-style4.is-sticky .nav-item .nav-link:hover, .navbar-light.nav-style4.is-sticky .nav-item .nav-link:focus, .navbar-light.nav-style4.is-sticky .nav-item .nav-link.active {
  color: #9C5451 !important;
}

.navbar-light .navbar-nav {
  z-index: 999;
}

.navbar-light .navbar-nav .nav-item {
  margin-bottom: 0;
  padding-right: 20px;
  padding-left: 20px;
}

.navbar-light .navbar-nav .nav-item .nav-link {
  color: #19191b;
  padding: 0;
  position: relative;
  font-size: 15px;
  font-weight: 600;
}

.navbar-light .navbar-nav .nav-item .nav-link:hover, .navbar-light .navbar-nav .nav-item .nav-link:focus, .navbar-light .navbar-nav .nav-item .nav-link.active {
  color: #0797ff;
}

.navbar-light .navbar-nav .nav-item:last-child {
  padding-right: 0;
}

.navbar-light .navbar-nav .nav-item:first-child {
  padding-left: 0;
}

.navbar-light .navbar-nav .nav-item.active .nav-link {
  color: #0797ff;
}

.navbar-light .navbar-nav .nav-item.active .nav-link::before {
  bottom: -13px;
  opacity: 1;
  visibility: visible;
}

.navbar-light.is-sticky .navbar-nav .nav-item .nav-link {
  color: #19191b;
}

.navbar-light.is-sticky .navbar-nav .nav-item .nav-link:hover, .navbar-light.is-sticky .navbar-nav .nav-item .nav-link:focus, .navbar-light.is-sticky .navbar-nav .nav-item .nav-link.active {
  color: #0797ff;
}

.navbar-light.nav-style6 {
  background-color: #ffffff;
}

.navbar-light.nav-style7 {
  padding: 0;
}

.navbar-light.nav-style7 .container-fluid {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 15px 30px;
}

.navbar-light.nav-style7 .navbar-nav .nav-item .nav-link:hover, .navbar-light.nav-style7 .navbar-nav .nav-item .nav-link:focus, .navbar-light.nav-style7 .navbar-nav .nav-item .nav-link.active {
  color: #dd131a;
}

.navbar-light.nav-style7 .navbar-nav .nav-item.active .nav-link {
  color: #dd131a;
}

.navbar-light.nav-style7.is-sticky .navbar-nav .nav-item .nav-link:hover, .navbar-light.nav-style7.is-sticky .navbar-nav .nav-item .nav-link:focus, .navbar-light.nav-style7.is-sticky .navbar-nav .nav-item .nav-link.active {
  color: #dd131a;
}

.navbar-light.nav-style8 {
  background-color: transparent;
}

.navbar-light.nav-style8 .others-options {
  margin-left: 40px;
}

.navbar-light.nav-style8 .others-options ul {
  padding-left: 0;
  margin-bottom: 0;
}

.navbar-light.nav-style8 .others-options ul li {
  display: inline-block;
  margin-right: 15px;
}

.navbar-light.nav-style8 .others-options ul li:last-child {
  margin-right: 0;
}

.navbar-light.nav-style8 .others-options ul li a {
  padding: 15px 20px;
  background-color: #1C2029;
  color: #ffffff;
  border-radius: 10px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  margin: 0;
}

.navbar-light.nav-style8 .others-options ul li a:hover {
  background-color: #0b6dff;
  color: #ffffff;
}

.navbar-light.nav-style8 .navbar-nav .nav-item .nav-link {
  color: #ffffff;
}

.navbar-light.nav-style8 .navbar-nav .nav-item .nav-link:hover, .navbar-light.nav-style8 .navbar-nav .nav-item .nav-link:focus, .navbar-light.nav-style8 .navbar-nav .nav-item .nav-link.active {
  color: #0b6dff;
}

.navbar-light.nav-style8.is-sticky {
  background-color: #191919 !important;
}

.navbar-light.nav-style8.is-sticky .nav-item .nav-link {
  color: #ffffff !important;
}

.navbar-light.nav-style8.is-sticky .nav-item .nav-link:hover, .navbar-light.nav-style8.is-sticky .nav-item .nav-link:focus, .navbar-light.nav-style8.is-sticky .nav-item .nav-link.active {
  color: #0b6dff !important;
}

.navbar-light.nav-style9 {
  background-color: transparent;
}

.navbar-light.nav-style9 .others-options {
  margin-left: 40px;
}

.navbar-light.nav-style9 .navbar-nav .nav-item .nav-link {
  color: #ffffff;
}

.navbar-light.nav-style9 .navbar-nav .nav-item .nav-link:hover, .navbar-light.nav-style9 .navbar-nav .nav-item .nav-link:focus, .navbar-light.nav-style9 .navbar-nav .nav-item .nav-link.active {
  color: #06dbac !important;
}

.navbar-light.nav-style9.is-sticky {
  background-color: #191919 !important;
}

.navbar-light.nav-style9.is-sticky .nav-item .nav-link {
  color: #ffffff !important;
}

.navbar-light.nav-style9.is-sticky .nav-item .nav-link:hover, .navbar-light.nav-style9.is-sticky .nav-item .nav-link:focus, .navbar-light.nav-style9.is-sticky .nav-item .nav-link.active {
  color: #06dbac !important;
}

.navbar-light.nav-style10 {
  background-color: #030734;
}

.navbar-light.nav-style10 .navbar-nav .nav-item .nav-link {
  color: #ffffff;
}

.navbar-light.nav-style10 .navbar-nav .nav-item .nav-link:hover, .navbar-light.nav-style10 .navbar-nav .nav-item .nav-link:focus, .navbar-light.nav-style10 .navbar-nav .nav-item .nav-link.active {
  color: #00a9a4 !important;
}

.navbar-light.nav-style10.is-sticky {
  background-color: #030734 !important;
}

.navbar-light.nav-style10.is-sticky .nav-item .nav-link {
  color: #ffffff !important;
}

.navbar-light.nav-style10.is-sticky .nav-item .nav-link:hover, .navbar-light.nav-style10.is-sticky .nav-item .nav-link:focus, .navbar-light.nav-style10.is-sticky .nav-item .nav-link.active {
  color: #00a9a4 !important;
}

.navbar-light.nav-style11 {
  background-color: transparent;
}

.navbar-light.nav-style11 .others-options a {
  background-color: #0797ff;
  color: #ffffff;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.navbar-light.nav-style11 .others-options a:hover {
  background-color: #19191b;
  color: #ffffff;
}

.navbar-light.nav-style11 .navbar-nav .nav-item .nav-link {
  color: #ffffff;
}

.navbar-light.nav-style11 .navbar-nav .nav-item .nav-link:hover, .navbar-light.nav-style11 .navbar-nav .nav-item .nav-link:focus, .navbar-light.nav-style11 .navbar-nav .nav-item .nav-link.active {
  color: #0797ff;
}

.navbar-light.nav-style11.is-sticky {
  background-color: #191919 !important;
}

.navbar-light.nav-style11.is-sticky .nav-item .nav-link {
  color: #ffffff !important;
}

.navbar-light.nav-style11.is-sticky .nav-item .nav-link:hover, .navbar-light.nav-style11.is-sticky .nav-item .nav-link:focus, .navbar-light.nav-style11.is-sticky .nav-item .nav-link.active {
  color: #0797ff !important;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: .6;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: .6;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@-webkit-keyframes movebounce2 {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes movebounce2 {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

/* Navbar toggler */
.navbar-toggler {
  border: none;
  border-radius: 0;
  padding: 0;
}

.navbar-toggler:focus {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.navbar-toggler .icon-bar {
  width: 35px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #221638;
  height: 4px;
  display: block;
  border-radius: 3px;
}

.navbar-toggler .top-bar {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
          transform-origin: 10% 10%;
  left: 4px;
  position: relative;
}

.navbar-toggler .middle-bar {
  opacity: 0;
  margin: 5px 0;
}

.navbar-toggler .bottom-bar {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
          transform-origin: 10% 90%;
  left: 4px;
  position: relative;
}

.navbar-toggler.collapsed .top-bar {
  -webkit-transform: rotate(0);
          transform: rotate(0);
  left: 0;
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}

.navbar-toggler.collapsed .bottom-bar {
  -webkit-transform: rotate(0);
          transform: rotate(0);
  left: 0;
  margin-top: 6px;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  overflow: hidden;
  background: #19191b;
}

.preloader .loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 110px;
  height: 110px;
  margin: -80px 0 0 -75px;
  border-radius: 50%;
  border: 7px solid transparent;
  border-top-color: #0797ff;
  -webkit-animation: spin 1.7s linear infinite;
          animation: spin 1.7s linear infinite;
  z-index: 11;
}

.preloader .loader::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 7px solid transparent;
  border-top-color: #ffffff;
  -webkit-animation: spin-reverse .6s linear infinite;
          animation: spin-reverse .6s linear infinite;
}

.preloader .loader::after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 7px solid transparent;
  border-top-color: #0797ff;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

/*Sidebar Modal
======================================================*/
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 420px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
  right: -100%;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  background-color: #0e0e0e;
  border: none;
  border-radius: 0;
}

.modal.right .modal-content button.close {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: transparent;
  border: none;
  text-shadow: unset;
  -webkit-box-shadow: unset;
          box-shadow: unset;
  z-index: 99;
  opacity: 1;
}

.modal.right .modal-content button.close i {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background-color: #9C5451;
  color: #ffffff;
  font-size: 20px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 50%;
}

.modal.right .modal-content button.close i:hover {
  background-color: #19191b;
}

.modal.right.show .modal-dialog {
  right: 0;
}

.sidebarModal {
  position: relative;
}

.sidebarModal.modal {
  display: block;
}

.sidebarModal.modal .modal-body {
  padding: 70px 30px 30px 30px;
}

.sidebarModal.modal .modal-body .sidebar-content {
  margin-top: 35px;
  margin-bottom: 30px;
}

.sidebarModal.modal .modal-body .sidebar-content p {
  margin-bottom: 30px;
  color: #ffffff;
}

.sidebarModal.modal .modal-body .sidebar-content h3 {
  font-size: 22px;
  margin-bottom: 15px;
  color: #ffffff;
  font-family: "Playfair Display", serif;
}

.sidebarModal.modal .modal-body .sidebar-content .sidebar-btn {
  margin-top: 25px;
}

.sidebarModal.modal .modal-body .sidebar-contact-info {
  margin-top: 30px;
}

.sidebarModal.modal .modal-body .sidebar-contact-info h3 {
  font-size: 22px;
  margin-bottom: 0;
  font-family: "Playfair Display", serif;
  color: #ffffff;
}

.sidebarModal.modal .modal-body .sidebar-contact-info .info-list {
  padding-left: 0;
  margin-top: 30px;
  margin-bottom: 0;
}

.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li {
  list-style-type: none;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 20px;
  position: relative;
  padding-left: 22px;
}

.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li:last-child {
  margin-bottom: 0;
}

.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li a {
  color: #ffffff;
}

.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li a:hover {
  color: #9C5451;
}

.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li i {
  position: absolute;
  left: 0;
  top: 2px;
  color: #9C5451;
}

.sidebarModal.modal .modal-body .sidebar-social-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 30px;
}

.sidebarModal.modal .modal-body .sidebar-social-list li {
  display: inline-block;
  margin-right: 8px;
}

.sidebarModal.modal .modal-body .sidebar-social-list li:last-child {
  margin-right: 0;
}

.sidebarModal.modal .modal-body .sidebar-social-list li i {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 40px;
  font-size: 16px;
  background: #9C5451;
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.sidebarModal.modal .modal-body .sidebar-social-list li i:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  background-color: #19191b;
  color: #ffffff;
}

.sidebarModal.modal .modal-body .contact-form {
  margin-top: 30px;
}

.sidebarModal.modal .modal-body .contact-form h3 {
  font-size: 22px;
  margin-bottom: 25px;
  color: #ffffff;
  font-family: "Playfair Display", serif;
}

.sidebarModal.modal .modal-body .contact-form #contactForm .form-group {
  margin-bottom: 20px;
}

.sidebarModal.modal .modal-body .contact-form #contactForm .form-group .form-control {
  padding: 15px;
}

.sidebarModal.modal .modal-body .contact-form #contactForm .default-btn {
  border: none;
}

.sidebarModal.modal .modal-body .contact-form #contactForm .list-unstyled {
  color: #ee1010;
  font-size: 14px;
  margin-top: 8px;
}

.sidebarModal.modal .modal-body .contact-form #contactForm #msgSubmit {
  font-size: 15px;
}

/*Default btn
======================================================*/
.sass-default-btn {
  background-color: #0797ff;
  color: #ffffff;
  padding: 15px 30px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 7px;
  display: inline-block;
}

.sass-default-btn:hover {
  background-color: #6064e3;
  color: #ffffff;
}

.app-default-btn {
  background-color: #36234b;
  color: #ffffff;
  padding: 15px 30px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 10px;
  display: inline-block;
  text-decoration: none;
}

.app-default-btn:hover {
  background-color: #f4732a;
  color: #ffffff;
}

.app-default-btn.style2 {
  background-color: #f4732a;
  color: #ffffff;
}

.app-default-btn.style2:hover {
  background-color: #ffffff;
  color: #f4732a;
}

.app-default-btn.style3 {
  background-color: #ffffff;
  color: #f4732a;
}

.app-default-btn.style3:hover {
  background-color: #f4732a;
  color: #ffffff;
}

.app-default-btn.style3 {
  display: block;
  padding: 15px 30px;
  border: 1px solid #efedf0;
  text-align: center;
  color: #f4732a;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 10px;
}

.app-default-btn.style3:hover {
  background-color: #f4732a;
  color: #ffffff;
  border-color: #f4732a;
}

.agency-default-btn {
  background-color: #f4732a;
  color: #ffffff;
  padding: 15px 30px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 10px;
}

.agency-default-btn:hover {
  background-color: #0b1460;
  color: #ffffff;
}

.agency-default-btn i {
  position: relative;
  top: 2px;
  padding-left: 7px;
}

.agency-default-btn.style2 {
  background-color: transparent;
  border: 1px solid #0b1460;
  color: #0b1460;
}

.agency-default-btn.style2:hover {
  background-color: #f4732a;
  color: #ffffff;
  border-color: #f4732a;
}

.agency-default-btn.style3 {
  background-color: #0b1460;
  color: #ffffff;
}

.agency-default-btn.style3:hover {
  background-color: #f4732a;
  color: #ffffff;
}

.agency-default-btn.style4 {
  background-color: transparent;
  border: 1px solid #eeeeee;
  color: #f4732a;
}

.agency-default-btn.style4:hover {
  background-color: #f4732a;
  color: #ffffff;
  border-color: #f4732a;
}

.consulting-default-btn {
  background-color: #3561e4;
  color: #ffffff;
  padding: 15px 30px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 10px;
}

.consulting-default-btn:hover {
  background-color: #181c27;
  color: #ffffff;
}

.consulting-default-btn i {
  position: relative;
  top: 3px;
  padding-left: 7px;
}

.consulting-default-btn.style2 {
  background-color: #19191b;
  color: #ffffff;
}

.consulting-default-btn.style2:hover {
  color: #ffffff;
  background-color: #3561e4;
}

.photography-default-btn {
  background-color: #9C5451;
  color: #ffffff;
  padding: 15px 30px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 0;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
}

.photography-default-btn:hover {
  background-color: #19191b;
  color: #ffffff;
}

.photography-default-btn i {
  position: relative;
  top: 2px;
  padding-left: 7px;
}

.photography-default-btn.style2 {
  background-color: transparent;
  border: 1px solid #9C5451;
  color: #9C5451;
  width: 100%;
}

.photography-default-btn.style2:hover {
  background-color: #9C5451;
  color: #ffffff;
}

.political-default-btn {
  background-color: #dd131a;
  color: #ffffff;
  padding: 15px 30px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 0;
  font-weight: 500;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}

.political-default-btn:hover {
  background-color: #0c1a3c;
  color: #ffffff;
}

.political-default-btn.style2 {
  background-color: #0c1a3c;
  color: #ffffff;
}

.political-default-btn.style2:hover {
  background-color: #dd131a;
  color: #ffffff;
}

.currency-default-btn {
  background-color: #0b6dff;
  color: #ffffff;
  padding: 15px 30px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 7px;
  font-weight: 500;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}

.currency-default-btn:hover {
  background-color: #6094e1;
  color: #ffffff;
  border-color: #6094e1;
}

.currency-default-btn.style2 {
  background-color: #0e1013;
  color: #ffffff;
}

.currency-default-btn.style2:hover {
  background-color: #0b6dff;
  color: #ffffff;
}

.nft-default-btn {
  background: -webkit-gradient(linear, left top, right top, from(#06DBAC), to(#1EA5FF));
  background: linear-gradient(90deg, #06DBAC 0%, #1EA5FF 100%);
  color: #ffffff;
  padding: 15px 30px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  -o-transition: all .4s ease-in-out;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.nft-default-btn::before {
  position: absolute;
  content: '';
  background: -webkit-gradient(linear, left top, right top, from(#1EA5FF), to(#06DBAC));
  background: linear-gradient(90deg, #1EA5FF 0%, #06DBAC 100%);
  height: 100%;
  width: 0%;
  left: 0;
  top: 0;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  z-index: -1;
}

.nft-default-btn::after {
  position: absolute;
  content: '';
  background: -webkit-gradient(linear, left top, right top, from(#1EA5FF), to(#06DBAC));
  background: linear-gradient(90deg, #1EA5FF 0%, #06DBAC 100%);
  height: 0%;
  width: 100%;
  left: 0;
  top: 0;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  z-index: -1;
}

.nft-default-btn i {
  color: #ffffff;
  position: relative;
  top: 2px;
  padding-left: 7px;
}

.nft-default-btn:hover {
  color: #ffffff;
  -webkit-box-shadow: 0 4px 15px 0 rgba(6, 219, 172, 0.75);
          box-shadow: 0 4px 15px 0 rgba(6, 219, 172, 0.75);
}

.nft-default-btn:hover::before {
  width: 100%;
}

.nft-default-btn:hover::after {
  height: 100%;
}

.nft-default-btn.style2 {
  background: transparent;
  color: #ffffff;
  border: 1px solid #06dbac;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.nft-default-btn.style2:hover {
  color: #ffffff;
}

.exchange-default-btn {
  background-color: #00a9a4;
  color: #ffffff;
  padding: 15px 30px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  position: relative;
  z-index: 1;
}

.exchange-default-btn i {
  color: #ffffff;
  position: relative;
  top: 2px;
  padding-left: 7px;
}

.exchange-default-btn:hover {
  background-color: #030734;
  color: #ffffff;
}

.exchange-default-btn.style2 {
  background-color: #030734;
  color: #ffffff;
}

.exchange-default-btn.style2:hover {
  background-color: #00a9a4;
  color: #ffffff;
}

.read-more-2 {
  color: #3561e4;
}

.read-more-2 i {
  position: relative;
  padding-left: 7px;
  top: 3px;
}

/*Banner Style
======================================================*/
.sass-banner-area {
  padding-top: 200px;
  padding-bottom: 170px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.sass-banner-area.bg-1 {
  background-color: #f8f8f8;
  background-size: cover;
  background-position: center center;
}

.sass-banner-area .container-fluid {
  max-width: 1690px;
  padding-left: 30px;
  padding-right: 30px;
}

.sass-banner-area .banner-shape {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: -1;
}

.sass-banner-content {
  position: relative;
}

.sass-banner-content span {
  color: #0797ff;
  display: inline-block;
  margin-bottom: 15px;
}

.sass-banner-content h1 {
  font-size: 80px;
  margin-bottom: 20px;
}

.sass-banner-content p {
  margin-bottom: 30px;
}

.sass-banner-content .content-shape {
  position: absolute;
  right: 150px;
  top: -30px;
  -webkit-animation: movebounce2 5s linear infinite;
          animation: movebounce2 5s linear infinite;
}

.sass-banner-image {
  position: relative;
  z-index: 1;
}

.sass-banner-image .shape .banner-shape-1 {
  position: absolute;
  top: -50px;
  left: -30px;
  -webkit-animation: ripple 4s linear infinite;
          animation: ripple 4s linear infinite;
}

.sass-banner-image .shape .banner-shape-2 {
  position: absolute;
  top: 0;
  right: 300px;
  -webkit-animation: movebounce 4s linear infinite;
          animation: movebounce 4s linear infinite;
  width: 100px;
}

.sass-banner-image .shape .banner-shape-3 {
  position: absolute;
  bottom: -70px;
  right: 370px;
  -webkit-animation: movebounce 5s linear infinite;
          animation: movebounce 5s linear infinite;
  width: 100px;
}

.sass-banner-image .shape .banner-shape-4 {
  position: absolute;
  bottom: -50px;
  right: 0;
  z-index: -1;
  -webkit-animation: movebounce2 4s linear infinite;
          animation: movebounce2 4s linear infinite;
}

.sass-banner-image .shape .banner-shape-5 {
  position: absolute;
  bottom: -100px;
  left: -100px;
  -webkit-animation: movebounce2 5s linear infinite;
          animation: movebounce2 5s linear infinite;
}

.sass-banner-image .shape .banner-shape-6 {
  position: absolute;
  top: -50px;
  left: 300px;
  -webkit-animation: fadeInDown 4s linear infinite;
          animation: fadeInDown 4s linear infinite;
}

.app-banner-area {
  background-color: #36234b;
  padding-top: 100px;
  position: relative;
}

.app-banner-area .container-fluid {
  max-width: 1540px;
  padding-left: 30px;
  padding-right: 30px;
}

.app-banner-area .app-banner-shape-1 {
  position: absolute;
  bottom: 150px;
  right: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-animation: movebounce 5s linear infinite;
          animation: movebounce 5s linear infinite;
}

.app-banner-area .app-banner-shape-2 {
  position: absolute;
  top: 300px;
  right: 120px;
  -webkit-animation: rotateme 5s linear infinite;
          animation: rotateme 5s linear infinite;
}

.app-banner-area .app-banner-shape-3 {
  position: absolute;
  bottom: 150px;
  left: 60px;
  -webkit-animation: movebounce 5s linear infinite;
          animation: movebounce 5s linear infinite;
}

.app-banner-content {
  margin-bottom: 15px;
}

.app-banner-content span {
  color: #f4732a;
  margin-bottom: 15px;
  display: inline-block;
}

.app-banner-content h1 {
  font-size: 64px;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  margin-bottom: 20px;
}

.app-banner-content p {
  color: #ffffff;
  margin-bottom: 30px;
}

.app-banner-content .download-links ul {
  padding-left: 0;
  margin-bottom: 0;
}

.app-banner-content .download-links ul li {
  display: inline-block;
  margin-right: 15px;
}

.app-banner-content .download-links ul li img {
  border-radius: 12px;
}

/*Partner Style
======================================================*/
.partner-slider .partner-logo {
  opacity: .80;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.partner-slider .partner-logo:hover {
  opacity: 1;
}

/*Features Style
======================================================*/
.sass-single-features-card {
  text-align: center;
  padding: 30px;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 10px;
}

.sass-single-features-card img {
  max-width: 80px;
  margin-bottom: 15px;
}

.sass-single-features-card h3 {
  font-size: 22px;
  margin-bottom: 13px;
}

.sass-single-features-card:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.sass-features-area .container {
  position: relative;
}

.sass-features-area .container .features-shape {
  position: absolute;
  top: 50px;
  right: 22%;
  -webkit-animation: movebounce2 5s linear infinite;
          animation: movebounce2 5s linear infinite;
}

.single-app-features-box {
  position: relative;
  z-index: 1;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.single-app-features-box::before {
  content: '';
  position: absolute;
  background-color: #f5f3f6;
  width: 100%;
  height: 70%;
  z-index: -1;
  top: -20px;
  left: 0;
  border-radius: 10px;
}

.single-app-features-box .features-content {
  text-align: center;
  background-color: #ffffff;
  padding: 30px;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin: 20px 20px 30px 20px;
  position: relative;
  z-index: 2;
  border-radius: 10px;
}

.single-app-features-box .features-content i {
  color: #f4732a;
  font-size: 34px;
  margin-bottom: 18px;
  display: inline-block;
  line-height: 1;
}

.single-app-features-box .features-content h3 {
  font-family: "Roboto", sans-serif;
  margin-bottom: 13px;
  font-size: 22px;
}

.single-app-features-box:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

/*Data Analysis Style
======================================================*/
.sass-data-content .data-section-title span {
  color: #0797ff;
  display: inline-block;
  margin-bottom: 10px;
}

.sass-data-content .data-section-title h2 {
  font-size: 40px;
  margin-bottom: 20px;
}

.sass-data-content .data-section-title p {
  margin-bottom: 20px;
}

.sass-data-content .data-list ul {
  padding-left: 0;
  margin-bottom: 0;
}

.sass-data-content .data-list ul li {
  list-style-type: none;
  padding: 30px;
  margin-bottom: 15px;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding-left: 80px;
  position: relative;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 10px;
  background-color: #ffffff;
  font-weight: 500;
  color: #19191b;
}

.sass-data-content .data-list ul li .icon {
  color: #0797ff;
  height: 50px;
  width: 50px;
  line-height: 50px;
  border-radius: 100%;
  background-color: #f8f8f8;
  text-align: center;
  position: absolute;
  left: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.sass-data-content .data-list ul li .icon i {
  font-size: 20px;
  position: relative;
  top: 3px;
}

.sass-data-content .data-list ul li:last-child {
  margin-bottom: 0;
}

.sass-data-content .data-list ul li:hover {
  background-color: #0797ff;
  color: #ffffff;
}

/*Solution Style
======================================================*/
.sass-solution-single-card {
  padding: 30px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border-radius: 10px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  position: relative;
}

.sass-solution-single-card .solution-content {
  position: relative;
  padding-left: 80px;
}

.sass-solution-single-card .solution-content .icon {
  height: 60px;
  width: 60px;
  line-height: 63px;
  border-radius: 100%;
  text-align: center;
  color: #0797ff;
  font-size: 30px;
  border: 1px solid #0797ff;
  position: absolute;
  left: 0;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.sass-solution-single-card .solution-content h3 {
  font-size: 22px;
  margin-bottom: 15px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.sass-solution-single-card .solution-content p {
  font-size: 15px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.sass-solution-single-card .solution-content a {
  color: #0797ff;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  display: inline-block;
  text-decoration: none;
}

.sass-solution-single-card::before {
  position: absolute;
  content: '';
  height: 100%;
  height: 100%;
  border-radius: 10px;
  width: 0;
  top: 0;
  right: 0;
  background-color: #0797ff;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.sass-solution-single-card:hover::before {
  width: 100%;
  border-radius: 0;
  left: 0;
  right: auto;
  border-radius: 10px;
}

.sass-solution-single-card:hover .solution-content .icon {
  color: #ffffff;
  border-color: #ffffff;
}

.sass-solution-single-card:hover .solution-content h3 {
  color: #ffffff;
}

.sass-solution-single-card:hover .solution-content p {
  color: #ffffff;
}

.sass-solution-single-card:hover .solution-content a {
  color: #ffffff;
}

/*Core Statics Style
======================================================*/
.core-statics-content .statis-title span {
  color: #0797ff;
  display: inline-block;
  margin-bottom: 10px;
}

.core-statics-content .statis-title h2 {
  font-size: 40px;
  margin-bottom: 20px;
}

.core-statics-content .statis-title p {
  margin-bottom: 20px;
}

.core-statics-content .core-list ul {
  padding-left: 0;
  margin-bottom: 30px;
}

.core-statics-content .core-list ul li {
  list-style-type: none;
  margin-bottom: 20px;
  position: relative;
  padding-left: 30px;
}

.core-statics-content .core-list ul li h3 {
  font-size: 22px;
  margin-bottom: 12px;
}

.core-statics-content .core-list ul li .icon {
  color: #0797ff;
  position: absolute;
  left: 0;
  top: 3px;
}

.core-img {
  position: relative;
  z-index: 1;
}

.core-img .core-shape-1 {
  position: absolute;
  left: 30px;
  top: 150px;
  -webkit-animation: movebounce 4s linear infinite;
          animation: movebounce 4s linear infinite;
}

.core-img .core-shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-animation: movebounce2 4s linear infinite;
          animation: movebounce2 4s linear infinite;
}

/*Video Style
======================================================*/
.entro-video-area {
  position: relative;
}

.entro-video-area.video-bg {
  /*background-image: url('/images/video-img/video-bg.png');*/
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.entro-video-area .video-shape {
  position: absolute;
  right: 150px;
  bottom: 120px;
  -webkit-animation: movebounce 4s linear infinite;
          animation: movebounce 4s linear infinite;
}

.intro-video .image {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
}

.intro-video .image img {
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.intro-video .image .icon {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
}

.intro-video .image .icon .play-btn {
  font-size: 24px;
  background-color: #0797ff;
  color: #ffffff;
  text-align: center;
  height: 60px;
  width: 60px;
  line-height: 65px;
  border-radius: 100%;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.intro-video .image .icon .play-btn i {
  position: relative;
  left: 3px;
  top: 1px;
}

.intro-video .image .icon .play-btn:hover {
  background-color: #ffffff;
  color: #0797ff;
}

.intro-video .image .icon::before {
  position: absolute;
  content: '';
  height: 70px;
  width: 70px;
  border-radius: 100%;
  background-color: rgba(7, 151, 255, 0.6);
  -webkit-animation: ripple 4s linear infinite;
          animation: ripple 4s linear infinite;
  z-index: -1;
}

.intro-video .image .icon::after {
  position: absolute;
  content: '';
  height: 80px;
  width: 80px;
  border-radius: 100%;
  background-color: rgba(7, 151, 255, 0.4);
  -webkit-animation: ripple 3s linear infinite;
          animation: ripple 3s linear infinite;
  z-index: -1;
}

.intro-video .image:hover img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-filter: blur(3px);
          filter: blur(3px);
}

/*Counter Style
======================================================*/
.single-counter-box {
  margin-bottom: 30px;
}

.single-counter-box .counter-content {
  position: relative;
  padding-left: 80px;
}

.single-counter-box .counter-content .icon {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.single-counter-box .counter-content .icon i {
  font-size: 50px;
  color: #0797ff;
}

.single-counter-box .counter-content .icon::before {
  content: '';
  position: absolute;
  height: 60px;
  width: 60px;
  border-left: 20px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 35px solid #0797ff;
  border-right: 35px solid #0797ff;
  border-radius: 15px;
  opacity: .2;
  right: -10px;
  -webkit-transform: rotate(10deg);
          transform: rotate(10deg);
}

.single-counter-box .counter-content h1 {
  margin-bottom: 10px;
  font-size: 44px;
}

/*Pricing Style
======================================================*/
.single-pricing-box {
  text-align: center;
  padding: 50px 30px;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 5px;
}

.single-pricing-box h3 {
  font-size: 20px;
  margin-bottom: 22px;
}

.single-pricing-box h1 {
  font-size: 48px;
  color: #0797ff;
  margin-bottom: 5px;
}

.single-pricing-box p {
  margin-bottom: 22px;
}

.single-pricing-box .pricing-list {
  margin-bottom: 30px;
}

.single-pricing-box .pricing-list ul {
  padding-left: 0;
  margin-bottom: 0;
}

.single-pricing-box .pricing-list ul li {
  list-style-type: none;
  margin-bottom: 13px;
  color: #19191b;
}

.single-pricing-box .pricing-list ul li i {
  color: #0797ff;
  padding-right: 10px;
}

.single-pricing-box .pricing-list ul li:last-child {
  margin-bottom: 0;
}

.single-pricing-box .pricing-btn {
  padding: 15px 50px;
  border: 1px solid #0797ff;
  color: #0797ff;
  font-weight: 500;
  display: inline-block;
  border-radius: 10px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  text-decoration: none;
}

.single-pricing-box .pricing-btn:hover {
  background-color: #0797ff;
  color: #ffffff;
}

.single-pricing-box.active .pricing-btn {
  background-color: #0797ff;
  color: #ffffff;
}

.single-pricing-box .popular {
  position: absolute;
  background-color: #0797ff;
  padding: 5px 40px;
  text-align: center;
  color: #ffffff;
  top: 20px;
  right: -32px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.pricing-area .tab .react-tabs__tab-list {
  list-style-type: none;
  margin: auto auto 50px auto;
  text-align: center;
  max-width: 225px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding-left: 0;
  padding: 15px 0;
  border-radius: 10px;
}

.pricing-area .tab .react-tabs__tab-list li {
  display: inline-block;
  color: #19191b;
  padding: 10px 20px;
  background: transparent;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 5px;
  text-transform: capitalize;
  font-weight: 600;
  margin: 0 2px;
}

.pricing-area .tab .react-tabs__tab-list li:hover, .pricing-area .tab .react-tabs__tab-list li:focus {
  background: #6064e3;
  color: #ffffff;
  border-color: #6064e3;
}

.pricing-area .tab .react-tabs__tab-list li.react-tabs__tab--selected {
  background: #6064e3;
  color: #ffffff;
  border-color: #6064e3;
}

.pricing-area .container {
  position: relative;
}

.pricing-area .container .pricing-shape {
  position: absolute;
  left: 100px;
  top: 140px;
  -webkit-animation: movebounce2 5s linear infinite;
          animation: movebounce2 5s linear infinite;
}

/*Reviews Style
======================================================*/
.single-reviews-box {
  padding: 0 30px 30px 30px;
  margin-bottom: 30px;
  margin-top: 25px;
  border-radius: 10px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}

.single-reviews-box .reviews-top-content {
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #eeeeee;
}

.single-reviews-box .reviews-top-content .client-img {
  position: relative;
  top: -25px;
}

.single-reviews-box .reviews-top-content .client-img img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}

.single-reviews-box .reviews-bottom-content h3 {
  font-size: 20px;
  margin-bottom: 5px;
}

.single-reviews-box .reviews-bottom-content ul {
  padding-left: 0;
  margin-bottom: 0;
  text-align: right;
}

.single-reviews-box .reviews-bottom-content ul li {
  display: inline-block;
  padding-left: 5px;
}

.single-reviews-box .reviews-bottom-content ul li i {
  color: #ffc107;
}

.reviews-slider .swiper-pagination {
  position: initial;
  line-height: 1;
}

.reviews-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}

.reviews-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #0797ff;
}

/*Blog Style
======================================================*/
.single-blog-card {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  border-radius: 10px;
}

.single-blog-card .blog-img {
  overflow: hidden;
  position: relative;
}

.single-blog-card .blog-img img {
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.single-blog-card .blog-img span {
  position: absolute;
  background-color: #0797ff;
  color: #ffffff;
  padding: 10px 20px;
  top: 30px;
  right: 30px;
  border-radius: 10px;
  display: inline-block;
}

.single-blog-card .blog-content {
  padding: 30px;
}

.single-blog-card .blog-content .name-and-date {
  margin-bottom: 15px;
}

.single-blog-card .blog-content .name-and-date ul {
  padding-left: 0;
  margin-bottom: 0;
}

.single-blog-card .blog-content .name-and-date ul li {
  position: relative;
  padding-left: 25px;
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
}

.single-blog-card .blog-content .name-and-date ul li i {
  color: #0797ff;
  position: absolute;
  left: 0;
  top: 2px;
}

.single-blog-card .blog-content .name-and-date ul li a {
  color: #19191b;
  font-weight: 500;
  padding-left: 7px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  text-decoration: none;
}

.single-blog-card .blog-content .name-and-date ul li a:hover {
  color: #0797ff;
}

.single-blog-card .blog-content .name-and-date ul li:last-child {
  margin-right: 0;
}

.single-blog-card .blog-content h2 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 15px;
}

.single-blog-card .blog-content h2 a {
  color: #19191b;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  text-decoration: none;
}

.single-blog-card .blog-content h2 a:hover {
  color: #0797ff;
}

.single-blog-card .blog-content p {
  margin-bottom: 15px;
}

.single-blog-card .blog-content .learn-more {
  color: #0797ff;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  text-decoration: none;
}

.single-blog-card .blog-content .learn-more:hover {
  color: #6064e3;
}

.single-blog-card:hover .blog-img img {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.blog-area {
  position: relative;
}

.blog-area .shape .blog-shape-1 {
  position: absolute;
  right: 130px;
  top: 35%;
  -webkit-animation: movebounce 5s linear infinite;
          animation: movebounce 5s linear infinite;
}

/*Contact Style
======================================================*/
.contact-and-subscribe-area {
  position: relative;
  z-index: 1;
}

.contact-and-subscribe-area .contact-widget {
  background-color: #f8f8f8;
  padding: 50px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.contact-and-subscribe-area .contact-widget .contact-title {
  text-align: start;
}

.contact-and-subscribe-area .contact-widget .contact-title span {
  color: #0797ff;
  margin-bottom: 10px;
  display: inline-block;
}

.contact-and-subscribe-area .contact-widget .contact-title h3 {
  margin-bottom: 30px;
  font-size: 30px;
}

.contact-and-subscribe-area .contact-widget .contact-form .form-group {
  margin-bottom: 25px;
}

.contact-and-subscribe-area .contact-widget .contact-form .form-group label {
  margin-bottom: 7px;
}

.contact-and-subscribe-area .contact-widget .newsletter-img {
  margin-bottom: 30px;
}

.contact-and-subscribe-area .contact-widget .newsletter-form .form-control {
  margin-bottom: 25px;
}

.contact-and-subscribe-area .contact-widget .sass-default-btn {
  width: 100%;
  background-color: #0797ff;
  color: #ffffff;
  display: block;
}

.contact-and-subscribe-area .contact-widget .sass-default-btn:hover {
  background-color: #19191b;
  color: #ffffff;
}

.contact-and-subscribe-area .shape .contact-shape-1 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  -webkit-animation: movebounce 5s linear infinite;
          animation: movebounce 5s linear infinite;
}

.contact-and-subscribe-area .shape .contact-shape-2 {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 120px;
  z-index: -1;
  -webkit-animation: movebounce2 5s linear infinite;
          animation: movebounce2 5s linear infinite;
}

.list-unstyled {
  color: #ff0707;
  margin-top: 5px;
}

#msgSubmit {
  margin-top: 7px !important;
}

/*Footer Style
======================================================*/
.logo-area {
  margin-bottom: 25px;
}

.logo-area .logo-2 {
  display: none;
}

.logo-area img {
  margin-bottom: 30px;
}

.logo-area p {
  margin-bottom: 20px;
}

.logo-area .social-links ul {
  margin-bottom: 0;
  padding-left: 0;
}

.logo-area .social-links ul li {
  display: inline-block;
  margin-right: 10px;
}

.logo-area .social-links ul li:last-child {
  margin-right: 0;
}

.logo-area .social-links ul li a {
  height: 40px;
  width: 40px;
  line-height: 45px;
  background-color: #ffffff;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  display: inline-block;
  text-align: center;
  font-size: 15px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  text-decoration: none;
}

.logo-area .social-links ul li a:hover {
  background-color: #0797ff;
  color: #ffffff;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.logo-area.style2 .social-links ul li a {
  color: #555555;
  text-decoration: none;
}

.logo-area.style2 .social-links ul li a:hover {
  color: #ffffff;
  background-color: #f4732a;
}

.logo-area.style3 .social-links ul li a {
  text-decoration: none;
}

.logo-area.style3 .social-links ul li a:hover {
  background-color: #3561e4;
  color: #ffffff;
}

.logo-area.style4 p {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
}

.logo-area.style4 .social-links ul li a {
  text-decoration: none;
  color: #dd131a;
  background-color: rgba(255, 255, 255, 0.1);
}

.logo-area.style4 .social-links ul li a:hover {
  background-color: #dd131a;
  color: #ffffff;
}

.logo-area.style5 h3 {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
}

.logo-area.style5 p {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
}

.logo-area.style5 .social-links ul li a {
  text-decoration: none;
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background-color: transparent;
}

.logo-area.style5 .social-links ul li a:hover {
  background-color: #0b6dff;
  color: #ffffff;
}

.logo-area.style5 .newsletter-form {
  position: relative;
  margin-bottom: 30px;
}

.logo-area.style5 .newsletter-form .currency-default-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 55px;
  padding: 16px 30px;
  border-radius: 5px;
  border: none;
  background-color: #0b6dff;
  color: #ffffff;
}

.logo-area.style6 h3 {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
}

.logo-area.style6 p {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
}

.logo-area.style6 .social-links ul li a {
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background-color: transparent;
  text-decoration: none;
}

.logo-area.style6 .social-links ul li a:hover {
  background-color: #06dbac;
  color: #ffffff;
}

.logo-area.style6 .newsletter-form {
  position: relative;
  margin-bottom: 30px;
}

.logo-area.style6 .newsletter-form .nft-default-btn {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #06dbac;
  color: #ffffff;
}

.footer-widjet {
  margin-bottom: 30px;
}

.footer-widjet h3 {
  font-size: 22px;
  margin-bottom: 30px;
}

.footer-widjet .link-list ul {
  padding-left: 0;
  margin-bottom: 0;
}

.footer-widjet .link-list ul li {
  list-style-type: none;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
}

.footer-widjet .link-list ul li i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 14px;
}

.footer-widjet .link-list ul li a {
  color: #555555;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  text-decoration: none;
}

.footer-widjet .link-list ul li a i {
  color: #0797ff;
}

.footer-widjet .link-list ul li a:hover {
  color: #0797ff;
}

.footer-widjet.style2 .link-list ul li i {
  color: #f4732a;
}

.footer-widjet.style2 .link-list ul li a:hover {
  color: #f4732a;
}

.footer-widjet.style3 h3 {
  font-family: "Mulish", sans-serif;
}

.footer-widjet.style3 ul li i {
  color: #3561e4;
}

.footer-widjet.style3 ul li a:hover {
  color: #3561e4;
}

.footer-widjet.style4 h3 {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

.footer-widjet.style4 .link-list ul li i {
  color: #dd131a;
}

.footer-widjet.style4 .link-list ul li a {
  color: #ffffff;
}

.footer-widjet.style4 .link-list ul li a:hover {
  color: #dd131a;
}

.footer-widjet.style5 h3 {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

.footer-widjet.style5 .link-list ul li i {
  color: #0b6dff;
}

.footer-widjet.style5 .link-list ul li a {
  color: #ffffff;
}

.footer-widjet.style5 .link-list ul li a:hover {
  color: #0b6dff;
}

.footer-widjet.style5.copany {
  padding-left: 30px;
}

.footer-widjet.style6 h3 {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

.footer-widjet.style6 .link-list ul li i {
  color: #06dbac;
}

.footer-widjet.style6 .link-list ul li a {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
}

.footer-widjet.style6 .link-list ul li a:hover {
  color: #06dbac;
}

.get-in-touch {
  margin-bottom: 30px;
}

.get-in-touch h3 {
  font-size: 22px;
  margin-bottom: 30px;
  font-family: "Mulish", sans-serif;
}

.get-in-touch ul {
  padding-left: 0;
  margin-bottom: 0;
}

.get-in-touch ul li {
  margin-bottom: 15px;
  list-style-type: none;
  position: relative;
  padding-left: 25px;
}

.get-in-touch ul li a {
  color: #555555;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  text-decoration: none;
}

.get-in-touch ul li a:hover {
  color: #f4732a;
}

.get-in-touch ul li i {
  position: absolute;
  color: #f4732a;
  font-size: 16px;
  left: 0;
  top: 2px;
}

.get-in-touch ul li p {
  margin-bottom: 0;
}

.get-in-touch ul li:last-child {
  margin-bottom: 0;
}

.get-in-touch.style3 h3 {
  color: #181c27;
}

.get-in-touch.style3 ul li a:hover {
  color: #3561e4;
}

.get-in-touch.style3 ul li i {
  color: #3561e4;
}

.get-in-touch.style4 h3 {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

.get-in-touch.style4 ul li {
  color: #ffffff;
}

.get-in-touch.style4 ul li a {
  color: #ffffff;
}

.get-in-touch.style4 ul li a:hover {
  color: #0b6dff;
}

.get-in-touch.style4 ul li i {
  color: #0b6dff;
}

.get-in-touch.style4 ul li p {
  color: #ffffff;
}

.download-link h3 {
  font-size: 22px;
  margin-bottom: 30px;
}

.download-link .fotter-img a {
  display: block;
}

.download-link .fotter-img img {
  margin-bottom: 30px;
}

.agency-subscribe {
  margin-bottom: 30px;
}

.agency-subscribe h3 {
  font-size: 22px;
  margin-bottom: 30px;
  font-family: "Montserrat", sans-serif;
}

.agency-subscribe .newsletter-form {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 30px;
}

.agency-subscribe .newsletter-form .form-control {
  margin-bottom: 30px;
}

.agency-subscribe .newsletter-form .agency-default-btn {
  width: 100%;
}

.agency-subscribe.style2 h3 {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

.agency-subscribe.style2 .newsletter-form {
  border-radius: 0;
}

.agency-subscribe.style2 .newsletter-form .political-default-btn {
  width: 100%;
  background-color: #dd131a;
  color: #ffffff;
  position: relative;
}

.agency-subscribe.style2 .newsletter-form .political-default-btn:hover {
  background-color: #0c1a3c;
  color: #ffffff;
}

.agency-subscribe.style3 h3 {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

.agency-subscribe.style3 .newsletter-form {
  background: rgba(255, 255, 255, 0.06);
}

.agency-subscribe.style3 .newsletter-form .form-control {
  background-color: transparent;
  color: #ffffff;
  border-color: #eeeeee;
}

.agency-subscribe.style3 .newsletter-form .form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.agency-subscribe.style3 .newsletter-form .form-control:-ms-input-placeholder {
  color: #ffffff;
}

.agency-subscribe.style3 .newsletter-form .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.agency-subscribe.style3 .newsletter-form .form-control::placeholder {
  color: #ffffff;
}

.agency-subscribe.style3 .newsletter-form .exchange-default-btn {
  background-color: #00a9a4;
  color: #ffffff;
  width: 100%;
}

.agency-subscribe.style3 .newsletter-form .exchange-default-btn:hover {
  background-color: #030734;
  color: #ffffff;
}

.copy-right-area {
  text-align: center;
}

.copy-right-area p {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid #eeeeee;
}

.copy-right-area p a {
  color: #0797ff;
}

.copy-right-area.style3 p a {
  color: #f4732a;
}

.copy-right-area.style4 p a {
  color: #3561e4;
}

.copy-right-area.style5 {
  background-color: #19191b;
}

.copy-right-area.style5 p {
  color: #ffffff;
  border-top: 0;
}

.copy-right-area.style5 p a {
  color: #9C5451 !important;
}

.copy-right-area.style5 p a {
  color: #025de4;
}

.copy-right-area.style7 {
  background-color: #0c1a3c;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.copy-right-area.style7 p {
  color: #ffffff;
  border: none;
}

.copy-right-area.style7 p a {
  color: #dd131a;
}

.copy-right-area.style8 {
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.copy-right-area.style8 p {
  color: #ffffff;
  border: none;
}

.copy-right-area.style8 p a {
  color: #0b6dff;
}

.copy-right-area.style9 {
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.copy-right-area.style9 p {
  color: #ffffff;
  border: none;
}

.copy-right-area.style9 p a {
  color: #06dbac;
}

.agency-footer-area {
  position: relative;
  z-index: 1;
}

.agency-footer-area .footer-shape-1 {
  position: absolute;
  bottom: 0;
  left: 100px;
  z-index: -1;
}

.agency-footer-area .footer-shape-2 {
  position: absolute;
  bottom: 0;
  right: 130px;
  z-index: -1;
}

.validation-danger {
  color: #ff0707;
  padding-top: 7px;
}

/*
Go To Top Style
======================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 20px;
  right: 20px;
  background-color: #0797ff;
  z-index: 9999;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  -webkit-transition: .9s;
  transition: .9s;
}

.go-top.style2 {
  background-color: #f4732a;
}

.go-top.style3 {
  background-color: #3561e4;
}

.go-top.style5 {
  background-color: #3561e4;
}

.go-top.style5 {
  background-color: #9C5451;
}

.go-top.style7 {
  background-color: #dd131a;
}

.go-top.style8 {
  background-color: #0b6dff;
}

.go-top.style9 {
  background-color: #06dbac;
}

.go-top.style10 {
  background-color: #00a9a4;
}

.go-top i {
  color: #ffffff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 22px;
}

.go-top:hover {
  color: #ffffff;
  background: #19191b;
}

.go-top:hover i {
  color: #ffffff;
}

/*Acquaintance Style
======================================================*/
.acquaintance-content .acquaintance-title span {
  color: #f4732a;
  display: inline-block;
  margin-bottom: 10px;
}

.acquaintance-content .acquaintance-title h2 {
  font-size: 40px;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

.acquaintance-content .acquaintance-title p {
  margin-bottom: 25px;
}

.acquaintance-content .acquaintance-list {
  margin-bottom: 35px;
}

.acquaintance-content .acquaintance-list ul {
  padding-left: 0;
  margin-bottom: 0;
}

.acquaintance-content .acquaintance-list ul li {
  list-style-type: none;
  position: relative;
  padding-left: 70px;
  margin-bottom: 25px;
}

.acquaintance-content .acquaintance-list ul li .icon {
  color: #ffffff;
  background-color: #f4732a;
  text-align: center;
  height: 45px;
  width: 45px;
  line-height: 48px;
  font-size: 20px;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.acquaintance-content .acquaintance-list ul li h3 {
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
}

.acquaintance-content .acquaintance-list ul li:last-child {
  margin-bottom: 0;
}

/*Design Style
======================================================*/
.design-content .design-title span {
  color: #f4732a;
  display: inline-block;
  margin-bottom: 10px;
}

.design-content .design-title h2 {
  font-size: 40px;
  margin-bottom: 25px;
  font-family: "Roboto", sans-serif;
}

.design-content .design-text {
  margin-bottom: 30px;
}

.design-image {
  position: relative;
}

.design-image .doller {
  position: absolute;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
  right: -20px;
  top: 40%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 10px;
  -webkit-animation: movebounce 5s linear infinite;
          animation: movebounce 5s linear infinite;
}

.design-image .doller img {
  border-radius: 10px;
  margin-bottom: 15px;
}

.design-image .doller h1 {
  font-size: 28px;
}

.design-image .design-shape {
  position: absolute;
  right: 80px;
  bottom: 20px;
  -webkit-animation: movebounce2 5s linear infinite;
          animation: movebounce2 5s linear infinite;
}

/*Pricing Style
======================================================*/
.wrapper-full.hide {
  display: none;
}

.app-single-pricing-card {
  padding: 30px;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  border-radius: 10px;
  background-color: #ffffff;
}

.app-single-pricing-card .pricing-top-content {
  position: relative;
  padding-left: 85px;
  margin-bottom: 30px;
}

.app-single-pricing-card .pricing-top-content .icon {
  background-color: #efedf0;
  color: #f4732a;
  font-size: 35px;
  height: 67px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 10px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.app-single-pricing-card .pricing-top-content p {
  margin-bottom: 10px;
}

.app-single-pricing-card .pricing-top-content h2 {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
}

.app-single-pricing-card .pricing-top-content h2 span {
  font-size: 13px;
  font-weight: 500;
}

.app-single-pricing-card .pricing-list {
  margin-bottom: 30px;
}

.app-single-pricing-card .pricing-list ul {
  padding-left: 0;
  margin-bottom: 0;
}

.app-single-pricing-card .pricing-list ul li {
  list-style-type: none;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
}

.app-single-pricing-card .pricing-list ul li i {
  color: #f4732a;
  position: absolute;
  left: 0;
  top: 2px;
}

.app-single-pricing-card .pricing-list ul li:last-child {
  margin-bottom: 0;
}

.app-single-pricing-card.active .app-default-btn {
  background-color: #f4732a;
  color: #ffffff;
}

/*Reviews Style
======================================================*/
.app-reviews-section span {
  color: #f4732a;
  display: inline-block;
  margin-bottom: 10px;
}

.app-reviews-section h2 {
  font-size: 40px;
  margin-bottom: 30px;
  font-family: "Roboto", sans-serif;
}

.app-reviews-slider .swiper-pagination {
  position: initial;
  text-align: left;
}

.app-reviews-slider .swiper-pagination .swiper-pagination-bullet {
  width: 11px;
  height: 11px;
  background: #cccccc;
  opacity: 1;
}

.app-reviews-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #f4732a;
}

.app-reviews-box {
  margin-bottom: 40px;
}

.app-reviews-box .rating-star {
  margin-bottom: 15px;
}

.app-reviews-box .rating-star ul {
  padding-left: 0;
  margin-bottom: 0;
}

.app-reviews-box .rating-star ul li {
  display: inline-block;
  margin-right: 5px;
}

.app-reviews-box .rating-star ul li i {
  color: #ffc107;
}

.app-reviews-box p {
  margin-bottom: 30px;
}

.app-reviews-box .clients-profile {
  position: relative;
  padding-left: 100px;
  margin-bottom: 10px;
}

.app-reviews-box .clients-profile .clients-img {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 80px;
  height: 80px;
}

.app-reviews-box .clients-profile h3 {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  margin-bottom: 10px;
}

.app-reviews-box .clients-profile p {
  margin-bottom: 0;
}

.app-reviews-img {
  position: relative;
  padding-right: 50px;
}

.app-reviews-img .video-player {
  background-color: #f4732a;
  padding: 10px;
  padding-right: 20px;
  border-radius: 40px;
  position: absolute;
  right: 0;
  top: 50px;
}

.app-reviews-img .video-player .play-btn {
  color: #ffffff;
  position: relative;
  top: -4px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.app-reviews-img .video-player .play-btn i {
  color: #f4732a;
  background-color: #ffffff;
  height: 50px;
  width: 50px;
  line-height: 55px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  padding-left: 2px;
  position: relative;
  top: 4px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.app-reviews-img .video-player:hover .play-btn i {
  background-color: #36234b;
  color: #ffffff;
}

/*Download Style
======================================================*/
.app-download-area {
  background-color: #36234b;
  position: relative;
  z-index: 1;
}

.app-download-area::before {
  position: absolute;
  content: '';
  background-color: #ffffff;
  height: 100px;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.app-download-area .download-shape-6 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.download-image-content {
  position: relative;
}

.download-image-content .dowload-shape-1 {
  position: absolute;
  bottom: 200px;
  right: 60px;
  -webkit-animation: movebounce 5s linear infinite;
          animation: movebounce 5s linear infinite;
}

.download-image-content .dowload-shape-2 {
  position: absolute;
  bottom: 350px;
  right: 90px;
  -webkit-animation: movebounce2 6s linear infinite;
          animation: movebounce2 6s linear infinite;
}

.download-image-content .dowload-shape-3 {
  position: absolute;
  bottom: 500px;
  right: 50px;
  -webkit-animation: movebounce 7s linear infinite;
          animation: movebounce 7s linear infinite;
}

.download-image-content .dowload-shape-4 {
  position: absolute;
  bottom: 200px;
  left: -30px;
  -webkit-animation: rotateme 9s linear infinite;
          animation: rotateme 9s linear infinite;
}

.download-image-content .dowload-shape-5 {
  position: absolute;
  top: 30px;
  left: -10px;
  -webkit-animation: movebounce2 6s linear infinite;
          animation: movebounce2 6s linear infinite;
}

.download-content {
  margin-top: 30px;
}

.download-content .download-title span {
  color: #f4732a;
  display: inline-block;
  margin-bottom: 10px;
}

.download-content .download-title h2 {
  font-size: 40px;
  margin-bottom: 30px;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
}

.download-content p {
  margin-bottom: 30px;
  color: #ffffff;
}

.download-content .download-links ul {
  padding-left: 0;
  margin-bottom: 0;
}

.download-content .download-links ul li {
  display: inline-block;
  margin-right: 15px;
}

.download-content .download-links ul li img {
  border-radius: 12px;
}

/*Team Style
======================================================*/
.single-team-card {
  text-align: center;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-radius: 100% 100% 10px 10px;
  margin-bottom: 30px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.single-team-card .team-content {
  padding: 30px;
}

.single-team-card .team-content h3 {
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
}

.single-team-card .team-content h3 span {
  color: #555555;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.single-team-card .team-content .social-links ul {
  padding-left: 0;
  margin-bottom: 0;
}

.single-team-card .team-content .social-links ul li {
  display: inline-block;
  margin-right: 10px;
}

.single-team-card .team-content .social-links ul li a {
  height: 40px;
  width: 40px;
  line-height: 42px;
  border: 1px solid #eeeeee;
  color: #555555;
  display: inline-block;
  border-radius: 100%;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  text-decoration: none;
}

.single-team-card .team-content .social-links ul li a:hover {
  background-color: #f4732a;
  color: #ffffff;
  border-color: #f4732a;
  -webkit-transform: translateY(-7px);
          transform: translateY(-7px);
}

.single-team-card .team-content .social-links ul li:last-child {
  margin-right: 0;
}

.single-team-card:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.team-area .container {
  position: relative;
}

.team-area .container .team-shape {
  position: absolute;
  top: -50px;
  right: 50px;
  -webkit-animation: movebounce 5s linear infinite;
          animation: movebounce 5s linear infinite;
}

/*Watch Video Style
======================================================*/
.watch-video-area {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  text-align: center;
}

.watch-video-area::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background-color: #19191b;
  opacity: .60;
  top: 0;
  left: 0;
}

.watch-video-area .app-section-title h2 {
  color: #ffffff;
}

.watch-video-area .video-icon .icon {
  position: relative;
  z-index: 1;
}

.watch-video-area .video-icon .icon .play-btn {
  font-size: 24px;
  background-color: #f4732a;
  color: #ffffff;
  text-align: center;
  height: 60px;
  width: 60px;
  line-height: 65px;
  border-radius: 100%;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  display: inline-block;
  position: relative;
  top: 5px;
  left: 10px;
  cursor: pointer;
}

.watch-video-area .video-icon .icon .play-btn i {
  position: relative;
  left: 3px;
  top: 1px;
}

.watch-video-area .video-icon .icon .play-btn:hover {
  background-color: #ffffff;
  color: #f4732a;
}

.watch-video-area .video-icon .icon .play-btn::before {
  position: absolute;
  content: '';
  height: 70px;
  width: 70px;
  margin: auto;
  border-radius: 100%;
  background-color: rgba(245, 116, 41, 0.6);
  -webkit-animation: ripple 4s linear infinite;
          animation: ripple 4s linear infinite;
  z-index: -1;
  left: -5px;
  top: -5px;
}

/*Choose Video Style
======================================================*/
.choose-area .app-section-title {
  margin-bottom: 50px;
}

.class-for-frame {
  position: relative;
}

.class-for-frame .choose-img-frame {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-align: center;
  margin: 0 auto;
  width: auto;
  height: auto;
  top: -10px;
  z-index: 2;
}

.class-for-frame .choose-img {
  margin-bottom: 40px;
  text-align: center;
}

.choose-slider .swiper-pagination {
  position: initial;
}

.choose-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: #cccccc;
}

.choose-slider .swiper-pagination .swiper-pagination-bullet:hover, .choose-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #f4732a;
}

/*More With Us Style
======================================================*/
.more-us-content {
  /*background-image: url('../../images/shape/shape-10.png');*/
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #36234b;
  padding: 100px;
  border-radius: 20px;
}

.more-us-content .more-us-title span {
  color: #f4732a;
  display: inline-block;
  margin-bottom: 10px;
}

.more-us-content .more-us-title h2 {
  font-size: 40px;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  margin-bottom: 0;
}

.more-us-content .more-us-btn {
  text-align: right;
}

.more-us-content .more-us-btn ul {
  padding-left: 0;
  margin-bottom: 0;
}

.more-us-content .more-us-btn ul li {
  display: inline-block;
  margin-right: 15px;
}

.more-us-content .more-us-btn ul li:last-child {
  margin-right: 0;
}

/*Blog Style
======================================================*/
.app-single-blog-card {
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 30px;
}

.app-single-blog-card .blog-img {
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 10px;
}

.app-single-blog-card .blog-img img {
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 10px;
}

.app-single-blog-card .blog-content {
  padding: 15px;
  padding-top: 30px;
}

.app-single-blog-card .blog-content .date-and-comment {
  margin-bottom: 20px;
}

.app-single-blog-card .blog-content .date-and-comment ul {
  padding-left: 0;
  margin-bottom: 0;
}

.app-single-blog-card .blog-content .date-and-comment ul li {
  display: inline-block;
  position: relative;
  margin-right: 15px;
  padding-left: 20px;
}

.app-single-blog-card .blog-content .date-and-comment ul li i {
  position: absolute;
  color: #f4732a;
  left: 0;
  top: 2px;
}

.app-single-blog-card .blog-content .date-and-comment ul li:last-child {
  margin-right: 0;
}

.app-single-blog-card .blog-content h3 {
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 25px;
  line-height: 1.4;
}

.app-single-blog-card .blog-content h3 a {
  color: #19191b;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  text-decoration: none;
}

.app-single-blog-card .blog-content h3 a:hover {
  color: #f4732a;
}

.app-single-blog-card:hover .blog-img img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.app-copy-right-area .container {
  border-top: 1px solid #dad9d9;
  padding-top: 20px;
  padding-bottom: 20px;
}

.app-copy-right-area .download-links ul {
  padding-left: 0;
  margin-bottom: 0;
}

.app-copy-right-area .download-links ul li {
  display: inline-block;
  margin-right: 15px;
}

.app-copy-right-area .download-links ul li:last-child {
  margin-right: 0;
}

.app-copy-right-area .download-links ul li img {
  border-radius: 10px;
}

.app-copy-right-area p a {
  color: #f4732a;
}

/*Agency Demo Style
=================================================
================================================*/
/*Banner Style
======================================================*/
.agency-banner-area {
  padding-top: 200px;
  position: relative;
  z-index: 1;
}

.agency-banner-area .container-fluid {
  max-width: 1690px;
  padding-left: 30px;
  padding-right: 30px;
}

.agency-banner-area::before {
  position: absolute;
  content: '';
  background-color: #f9f5f4;
  height: 80px;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.agency-banner-content span {
  color: #f4732a;
  margin-bottom: 10px;
  font-family: "Karla", sans-serif;
  display: inline-block;
}

.agency-banner-content h1 {
  font-size: 72px;
  font-family: "Montserrat", sans-serif;
  color: #0b1460;
  font-weight: 800;
  margin-bottom: 20px;
}

.agency-banner-content p {
  font-family: "Karla", sans-serif;
  margin-bottom: 30px;
}

.trasted-content {
  padding: 50px;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: #ffffff;
}

.trasted-content .trasted-text h3 {
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
  color: #0b1460;
}

.trasted-slider.owl-carousel .owl-item img {
  width: auto;
  margin: auto;
}

/*Features Style
======================================================*/
.agency-single-features-card {
  text-align: center;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 35px;
  margin-bottom: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.agency-single-features-card .icon {
  height: 100px;
  width: 100px;
  line-height: 110px;
  text-align: center;
  background-color: #f9f5f4;
  border-radius: 100%;
  color: #f4732a;
  font-size: 45px;
  margin: auto;
  margin-bottom: 30px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.agency-single-features-card h3 {
  font-size: 22px;
  color: #0b1460;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 15px;
}

.agency-single-features-card p {
  font-family: "Karla", sans-serif;
}

.agency-single-features-card .btn {
  color: #f4732a;
  position: relative;
  padding-right: 25px;
}

.agency-single-features-card .btn i {
  position: absolute;
  right: 0;
  top: 8px;
}

.agency-single-features-card:hover {
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
}

.agency-single-features-card:hover .icon {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  background-color: #f4732a;
  color: #ffffff;
}

.agency-features-area .container {
  position: relative;
  z-index: 1;
}

.agency-features-area .container .features-shape-1 {
  position: absolute;
  right: 0;
  top: 20px;
  -webkit-animation: movebounce2 5s linear infinite;
          animation: movebounce2 5s linear infinite;
}

.agency-features-area .container .features-shape-2 {
  position: absolute;
  left: -30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: -1;
  -webkit-animation: movebounce 4s linear infinite;
          animation: movebounce 4s linear infinite;
}

/*About Style
======================================================*/
.agency-about-content .agency-about-title span {
  color: #f4732a;
  display: inline-block;
  margin-bottom: 10px;
  font-family: "Karla", sans-serif;
}

.agency-about-content .agency-about-title h2 {
  font-size: 40px;
  margin-bottom: 25px;
  font-family: "Montserrat", sans-serif;
  color: #0b1460;
}

.agency-about-content .agency-text {
  margin-bottom: 30px;
}

.agency-about-content .agency-text p {
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

.agency-about-image {
  position: relative;
}

.agency-about-image .about-shape-1 {
  position: absolute;
  bottom: 50px;
  right: 200px;
  -webkit-animation: movebounce2 6s linear infinite;
          animation: movebounce2 6s linear infinite;
}

.agency-about-image .about-shape-2 {
  position: absolute;
  top: 30px;
  left: 30px;
  -webkit-animation: movebounce 5s linear infinite;
          animation: movebounce 5s linear infinite;
}

.agency-about-area {
  position: relative;
}

.agency-about-area .about-shape-3 {
  position: absolute;
  bottom: 0;
  right: 0;
}

/*Services Style
======================================================*/
.agency-services-area {
  overflow: hidden;
}

.agency-services-area .container-fluid {
  overflow: hidden;
  left: calc((100% - 1320px)/2);
  position: relative;
}

.agency-single-services-card {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  overflow: hidden;
}

.agency-single-services-card .services-content {
  padding: 30px;
  padding-left: 10px;
}

.agency-single-services-card .services-content .icon {
  height: 70px;
  width: 70px;
  line-height: 75px;
  text-align: center;
  background-color: #f9f5f4;
  border-radius: 100%;
  color: #f4732a;
  font-size: 30px;
  margin-bottom: 20px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.agency-single-services-card .services-content h3 {
  font-size: 22px;
  color: #0b1460;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 15px;
}

.agency-single-services-card .services-content p {
  font-family: "Karla", sans-serif;
  margin-bottom: 15px;
}

.agency-single-services-card .services-content .read-btn {
  color: #f4732a;
  position: relative;
  padding-right: 25px;
}

.agency-single-services-card .services-content .read-btn i {
  position: absolute;
  right: 0;
  top: 2px;
}

.agency-single-services-card .services-shape-1 {
  position: absolute;
  right: -30px;
  top: -30px;
  z-index: -1;
}

.agency-single-services-card img {
  width: auto !important;
}

.services-slider .swiper-pagination {
  position: initial;
}

.services-slider .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
}

.services-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #f4732a;
}

/*Choose Style
======================================================*/
.agency-choose-area {
  overflow: hidden;
}

.agency-choose-area .container {
  position: relative;
}

.agency-choose-area .container .choose-shape-1 {
  position: absolute;
  left: -100px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-animation: movebounce 4s linear infinite;
          animation: movebounce 4s linear infinite;
}

.agency-choose-content .agency-choose-title span {
  color: #f4732a;
  display: inline-block;
  margin-bottom: 10px;
  font-family: "Karla", sans-serif;
}

.agency-choose-content .agency-choose-title h2 {
  font-size: 40px;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
  color: #0b1460;
}

.agency-choose-content .agency-choose-title p {
  margin-bottom: 25px;
  font-family: "Roboto", sans-serif;
}

.agency-choose-content .choose-list {
  margin-bottom: 25px;
}

.agency-choose-content .choose-list ul {
  padding-left: 0;
  margin-bottom: 0;
}

.agency-choose-content .choose-list ul li {
  list-style-type: none;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
}

.agency-choose-content .choose-list ul li i {
  position: absolute;
  color: #f4732a;
  font-size: 22px;
  top: 1px;
  left: 0;
}

.agency-choose-content .choose-list ul li h3 {
  font-size: 22px;
  color: #0b1460;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
}

.agency-choose-content .choose-list ul li:last-child {
  margin-bottom: 0;
}

/*Portfolio Style
======================================================*/
.portfolio-card {
  margin-bottom: 30px;
}

.portfolio-card .portfolio-img {
  position: relative;
}

.portfolio-card .portfolio-img .caption-one {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  text-align: left;
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.portfolio-card .portfolio-img .caption-one h3 {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: #0b1460;
}

.portfolio-card .portfolio-img .caption-two {
  background-color: #0b1460;
  position: absolute;
  bottom: 30px;
  left: 20px;
  right: 20px;
  border-radius: 10px;
  padding: 25px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.portfolio-card .portfolio-img .caption-two h3 {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: #0b1460;
  color: #ffffff;
  margin-bottom: 15px;
}

.portfolio-card .portfolio-img .caption-two p {
  margin-bottom: 10px;
  color: #ffffff;
}

.portfolio-card .portfolio-img .caption-two a {
  color: #ffffff;
}

.portfolio-card .portfolio-img .caption-two a i {
  position: relative;
  top: 3px;
  padding-left: 7px;
}

.portfolio-slider .swiper-pagination {
  position: initial;
}

.portfolio-slider .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
}

.portfolio-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #36234b;
}

/*Video Style
======================================================*/
.agency-intro-video-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.agency-intro-video-area .agrncy-video {
  position: relative;
  position: relative;
  overflow: hidden;
  border-radius: 18px;
}

.agency-intro-video-area .agrncy-video img {
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.agency-intro-video-area .agrncy-video .icon {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
}

.agency-intro-video-area .agrncy-video .icon .play-btn {
  font-size: 24px;
  background-color: #ffffff;
  color: #f4732a;
  text-align: center;
  height: 60px;
  width: 60px;
  line-height: 65px;
  border-radius: 100%;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.agency-intro-video-area .agrncy-video .icon .play-btn i {
  position: relative;
  left: 3px;
  top: 1px;
}

.agency-intro-video-area .agrncy-video .icon .play-btn:hover {
  background-color: #0b1460;
  color: #ffffff;
}

.agency-intro-video-area .agrncy-video .icon::before {
  position: absolute;
  content: '';
  height: 70px;
  width: 70px;
  border-radius: 100%;
  background-color: rgba(244, 115, 42, 0.6);
  -webkit-animation: ripple 4s linear infinite;
          animation: ripple 4s linear infinite;
  z-index: -1;
}

.agency-intro-video-area .agrncy-video .icon::after {
  position: absolute;
  content: '';
  height: 80px;
  width: 80px;
  border-radius: 100%;
  background-color: rgba(244, 115, 42, 0.4);
  -webkit-animation: ripple 3s linear infinite;
          animation: ripple 3s linear infinite;
  z-index: -1;
}

.agency-intro-video-area .agrncy-video:hover img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-filter: blur(3px);
          filter: blur(3px);
}

.agency-intro-video-area .video-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.agency-intro-video-area .video-shape-2 {
  position: absolute;
  top: 0;
  left: 15%;
  -webkit-animation: spin-reverse 9s linear infinite;
          animation: spin-reverse 9s linear infinite;
}

.agency-intro-video-area .video-shape-3 {
  position: absolute;
  top: 0;
  right: 15%;
  -webkit-animation: movebounce 4s linear infinite;
          animation: movebounce 4s linear infinite;
}

/*Counter Style
======================================================*/
.agency-counter-area .container {
  position: relative;
}

.agency-counter-area .container .counter-shape-1 {
  position: absolute;
  bottom: 20px;
  right: 15px;
  -webkit-animation: movebounce 4s linear infinite;
          animation: movebounce 4s linear infinite;
}

.agency-counter-box {
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 20px;
  margin-left: 30px;
  margin-right: 30px;
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  z-index: 1;
  margin-bottom: 30px;
}

.agency-counter-box .counter-content {
  padding: 20px;
  text-align: center;
  border: 8px solid #f9f5f4;
  z-index: 2;
  border-radius: 10px;
  background-color: #ffffff;
}

.agency-counter-box .counter-content h1 {
  font-size: 48px;
  font-family: "Montserrat", sans-serif;
  color: #f4732a;
  margin-bottom: 5px;
}

.agency-counter-box .counter-content p {
  font-family: "Karla", sans-serif;
}

.agency-counter-box::before {
  position: absolute;
  content: '';
  height: 100px;
  width: auto;
  left: -30px;
  right: -30px !important;
  top: 50%;
  border-radius: 10px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border: 1px solid #eeeeee;
  z-index: -1;
}

/*Testimonial Style
======================================================*/
.agency-testimonial-card {
  padding: 40px;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 10px 10px 30px 10px;
}

.agency-testimonial-card .testimonial-content .rating-star {
  margin-bottom: 15px;
}

.agency-testimonial-card .testimonial-content .rating-star ul {
  padding-left: 0;
  margin-bottom: 0;
}

.agency-testimonial-card .testimonial-content .rating-star ul li {
  display: inline-block;
  margin-right: 5px;
}

.agency-testimonial-card .testimonial-content .rating-star ul li:last-child {
  margin-left: 0;
}

.agency-testimonial-card .testimonial-content .rating-star ul li i {
  color: #ffc107;
}

.agency-testimonial-card .testimonial-content p {
  margin-bottom: 20px;
  font-family: "Karla", sans-serif;
}

.agency-testimonial-card .testimonial-content h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  color: #0b1460;
  margin-bottom: 10px;
}

.agency-testimonial-card .testimonial-content span {
  font-family: "Karla", sans-serif;
}

.agency-testimonial-card .testimonial-image img {
  width: 200px;
  border-radius: 70%;
}

.agency-testimonial-slider .swiper-button-next::after, .agency-testimonial-slider .swiper-button-prev::after {
  color: #36234b;
  font-size: 25px;
}

.agency-testimonial-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.agency-testimonial-area .testimonial-shape-1 {
  position: absolute;
  top: 100px;
  right: 23%;
  z-index: -1;
  -webkit-animation: movebounce 6s linear infinite;
          animation: movebounce 6s linear infinite;
}

.agency-testimonial-area .testimonial-shape-2 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.agency-testimonial-area .testimonial-shape-3 {
  position: absolute;
  top: 50px;
  left: 20%;
  -webkit-animation: movebounce2 6s linear infinite;
          animation: movebounce2 6s linear infinite;
}

/*Pricing Style
======================================================*/
.agency-pricing-area .container {
  position: relative;
  z-index: 1;
}

.agency-pricing-area .container .pricing-shape-1 {
  position: absolute;
  bottom: -20px;
  right: -50px;
  z-index: -1;
  -webkit-animation: movebounce 6s linear infinite;
          animation: movebounce 6s linear infinite;
}

.agency-pricing-area .container .pricing-shape-2 {
  position: absolute;
  bottom: 220px;
  right: -50px;
  z-index: -1;
  -webkit-animation: movebounce2 6s linear infinite;
          animation: movebounce2 6s linear infinite;
}

.agency-pricing-tabs .react-tabs {
  text-align: center;
}

.agency-pricing-tabs .react-tabs .react-tabs__tab-list {
  list-style-type: none;
  margin: 0 0 60px 0;
  text-align: center;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 0;
  border-radius: 10px;
  border: none;
  display: inline-block;
}

.agency-pricing-tabs .react-tabs .react-tabs__tab-list li {
  display: inline-block;
  color: #19191b;
  padding: 10px 20px;
  background: transparent;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 5px;
  text-transform: capitalize;
  font-weight: 600;
  bottom: 0;
}

.agency-pricing-tabs .react-tabs .react-tabs__tab-list li:hover, .agency-pricing-tabs .react-tabs .react-tabs__tab-list li:focus {
  background: #f4732a;
  color: #ffffff;
  border-color: #f4732a;
}

.agency-pricing-tabs .react-tabs .react-tabs__tab-list li:hover::after, .agency-pricing-tabs .react-tabs .react-tabs__tab-list li:focus::after {
  background-color: transparent;
}

.agency-pricing-tabs .react-tabs .react-tabs__tab-list li.react-tabs__tab--selected {
  background: #f4732a;
  color: #ffffff;
  border-color: #f4732a;
}

.agency-pricing-tabs .react-tabs__tab-panel {
  background-color: #ffffff;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}

.agency-pricing-box {
  padding: 40px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 10px;
  position: relative;
  text-align: left;
}

.agency-pricing-box .pricing-content h1 {
  font-size: 48px;
  color: #0b1460;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 20px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.agency-pricing-box .pricing-content h1 span {
  color: #555555;
  font-size: 16px;
  font-weight: 400;
  font-family: "Karla", sans-serif;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.agency-pricing-box .pricing-content h6 {
  font-size: 22px;
  margin-bottom: 15px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.agency-pricing-box .pricing-content p {
  font-family: "Karla", sans-serif;
  margin-bottom: 20px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.agency-pricing-box .pricing-content .pricing-list {
  margin-bottom: 30px;
}

.agency-pricing-box .pricing-content .pricing-list ul {
  padding-left: 0;
  margin-bottom: 0;
}

.agency-pricing-box .pricing-content .pricing-list ul li {
  list-style-type: none;
  position: relative;
  padding-left: 25px;
  margin-bottom: 13px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  font-family: "Karla", sans-serif;
}

.agency-pricing-box .pricing-content .pricing-list ul li i {
  color: #f4732a;
  position: absolute;
  left: 0;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.agency-pricing-box .pricing-content .pricing-list ul li i.flaticon-cancel {
  color: #555555;
  font-size: 12px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.agency-pricing-box .pricing-content .pricing-list ul li:last-child {
  margin-bottom: 0;
}

.agency-pricing-box .pricing-content .pricing-list ul li del {
  color: #8d8c8c;
}

.agency-pricing-box:hover, .agency-pricing-box.active {
  background-color: #0b1460;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.agency-pricing-box:hover .pricing-content h1, .agency-pricing-box.active .pricing-content h1 {
  color: #ffffff;
}

.agency-pricing-box:hover .pricing-content h1 span, .agency-pricing-box.active .pricing-content h1 span {
  color: #ffffff;
}

.agency-pricing-box:hover .pricing-content h6, .agency-pricing-box.active .pricing-content h6 {
  color: #ffffff;
}

.agency-pricing-box:hover .pricing-content p, .agency-pricing-box.active .pricing-content p {
  color: #ffffff;
}

.agency-pricing-box:hover .pricing-content .pricing-list ul li, .agency-pricing-box.active .pricing-content .pricing-list ul li {
  color: #ffffff;
}

.agency-pricing-box:hover .pricing-content .pricing-list ul li i, .agency-pricing-box.active .pricing-content .pricing-list ul li i {
  color: #f4732a;
}

.agency-pricing-box:hover .pricing-content .pricing-list ul li i.flaticon-cancel, .agency-pricing-box.active .pricing-content .pricing-list ul li i.flaticon-cancel {
  color: #ffffff;
}

.agency-pricing-box:hover .pricing-content .pricing-list ul li del, .agency-pricing-box.active .pricing-content .pricing-list ul li del {
  color: #ffffff;
}

.agency-pricing-box:hover .pricing-content .agency-default-btn, .agency-pricing-box.active .pricing-content .agency-default-btn {
  background-color: #f4732a;
  color: #ffffff;
  border-color: #f4732a;
}

.agency-pricing-box .popular {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #ffffff;
  color: #f4732a;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 12px;
  font-family: "Karla", sans-serif;
}

.pricing-tabs .react-tabs__tab-list {
  border-bottom: 1px solid #e3d4d4;
  margin: 0 0 30px;
  text-align: center;
}

.pricing-tabs .react-tabs__tab-list .react-tabs__tab {
  padding: 6px 20px;
  color: #000;
}

.pricing-tabs .react-tabs__tab-list .react-tabs__tab:hover {
  color: #f4732a;
}

.pricing-tabs .react-tabs__tab-list .react-tabs__tab:focus {
  outline: 0;
}

.pricing-tabs .react-tabs__tab-list .react-tabs__tab:focus::after {
  background: transparent;
}

.pricing-tabs .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
  background-color: #f4732a;
  color: #fff;
  border-color: #f4732a;
}

/*Team Style
======================================================*/
.agency-team-card {
  background-color: #f9f5f4;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 10px 30px 10px;
}

.agency-team-card .team-content {
  position: relative;
}

.agency-team-card .team-content .team-image {
  margin-right: 40px;
  margin-bottom: 40px;
}

.agency-team-card .team-content .team-caption {
  background-color: #ffffff;
  padding: 30px;
  position: absolute;
  bottom: -40px;
  right: 0;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.agency-team-card .team-content .team-caption h3 {
  font-size: 22px;
  margin-bottom: 8px;
  font-family: "Montserrat", sans-serif;
  color: #0b1460;
}

.agency-team-card .team-content .team-caption p {
  margin-bottom: 20px;
  font-family: "Karla", sans-serif;
}

.agency-team-card .team-content .team-caption .social-links ul {
  margin-bottom: 0;
  padding-left: 0;
}

.agency-team-card .team-content .team-caption .social-links ul li {
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
}

.agency-team-card .team-content .team-caption .social-links ul li a {
  height: 40px;
  width: 40px;
  line-height: 43px;
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  color: #555555;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.agency-team-card .team-content .team-caption .social-links ul li a:hover {
  background-color: #f4732a;
  color: #ffffff;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.agency-team-slider .swiper-pagination {
  position: initial;
}

.agency-team-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #cccccc;
}

.agency-team-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #f4732a;
}

.agency-team-area {
  overflow: hidden;
}

.agency-team-area .container {
  position: relative;
}

.agency-team-area .container .team-shape-1 {
  position: absolute;
  left: -80px;
  top: 35%;
  -webkit-animation: movebounce 5s linear infinite;
          animation: movebounce 5s linear infinite;
}

.agency-team-area .container .team-shape-2 {
  position: absolute;
  right: -60px;
  top: 200px;
  -webkit-animation: movebounce 6s linear infinite;
          animation: movebounce 6s linear infinite;
}

/*Join Us Style
======================================================*/
.join-us-area .container {
  background-color: #0b1460;
  position: relative;
  z-index: 1;
  border-radius: 10px;
}

.join-us-area .container .join-us-shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  -webkit-animation: movebounce 5s linear infinite;
          animation: movebounce 5s linear infinite;
}

.join-us-content {
  padding-left: 90px;
  margin-bottom: 30px;
}

.join-us-content h2 {
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  font-size: 48px;
  margin-bottom: 20px;
}

.join-us-content p {
  font-family: "Karla", sans-serif;
  color: #ffffff;
  margin-bottom: 25px;
}

.join-us-content .join-us-form .form-group .form-control {
  border-radius: 0;
}

.join-us-content .join-us-form .form-group .form-control.redius {
  border-radius: 10px 0 0 10px;
}

.join-us-content .join-us-form .form-group .agency-default-btn {
  width: 100%;
  border-radius: 0 10px 10px 0;
}

.join-us-content .join-us-form .form-group .agency-default-btn:hover {
  background-color: #555555;
}

.join-us-img {
  position: relative;
  margin-top: -50px;
}

.join-us-img .join-us-shape-1 {
  position: absolute;
  bottom: 0;
  right: -20px;
  -webkit-animation: movebounce2 5s linear infinite;
          animation: movebounce2 5s linear infinite;
}

/*Blog Style
======================================================*/
.agency-blog-card {
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  border-radius: 10px;
}

.agency-blog-card .blog-img {
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}

.agency-blog-card .blog-img img {
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 10px 10px 0 0;
}

.agency-blog-card .blog-img .date {
  position: absolute;
  left: 15px;
  top: 15px;
  background-color: #f4732a;
  color: #ffffff;
  font-family: "Karla", sans-serif;
  padding: 7px 10px;
  border-radius: 7px;
}

.agency-blog-card .blog-content {
  padding: 25px 30px 30px 30px;
}

.agency-blog-card .blog-content h3 {
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 15px;
  line-height: 1.5;
}

.agency-blog-card .blog-content h3 a {
  color: #0b1460;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.agency-blog-card .blog-content h3 a:hover {
  color: #f4732a;
}

.agency-blog-card .blog-content p {
  margin-bottom: 15px;
  font-family: "Karla", sans-serif;
}

.agency-blog-card .blog-content .list {
  padding-left: 0;
  margin-bottom: 0;
}

.agency-blog-card .blog-content .list li {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin-right: 15px;
  font-size: 15px;
}

.agency-blog-card .blog-content .list li:last-child {
  margin-right: 0;
}

.agency-blog-card .blog-content .list li a {
  color: #555555;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.agency-blog-card .blog-content .list li a:hover {
  color: #f4732a;
}

.agency-blog-card .blog-content .list li i {
  position: absolute;
  left: 0;
  top: 2px;
  color: #f4732a;
}

.agency-blog-card:hover .blog-img img {
  -webkit-transform: scale(1.2) rotate(7deg);
          transform: scale(1.2) rotate(7deg);
}

.agency-blog-area .container {
  position: relative;
}

.agency-blog-area .container .blog-shape-1 {
  position: absolute;
  right: -50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-animation: movebounce 4s linear infinite;
          animation: movebounce 4s linear infinite;
}

/*Instagram Style
======================================================*/
.single-instagram-card {
  position: relative;
}

.single-instagram-card .instagram-img {
  position: relative;
}

.single-instagram-card .instagram-img .caption {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  z-index: 2;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.single-instagram-card .instagram-img .caption .icon-and-text i {
  color: #ffffff;
  font-size: 30px;
}

.single-instagram-card .instagram-img .caption .icon-and-text h3 {
  font-size: 22px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
}

.single-instagram-card .instagram-img:hover .caption {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

/*Consulting Demo Style
=================================================
================================================*/
/*Banner Style
======================================================*/
.consulting-banner-area {
  padding-top: 100px;
  background-color: #f4f7ff;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.consulting-banner-area .container-fluid {
  max-width: 1690px;
  padding-left: 30px;
  padding-right: 30px;
}

.consulting-banner-content span {
  color: #3561e4;
  display: inline-block;
  margin-bottom: 10px;
}

.consulting-banner-content h1 {
  font-family: "Mulish", sans-serif;
  color: #181c27;
  font-size: 72px;
  margin-bottom: 20px;
  font-weight: 900;
}

.consulting-banner-content p {
  margin-bottom: 30px;
}

.consulting-banner-content .join-us-btn {
  color: #181c27;
  font-weight: 500;
}

.consulting-banner-content .join-us-btn i {
  position: relative;
  top: 2px;
  padding-right: 7px;
  color: #3561e4;
}

.consulting-banner-content .join-us-btn:hover {
  color: #6064e3;
}

.consulting-banner-image {
  position: relative;
}

.consulting-banner-image .month-stars {
  width: 290px;
  position: absolute;
  background-color: #ffffff;
  padding: 20px;
  top: 100px;
  right: 0;
  border-radius: 10px;
  -webkit-animation: movebounce 5s linear infinite;
          animation: movebounce 5s linear infinite;
}

.consulting-banner-image .month-stars .list1 {
  padding-left: 0;
  margin-bottom: 0;
  margin-bottom: 15px;
}

.consulting-banner-image .month-stars .list1 li {
  list-style-type: none;
}

.consulting-banner-image .month-stars .list2 {
  padding-left: 0;
  margin-bottom: 0;
  margin-bottom: 10px;
}

.consulting-banner-image .month-stars .list2 li {
  list-style-type: none;
  font-weight: 600;
  color: #181c27;
}

.consulting-banner-image .month-stars .progress-bar {
  background-color: #3561e4;
}

.consulting-banner-image .careear {
  position: absolute;
  bottom: 60px;
  left: 0;
}

.consulting-banner-image .careear .content {
  background-color: #ffffff;
  padding: 20px;
  width: 370px;
  position: relative;
  padding-left: 70px;
  border-radius: 10px;
}

.consulting-banner-image .careear .content .icon {
  height: 100px;
  width: 100px;
  line-height: 110px;
  background-color: #3561e4;
  text-align: center;
  border-radius: 100%;
  font-size: 40px;
  color: #ffffff;
  position: absolute;
  left: -45px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.consulting-banner-image .banner-shape-1 {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  -webkit-animation: movebounce 7s linear infinite;
          animation: movebounce 7s linear infinite;
}

.consulting-banner-image .banner-shape-2 {
  position: absolute;
  top: 60%;
  -webkit-transform: translateY(-60%);
          transform: translateY(-60%);
  right: 50px;
  -webkit-animation: movebounce2 8s linear infinite;
          animation: movebounce2 8s linear infinite;
}

/*Partner Style
======================================================*/
.consulting-partner-area {
  border-bottom: 1px solid #eeeeee;
}

.consulting-partner-slider.owl-carousel .owl-item img {
  width: auto;
  margin: auto;
}

/*Features Style
======================================================*/
.features-content {
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.consulting-features-card {
  text-align: center;
  padding: 60px 20px 30px 20px;
  position: relative;
}

.consulting-features-card .icon {
  font-size: 35px;
  color: #3561e4;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
}

.consulting-features-card .icon::before {
  position: absolute;
  content: '';
  height: 60px;
  width: 60px;
  border-radius: 100%;
  background-color: #3561e4;
  opacity: .1;
  top: -20px;
  right: 32%;
  -webkit-transform: translateX(-23%);
          transform: translateX(-23%);
  z-index: -1;
}

.consulting-features-card h3 {
  font-size: 22px;
  margin-bottom: 15px;
  font-family: "Mulish", sans-serif;
  color: #181c27;
}

.consulting-features-card p {
  margin-bottom: 20px;
}

.consulting-features-card::before {
  position: absolute;
  content: '';
  background-color: #eeeeee;
  height: 70%;
  width: 1px;
  right: -10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.col-lg-4:last-child .consulting-features-card::before {
  display: none;
}

/*About Style
======================================================*/
.consulting-about-content .consulting-about-title {
  position: relative;
  margin-bottom: 50px;
}

.consulting-about-content .consulting-about-title span {
  font-size: 16px;
  margin-bottom: 10px;
  display: inline-block;
  color: #3561e4;
  font-family: "Karla", sans-serif;
  letter-spacing: 3px;
  font-weight: 500;
}

.consulting-about-content .consulting-about-title h2 {
  font-size: 40px;
  font-family: "Mulish", sans-serif;
  color: #181c27;
}

.consulting-about-content .consulting-about-title::before {
  position: absolute;
  content: '';
  height: 3px;
  width: 100px;
  background-color: #3561e4;
  bottom: -20px;
}

.consulting-about-content .consulting-about-title::after {
  position: absolute;
  content: '';
  height: 3px;
  width: 7px;
  bottom: -20px;
  background-color: #ffffff;
  -webkit-animation: movebounce2 4s linear infinite;
          animation: movebounce2 4s linear infinite;
}

.consulting-about-content p {
  margin-bottom: 25px;
}

.consulting-about-content .about-profile {
  position: relative;
  margin-bottom: 30px;
  padding-left: 75px;
}

.consulting-about-content .about-profile img {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 60px;
  height: 60px;
}

.consulting-about-content .about-profile h3 {
  font-size: 20px;
  margin-bottom: 5px;
}

.consulting-about-content .about-profile p {
  margin-bottom: 0;
}

.consulting-about-content .download-btn {
  color: #181c27;
  font-weight: 500;
  padding-left: 20px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.consulting-about-content .download-btn i {
  position: relative;
  top: 2px;
  padding-left: 7px;
}

.consulting-about-content .download-btn:hover {
  color: #6064e3;
}

.consulting-about-image {
  position: relative;
  padding-right: 50px;
}

.consulting-about-image .customer {
  position: absolute;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
  border-radius: 10px;
  bottom: 20px;
  right: -10px;
}

.consulting-about-image .customer .customer-content {
  position: relative;
  padding-left: 75px;
}

.consulting-about-image .customer .customer-content h1 {
  font-size: 30px;
  margin-bottom: 10px;
  font-family: "Mulish", sans-serif;
  color: #181c27;
}

.consulting-about-image .customer .customer-content img {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.consulting-about-image .about-shape-3 {
  position: absolute;
  bottom: -20px;
  left: -30px;
  -webkit-animation: movebounce2 4s linear infinite;
          animation: movebounce2 4s linear infinite;
}

.consulting-about-area {
  position: relative;
  z-index: 1;
}

.consulting-about-area .about-shape-1 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.consulting-about-area .about-shape-2 {
  position: absolute;
  right: 100px;
  bottom: 100px;
  z-index: -1;
  -webkit-animation: movebounce 4s linear infinite;
          animation: movebounce 4s linear infinite;
}

/*Services Style
======================================================*/
.consulting-services-card {
  padding: 10px;
  border-radius: 10px;
  margin: 0 5px 30px 5px;
  border: 1px solid #eeeeee;
}

.consulting-services-card .services-img {
  position: relative;
}

.consulting-services-card .services-img .icon {
  font-size: 35px;
  height: 70px;
  width: 70px;
  line-height: 75px;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-radius: 100%;
  color: #3561e4;
  position: absolute;
  bottom: -30px;
  right: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.consulting-services-card .services-content {
  padding: 20px;
  padding-top: 30px;
}

.consulting-services-card .services-content h3 {
  font-size: 22px;
  font-family: "Mulish", sans-serif;
  color: #181c27;
  margin-bottom: 15px;
}

.consulting-services-card .services-content p {
  margin-bottom: 15px;
}

.consulting-services-slider .swiper-pagination {
  position: initial;
}

.consulting-services-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #cccccc;
  opacity: 1;
}

.consulting-services-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #3561e4;
}

/*Choose Style
======================================================*/
.start-consuling-choose {
  background-color: #181c27;
}

.consulting-choose-content .choose-content-title {
  position: relative;
  margin-bottom: 50px;
}

.consulting-choose-content .choose-content-title span {
  font-size: 16px;
  margin-bottom: 10px;
  display: inline-block;
  color: #3561e4;
  font-family: "Karla", sans-serif;
  letter-spacing: 3px;
  font-weight: 500;
  text-transform: uppercase;
}

.consulting-choose-content .choose-content-title h2 {
  font-size: 40px;
  font-family: "Mulish", sans-serif;
  color: #ffffff;
}

.consulting-choose-content .choose-content-title::before {
  position: absolute;
  content: '';
  height: 3px;
  width: 100px;
  background-color: #3561e4;
  bottom: -20px;
}

.consulting-choose-content .choose-content-title::after {
  position: absolute;
  content: '';
  height: 3px;
  width: 7px;
  bottom: -20px;
  background-color: #181c27;
  -webkit-animation: movebounce2 4s linear infinite;
          animation: movebounce2 4s linear infinite;
}

.consulting-choose-content p {
  color: #ffffff;
  margin-bottom: 30px;
}

.consult-choose-img {
  position: relative;
  margin-right: 60px;
}

.consult-choose-img img {
  border-radius: 10px;
}

.consult-choose-img .icon {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
}

.consult-choose-img .icon .play-btn {
  font-size: 24px;
  background-color: #3561e4;
  color: #ffffff;
  text-align: center;
  height: 60px;
  width: 60px;
  line-height: 65px;
  border-radius: 100%;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.consult-choose-img .icon .play-btn i {
  position: relative;
  left: 3px;
  top: 1px;
}

.consult-choose-img .icon .play-btn:hover {
  background-color: #ffffff;
  color: #0797ff;
}

.consult-choose-img .icon::before {
  position: absolute;
  content: '';
  height: 70px;
  width: 70px;
  border-radius: 100%;
  background-color: rgba(53, 97, 228, 0.6);
  -webkit-animation: ripple 4s linear infinite;
          animation: ripple 4s linear infinite;
  z-index: -1;
}

.consult-choose-img .icon::after {
  position: absolute;
  content: '';
  height: 80px;
  width: 80px;
  border-radius: 100%;
  background-color: rgba(53, 97, 228, 0.4);
  -webkit-animation: ripple 3s linear infinite;
          animation: ripple 3s linear infinite;
  z-index: -1;
}

.consult-choose-img .choose-shape-1 {
  position: absolute;
  bottom: 60px;
  right: -60px;
}

.consult-choose-img .choose-shape-2 {
  position: absolute;
  bottom: -20px;
  left: -20px;
  -webkit-animation: movebounce2 4s linear infinite;
          animation: movebounce2 4s linear infinite;
}

.choose-counter-card {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #000000;
  border-radius: 10px;
}

.choose-counter-card .counter-content {
  position: relative;
  padding-left: 40px;
}

.choose-counter-card .counter-content .icon {
  font-size: 70px;
  color: #3561e4;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: .2;
  line-height: 1;
}

.choose-counter-card .counter-content h1 {
  font-size: 48px;
  font-family: "Mulish", sans-serif;
  color: #ffffff;
  margin-bottom: 5px;
}

.choose-counter-card .counter-content p {
  color: #ffffff;
  margin-bottom: 0;
}

/*Project Style
======================================================*/
.consulting-project-card {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}

.consulting-project-card .project-content {
  position: absolute;
  bottom: 30px;
  text-align: center;
  top: 100%;
  left: 0;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  padding: 15px;
}

.consulting-project-card .project-content h3 {
  font-size: 22px;
  color: #ffffff;
  font-family: "Mulish", sans-serif;
}

.consulting-project-card .project-content p {
  color: #ffffff;
}

.consulting-project-card .project-content .view {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  background-color: #3561e4;
  display: inline-block;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.consulting-project-card .project-content .view:hover {
  background-color: #fff;
  border-radius: 100px;
  color: #000;
}

.consulting-project-card:hover .project-content {
  top: 0;
}

.consulting-project-slider .swiper-pagination {
  position: initial;
}

.consulting-project-slider .swiper-pagination .swiper-pagination-bullet {
  background: #cccccc;
  width: 12px;
  height: 12px;
  opacity: 1;
}

.consulting-project-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #3561e4;
}

/*Quote Style
======================================================*/
.consulting-quote-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.consulting-quote-area::before {
  position: absolute;
  content: '';
  background-color: #f9f5f4;
  height: 60%;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.consulting-quote-area .container {
  background-color: #181c27;
  z-index: 1;
  position: relative;
  border-radius: 10px;
}

.consulting-quote-area .container::before {
  position: absolute;
  content: '';
  background-color: #ffffff;
  height: 30px;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.consulting-quote-area .container::after {
  position: absolute;
  content: '';
  background-color: #f9f5f4;
  height: 30px;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.quote-content {
  padding-left: 50px;
}

.quote-content .quote-title {
  position: relative;
  margin-bottom: 50px;
}

.quote-content .quote-title span {
  font-size: 16px;
  margin-bottom: 10px;
  display: inline-block;
  color: #3561e4;
  font-family: "Karla", sans-serif;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.quote-content .quote-title h2 {
  font-size: 40px;
  font-family: "Mulish", sans-serif;
  color: #ffffff;
}

.quote-content .quote-title::before {
  position: absolute;
  content: '';
  height: 3px;
  width: 100px;
  background-color: #3561e4;
  bottom: -20px;
}

.quote-content .quote-title::after {
  position: absolute;
  content: '';
  height: 3px;
  width: 7px;
  bottom: -20px;
  background-color: #ffffff;
  -webkit-animation: movebounce2 4s linear infinite;
          animation: movebounce2 4s linear infinite;
}

.quote-img {
  -webkit-animation: movebounce2 5s linear infinite;
          animation: movebounce2 5s linear infinite;
}

/*Team Style
======================================================*/
.consulting-team-card {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 10px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.consulting-team-card img {
  border-radius: 10px;
}

.consulting-team-card .team-content {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  text-align: center;
}

.consulting-team-card .team-content h3 {
  font-size: 22px;
  color: #ffffff;
  font-family: "Mulish", sans-serif;
  margin-bottom: 10px;
}

.consulting-team-card .team-content p {
  color: #ffffff;
}

.consulting-team-card .team-content::before {
  position: absolute;
  content: '';
  height: 2px;
  width: 80px;
  background-color: #ffffff;
  bottom: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.consulting-team-card .social-links {
  position: absolute;
  right: 30px;
  top: 30px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  opacity: 0;
}

.consulting-team-card .social-links ul {
  margin-bottom: 0;
  padding-left: 0;
}

.consulting-team-card .social-links ul li {
  list-style-type: none;
  margin-bottom: 10px;
}

.consulting-team-card .social-links ul li a {
  height: 40px;
  width: 40px;
  line-height: 43px;
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  color: #3561e4;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.consulting-team-card .social-links ul li a:hover {
  background-color: #3561e4;
  color: #ffffff;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.consulting-team-card::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #181c27;
  opacity: .5;
  border-radius: 10px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.consulting-team-card:hover .team-content::before {
  background-color: #3561e4;
}

.consulting-team-card:hover .social-links {
  opacity: 1;
  top: 50px;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  right: 50px;
}

/*Testimonial Style
======================================================*/
.consulting-testimonial-area {
  background-size: cover;
  background-position: center center;
  overflow: hidden;
}

.consulting-testimonial-area .container {
  position: relative;
}

.consulting-testimonial-area .container .testimonial-shape-1 {
  position: absolute;
  top: 100px;
  left: 50px;
  -webkit-animation: movebounce2 4s linear infinite;
          animation: movebounce2 4s linear infinite;
}

.consulting-testimonial-card {
  padding: 30px;
  position: relative;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  margin-bottom: 30px;
  background-color: #ffffff;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.consulting-testimonial-card p {
  margin-bottom: 30px;
}

.consulting-testimonial-card .cliens-profile {
  position: relative;
  padding-left: 100px;
}

.consulting-testimonial-card .cliens-profile img {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 80px;
  height: 80px;
}

.consulting-testimonial-card .cliens-profile h3 {
  font-size: 20px;
  margin-bottom: 10px;
  font-family: "Mulish", sans-serif;
  color: #181c27;
}

.consulting-testimonial-card .cliens-profile p {
  margin-bottom: 0;
}

.consulting-testimonial-card .icon {
  font-size: 60px;
  color: #6064e3;
  position: absolute;
  right: 30px;
  bottom: 0;
}

.consulting-testimonial-slider {
  position: relative;
  margin-top: 50px;
}

.consulting-testimonial-slider .swiper-pagination {
  position: initial;
}

.consulting-testimonial-slider .swiper-pagination .swiper-pagination-bullet {
  background: #cccccc;
  width: 12px;
  height: 12px;
  opacity: 1;
}

.consulting-testimonial-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #3561e4;
}

/*Join Us Style
======================================================*/
.consulting-join-us-area {
  background-color: #181c27;
  position: relative;
  z-index: 1;
}

.consulting-join-us-area .join-us-shape-1 {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.consulting-join-content {
  border-left: 3px solid #ffffff;
  padding-left: 30px;
}

.consulting-join-content h2 {
  font-size: 48px;
  color: #ffffff;
  font-family: "Mulish", sans-serif;
  margin-bottom: 20px;
}

.consulting-join-content p {
  color: #ffffff;
}

.consulting-join-us-form .newsletter-form {
  position: relative;
}

.consulting-join-us-form .newsletter-form .form-control {
  border-radius: 10px;
  height: 54px;
}

.consulting-join-us-form .newsletter-form .consulting-default-btn {
  position: absolute;
  background-color: #3561e4;
  color: #ffffff;
  right: 0;
  top: -1px;
}

.consulting-join-us-form .newsletter-form .consulting-default-btn:hover {
  background-color: #19191b;
  color: #ffffff;
}

/*Blog Style
======================================================*/
.consulting-blog-card .blog-content {
  padding-top: 30px;
  padding-bottom: 30px;
}

.consulting-blog-card .blog-content .list {
  padding-left: 0;
  margin-bottom: 14px;
}

.consulting-blog-card .blog-content .list li {
  display: inline-block;
  margin-right: 15px;
  padding-left: 22px;
  position: relative;
}

.consulting-blog-card .blog-content .list li i {
  position: absolute;
  left: 0;
  top: 1px;
  color: #3561e4;
}

.consulting-blog-card .blog-content .list li a {
  color: #555555;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.consulting-blog-card .blog-content .list li a:hover {
  color: #3561e4;
}

.consulting-blog-card .blog-content h3 {
  font-size: 22px;
  font-family: "Mulish", sans-serif;
  margin-bottom: 15px;
  line-height: 1.5;
}

.consulting-blog-card .blog-content h3 a {
  color: #181c27;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.consulting-blog-card .blog-content h3 a:hover {
  color: #3561e4;
}

.consulting-blog-card .blog-img {
  border-radius: 10px;
  overflow: hidden;
}

.consulting-blog-card .blog-img img {
  border-radius: 10px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.consulting-blog-card:hover .blog-img img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

/*Photograpy Demo Style
=================================================
================================================*/
/*Banner Style
======================================================*/
.photography-banner-area {
  background-color: #191919;
}

.photography-banner-content {
  max-width: 800px;
  margin-right: 0;
  margin-left: auto;
  padding-top: 40px;
  padding-left: 30px;
}

.photography-banner-content span {
  color: #ffffff;
  display: inline-block;
  margin-bottom: 10px;
  letter-spacing: 3px;
  font-family: "Mukta", sans-serif;
}

.photography-banner-content h1 {
  font-size: 86px;
  color: #ffffff;
  font-family: "Playfair Display", serif;
  margin-bottom: 20px;
}

.photography-banner-content p {
  color: #ffffff;
  margin-bottom: 25px;
  font-family: "Mukta", sans-serif;
  padding-right: 30px;
}

.photography-banner-content a {
  color: #ffffff;
  letter-spacing: 3px;
}

.photography-banner-content a i {
  position: relative;
  top: 3px;
  padding-left: 7px;
}

.photography-banner-img .swiper-button-next::after, .photography-banner-img .swiper-button-prev::after {
  font-size: 30px;
  color: #fff;
}

/*Services Style
======================================================*/
.photography-services-list > div:nth-child(1), .photography-services-list > div:nth-child(3), .photography-services-list > div:nth-child(5), .photography-services-list > div:nth-child(7), .photography-services-list > div:nth-child(9), .photography-services-list > div:nth-child(11), .photography-services-list > div:nth-child(13), .photography-services-list > div:nth-child(15), .photography-services-list > div:nth-child(17), .photography-services-list > div:nth-child(19), .photography-services-list > div:nth-child(21), .photography-services-list > div:nth-child(23), .photography-services-list > div:nth-child(25), .photography-services-list > div:nth-child(27), .photography-services-list > div:nth-child(29), .photography-services-list > div:nth-child(31), .photography-services-list > div:nth-child(33), .photography-services-list > div:nth-child(35), .photography-services-list > div:nth-child(37), .photography-services-list > div:nth-child(39) {
  margin-top: 30px;
}

.photograpy-services-card.mt-30 {
  margin-top: 30px;
}

.photograpy-services-card .services-content {
  padding-top: 30px;
  padding-bottom: 30px;
}

.photograpy-services-card .services-content span {
  letter-spacing: 3px;
  color: #9C5451;
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-block;
  font-family: "Mukta", sans-serif;
}

.photograpy-services-card .services-content h2 {
  font-size: 22px;
  font-family: "Playfair Display", serif;
  font-weight: 600;
  margin-bottom: 20px;
}

.photograpy-services-card .services-content .icon-btn {
  font-size: 20px;
  color: #9C5451;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.photography-services-area {
  position: relative;
  z-index: 1;
}

.photography-services-area .services-shape-1 {
  position: absolute;
  left: 100px;
  z-index: -1;
  bottom: 250px;
  -webkit-animation: movebounce 4s linear infinite;
          animation: movebounce 4s linear infinite;
}

/*About Style
======================================================*/
.photograpy-about-content {
  position: relative;
}

.photograpy-about-content .photography-about-title span {
  color: #9C5451;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 10px;
}

.photograpy-about-content .photography-about-title h2 {
  font-size: 40px;
  margin-bottom: 25px;
  font-family: "Playfair Display", serif;
}

.photograpy-about-content .about-text {
  margin-bottom: 30px;
}

.photograpy-about-content .about-text p {
  font-family: "Mukta", sans-serif;
  margin-bottom: 20px;
}

.photography-about-area .container {
  position: relative;
  z-index: 1;
}

.photography-about-area .container .about-shape-1 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  -webkit-animation: movebounce2 4s linear infinite;
          animation: movebounce2 4s linear infinite;
}

/*Portfolio Style
======================================================*/
.photography-portfolio-area {
  position: relative;
  z-index: 1;
}

.photography-portfolio-area .portfolio-shape-1 {
  position: absolute;
  right: 100px;
  bottom: 300px;
  -webkit-animation: movebounce 4s linear infinite;
          animation: movebounce 4s linear infinite;
  z-index: -1;
}

.photography-portfolio-area.style2 .photography-section-title h2 {
  font-family: "Poppins", sans-serif;
}

.photography-portfolio-area.style2 .photography-section-title span {
  font-family: "Roboto", sans-serif;
}

.photography-portfolio-area.style2 .photography-portfolio-card .portfolio-content h3 {
  font-family: "Poppins", sans-serif;
}

.photography-portfolio-area.style2 .photography-portfolio-card .portfolio-content p {
  font-family: "Roboto", sans-serif;
}

.photography-portfolio-card {
  margin-bottom: 30px;
}

.photography-portfolio-card .portfolio-img {
  position: relative;
}

.photography-portfolio-card .portfolio-img .icon {
  position: absolute;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.photography-portfolio-card .portfolio-img .icon i {
  color: #ffffff;
  font-size: 30px;
}

.photography-portfolio-card .portfolio-img::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  background-color: #000000;
  opacity: .6;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.photography-portfolio-card .portfolio-img:hover .icon {
  opacity: 1;
}

.photography-portfolio-card .portfolio-img:hover::before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.photography-portfolio-card .portfolio-content {
  padding-top: 20px;
}

.photography-portfolio-card .portfolio-content h3 {
  font-size: 20px;
  margin-bottom: 15px;
  font-family: "Playfair Display", serif;
}

.photography-portfolio-card .portfolio-content p {
  font-family: "Mukta", sans-serif;
}

.mfp-title {
  display: none;
}

/*Watch Video Style
======================================================*/
.photography-video-area {
  position: relative;
  z-index: 1;
}

.photography-video-area .photography-bg {
  background-size: cover;
  background-position: center center;
  width: 100%;
}

.photography-video-area::before {
  position: absolute;
  content: '';
  background-color: #f9f9f9;
  width: 100%;
  height: 50%;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.photograpy-video-content {
  position: relative;
  padding-top: 200px;
}

.photograpy-video-content .video-text {
  padding: 30px;
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}

.photograpy-video-content .video-text h2 {
  font-size: 40px;
  font-family: "Playfair Display", serif;
  margin-bottom: 30px;
}

.video-icon-content {
  position: relative;
}

.video-icon-content .icon {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
}

.video-icon-content .icon .play-btn {
  font-size: 24px;
  background-color: #9C5451;
  color: #ffffff;
  text-align: center;
  height: 60px;
  width: 60px;
  line-height: 65px;
  border-radius: 100%;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.video-icon-content .icon .play-btn i {
  position: relative;
  left: 3px;
  top: 1px;
}

.video-icon-content .icon .play-btn:hover {
  background-color: #ffffff;
  color: #9C5451;
}

.video-icon-content .icon::before {
  position: absolute;
  content: '';
  height: 70px;
  width: 70px;
  border-radius: 100%;
  background-color: rgba(156, 84, 81, 0.5);
  -webkit-animation: ripple 4s linear infinite;
          animation: ripple 4s linear infinite;
  z-index: -1;
}

.video-icon-content .icon::after {
  position: absolute;
  content: '';
  height: 80px;
  width: 80px;
  border-radius: 100%;
  background-color: rgba(156, 84, 81, 0.3);
  -webkit-animation: ripple 3s linear infinite;
          animation: ripple 3s linear infinite;
  z-index: -1;
}

/*Testimonial Style
======================================================*/
.photography-testimonial-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.photography-testimonial-area .testimonial-shape-1 {
  position: absolute;
  left: 120px;
  top: 100px;
  z-index: -1;
  -webkit-animation: movebounce 4s linear infinite;
          animation: movebounce 4s linear infinite;
}

.photograpy-testimonial-card {
  padding: 40px;
  background-color: #ffffff;
  margin-bottom: 30px;
}

.photograpy-testimonial-card .rating {
  padding-left: 0;
  margin-bottom: 0;
  margin-bottom: 15px;
}

.photograpy-testimonial-card .rating li {
  display: inline-block;
  margin-right: 5px;
}

.photograpy-testimonial-card .rating li:last-child {
  margin-right: 0;
}

.photograpy-testimonial-card .rating li i {
  color: #ffc107;
}

.photograpy-testimonial-card h3 {
  font-size: 22px;
  font-family: "Playfair Display", serif;
  margin-bottom: 20px;
}

.photograpy-testimonial-card p {
  font-family: "Mukta", sans-serif;
  margin-bottom: 30px;
}

.photograpy-testimonial-card .clients-profile {
  position: relative;
  padding-left: 100px;
  margin-bottom: 10px;
}

.photograpy-testimonial-card .clients-profile img {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: auto;
}

.photograpy-testimonial-card .clients-profile h3 {
  font-size: 20px;
  font-family: "Playfair Display", serif;
  margin-bottom: 10px;
}

.photograpy-testimonial-card .clients-profile p {
  margin-bottom: 0;
}

.photograpy-testimonial-slider .swiper-pagination {
  position: initial;
}

.photograpy-testimonial-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}

.photograpy-testimonial-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #9C5451;
}

/*Team Style
======================================================*/
.photography-team-card {
  position: relative;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.photography-team-card .team-caption1 {
  text-align: center;
  padding: 30px;
  background-color: #ffffff;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.photography-team-card .team-caption1 h3 {
  font-size: 22px;
  margin-bottom: 15px;
  font-family: "Playfair Display", serif;
}

.photography-team-card .team-caption2 {
  text-align: center;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  padding: 30px;
  background-color: #ffffff;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.photography-team-card .team-caption2 h3 {
  font-size: 22px;
  margin-bottom: 15px;
  font-family: "Playfair Display", serif;
}

.photography-team-card .team-caption2 .social-links {
  -webkit-transition: all ease 0.5s !important;
  transition: all ease 0.5s !important;
}

.photography-team-card .team-caption2 .social-links ul {
  margin-bottom: 0;
  padding-left: 0;
}

.photography-team-card .team-caption2 .social-links ul li {
  display: inline-block;
  margin: 0 3px;
}

.photography-team-card .team-caption2 .social-links ul li a {
  height: 45px;
  width: 45px;
  line-height: 48px;
  background-color: #ffffff;
  color: #555555;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  display: inline-block;
  text-align: center;
  font-size: 18px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.photography-team-card .team-caption2 .social-links ul li a:hover {
  background-color: #9C5451;
  color: #ffffff;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.photography-team-card:hover .team-caption1 {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}

.photography-team-card:hover .team-caption2 {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.photography-team-area {
  position: relative;
  z-index: 1;
}

.photography-team-area .team-shape-1 {
  position: absolute;
  right: 120px;
  bottom: 250px;
  z-index: -1;
  -webkit-animation: movebounce 4s linear infinite;
          animation: movebounce 4s linear infinite;
}

/*Pricing Style
======================================================*/
.photography-pricing-area {
  position: relative;
  z-index: 1;
}

.photography-pricing-area .pricing-shape-1 {
  position: absolute;
  left: 120px;
  bottom: 250px;
  z-index: -1;
  -webkit-animation: movebounce 4s linear infinite;
          animation: movebounce 4s linear infinite;
}

.photography-pricing-card {
  padding: 40px;
  background-color: #ffffff;
}

.photography-pricing-card span {
  color: #9C5451;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 15px;
  display: inline-block;
}

.photography-pricing-card h1 {
  font-size: 48px;
  font-family: "Playfair Display", serif;
  margin-bottom: 30px;
}

.photography-pricing-card .pricing-list {
  padding-left: 0;
  margin-bottom: 35px;
}

.photography-pricing-card .pricing-list li {
  position: relative;
  list-style-type: none;
  margin-bottom: 15px;
}

.photography-pricing-card .pricing-list li:last-child {
  margin-bottom: 0;
}

.photography-pricing-card .pricing-list li i {
  position: absolute;
  right: 0;
  font-size: 13px;
  top: 2px;
  color: #9C5451;
}

.photography-pricing-card.active {
  margin-top: 30px;
}

.photography-pricing-card.active .photography-default-btn {
  background-color: #9C5451;
  color: #ffffff;
}

/*Contact Style
======================================================*/
.photography-contact-area {
  position: relative;
}

.photography-contact-area .contact-shape-1 {
  position: absolute;
  bottom: 300px;
  right: 120px;
  -webkit-animation: movebounce 4s linear infinite;
          animation: movebounce 4s linear infinite;
}

.photography-contact-form h3 {
  font-size: 22px;
  font-family: "Playfair Display", serif;
  margin-bottom: 30px;
}

.photography-contact-form .form-group {
  margin-bottom: 25px;
}

.photography-contact-form .form-group .form-control {
  color: #555555;
}

.photography-contact-form .form-group .form-control:focus {
  border: 1px solid #9C5451;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.photography-contact-form .form-group .form-control::-webkit-input-placeholder {
  color: #555555;
}

.photography-contact-form .form-group .form-control:-ms-input-placeholder {
  color: #555555;
}

.photography-contact-form .form-group .form-control::-ms-input-placeholder {
  color: #555555;
}

.photography-contact-form .form-group .form-control::placeholder {
  color: #555555;
}

/*Blog Style
======================================================*/
.photography-blog-card {
  margin-bottom: 30px;
}

.photography-blog-card .blog-content {
  padding-top: 25px;
}

.photography-blog-card .blog-content span {
  color: #9C5451;
  font-size: 16px;
  letter-spacing: 3px;
  display: inline-block;
  margin-bottom: 15px;
}

.photography-blog-card .blog-content h2 {
  font-size: 22px;
  font-family: "Playfair Display", serif;
  margin-bottom: 20px;
}

.photography-blog-card .blog-content h2 a {
  color: #19191b;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.photography-blog-card .blog-content h2 a:hover {
  color: #9C5451;
}

.photography-blog-card .blog-content a {
  color: #9C5451;
  font-size: 20px;
}

/*Fotter Style
======================================================*/
.photography-fotter-area {
  background-color: #19191b;
  border-bottom: 1px solid #575757;
  padding-top: 20px;
  padding-bottom: 20px;
}

.photography-fotter-area .photography-logo {
  text-align: left;
}

.photography-fotter-area .photography-quick-links {
  text-align: center;
}

.photography-fotter-area .photography-quick-links ul {
  padding-left: 0;
  margin-bottom: 0;
}

.photography-fotter-area .photography-quick-links ul li {
  display: inline-block;
  margin-right: 15px;
}

.photography-fotter-area .photography-quick-links ul li:last-child {
  margin-right: 0;
}

.photography-fotter-area .photography-quick-links ul li a {
  color: #ffffff;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.photography-fotter-area .photography-quick-links ul li a:hover {
  color: #9C5451;
}

.photography-fotter-area .photograpy-social-links {
  text-align: right;
}

.photography-fotter-area .photograpy-social-links .social-links {
  -webkit-transition: all ease 0.5s !important;
  transition: all ease 0.5s !important;
}

.photography-fotter-area .photograpy-social-links .social-links ul {
  margin-bottom: 0;
  padding-left: 0;
}

.photography-fotter-area .photograpy-social-links .social-links ul li {
  display: inline-block;
  margin-right: 10px;
}

.photography-fotter-area .photograpy-social-links .social-links ul li:last-child {
  margin-right: 0;
}

.photography-fotter-area .photograpy-social-links .social-links ul li a {
  height: 45px;
  width: 45px;
  line-height: 48px;
  border: 1px solid #575757;
  color: #ffffff;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  display: inline-block;
  text-align: center;
  font-size: 18px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.photography-fotter-area .photograpy-social-links .social-links ul li a:hover {
  background-color: #9C5451;
  color: #ffffff;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  border-color: #9C5451;
}

/*Coming Soon Style
======================================================*/
.coming-soon-area {
  padding-top: 200px;
  position: relative;
  z-index: 1;
}

.coming-soon-area::before {
  position: absolute;
  content: '';
  height: 420px;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #f7f7f7;
  z-index: -1;
}

.coming-soon-area .container {
  position: relative;
}

.coming-soon-area .container .coming-soon-shape-1 {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 3;
}

.coming-soon-area .container .coming-soon-shape-2 {
  position: absolute;
  right: 0;
  top: 45%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 3;
}

.coming-soon-area .container .coming-soon-shape-3 {
  position: absolute;
  left: 0;
  top: 50px;
  -webkit-animation: movebounce 4s linear infinite;
          animation: movebounce 4s linear infinite;
}

.coming-soon-area .container .coming-soon-shape-4 {
  position: absolute;
  left: 0;
  top: 30%;
  -webkit-animation: movebounce 5s linear infinite;
          animation: movebounce 5s linear infinite;
}

.coming-soon-area .container .coming-soon-shape-5 {
  position: absolute;
  right: 0;
  top: 18%;
  -webkit-animation: movebounce 5s linear infinite;
          animation: movebounce 5s linear infinite;
}

.coming-soon-counter {
  max-width: 880px;
  margin: auto;
  border: 30px solid #ffffff;
  background-color: #f7f7f7;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding-top: 100px;
  padding-bottom: 80px;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  margin-bottom: 30px;
}

.coming-soon-counter::before {
  position: absolute;
  content: '';
  height: 100%;
  width: auto;
  left: -60px !important;
  right: 0;
  bottom: -60px;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  z-index: -1;
}

.coming-soon-counter .list ul {
  margin-bottom: 0;
  padding-left: 0;
}

.coming-soon-counter .list ul li {
  margin: 0 30px;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #181c27;
  position: relative;
}

.coming-soon-counter .list ul li span {
  font-size: 15px;
  font-weight: 500;
  color: #555555;
}

.coming-soon-counter .list ul li::before {
  position: absolute;
  content: '';
  height: 6px;
  width: 6px;
  background-color: #555555;
  border-radius: 100%;
  right: -30px;
  top: 42px;
}

.coming-soon-counter .list ul li::after {
  position: absolute;
  content: '';
  height: 6px;
  width: 6px;
  background-color: #555555;
  border-radius: 100%;
  right: -30px;
  top: 55px;
}

.coming-soon-counter .list ul li:last-child::before {
  display: none;
}

.coming-soon-counter .list ul li:last-child::after {
  display: none;
}

.coming-soon-counter .shape-1 {
  position: absolute;
  top: -40px;
  right: 0;
  -webkit-animation: movebounce 5s linear infinite;
          animation: movebounce 5s linear infinite;
}

.coming-soon-counter .shape-2 {
  position: absolute;
  top: 30px;
  left: 30px;
  -webkit-animation: movebounce2 5s linear infinite;
          animation: movebounce2 5s linear infinite;
}

.coming-soon-counter .shape-3 {
  position: absolute;
  top: -80px;
  left: 300px;
  -webkit-animation: rotateme 9s linear infinite;
          animation: rotateme 9s linear infinite;
}

.coming-soon-content {
  max-width: 880px;
  margin: auto;
  text-align: center;
}

.coming-soon-content .top-content {
  max-width: 620px;
  margin: auto;
}

.coming-soon-content .top-content span {
  color: #ffffff;
  background-color: #dc3545;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 20px;
  font-family: "Playfair Display", serif;
  font-weight: 400;
  margin-bottom: 20px;
}

.coming-soon-content .top-content h1 {
  font-size: 60px;
  margin-bottom: 30px;
  font-family: "Playfair Display", serif;
  font-weight: 600;
}

.coming-soon-content .newsletter-form {
  position: relative;
  margin-bottom: 30px;
  z-index: 4;
}

.coming-soon-content .newsletter-form .form-control {
  height: 80px;
}

.coming-soon-content .newsletter-form .subscribe-btn {
  background-color: #025de4;
  color: #ffffff;
  padding: 15px 30px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border: none;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
  position: absolute;
  right: 13px;
  top: 13px;
}

.coming-soon-content .newsletter-form .subscribe-btn:hover {
  background-color: #19191b;
  color: #ffffff;
}

.coming-soon-content .newsletter-form .subscribe-btn i {
  position: relative;
  top: 2px;
  padding-left: 7px;
}

.coming-soon-content .social-links ul {
  margin-bottom: 0;
  padding-left: 0;
}

.coming-soon-content .social-links ul li {
  display: inline-block;
  margin-right: 10px;
}

.coming-soon-content .social-links ul li:last-child {
  margin-right: 0;
}

.coming-soon-content .social-links ul li a {
  height: 45px;
  width: 45px;
  line-height: 48px;
  background-color: #ffffff;
  border-radius: 100%;
  color: #555555;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #eeeeee;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.coming-soon-content .social-links ul li a:hover {
  background-color: #025de4;
  color: #ffffff;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

/*Political Demo Style
=================================================
================================================*/
/*Header Style
======================================================*/
.plitical-header-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.plitical-header-area .navbar {
  position: unset;
}

.plitical-header-area .navbar-light.nav-style7.is-sticky {
  background-color: #ffffff !important;
  position: fixed !important;
}

.plitical-header-area .navbar-light.nav-style7.is-sticky .container-fluid {
  -webkit-box-shadow: unset;
          box-shadow: unset;
}

.political-top-header-area {
  padding-top: 10px;
  padding-bottom: 10px;
}

.political-top-header-area .container-fluid {
  max-width: 1690px;
  padding-left: 30px;
  padding-right: 30px;
}

.political-top-header-area .political-header-left-content ul {
  padding-left: 0;
  margin-bottom: 0;
}

.political-top-header-area .political-header-left-content ul li {
  display: inline-block;
  margin-right: 20px;
}

.political-top-header-area .political-header-left-content ul li:last-child {
  margin-right: 0;
}

.political-top-header-area .political-header-left-content ul li a {
  color: #555555;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.political-top-header-area .political-header-left-content ul li a:hover {
  color: #dd131a;
}

.political-top-header-area .political-header-left-content ul li i {
  position: relative;
  top: 1px;
  padding-right: 10px;
  color: #dd131a;
}

.political-top-header-area .political-header-right-content {
  text-align: right;
}

.political-top-header-area .political-header-right-content .social-links ul {
  margin-bottom: 0;
  padding-left: 0;
}

.political-top-header-area .political-header-right-content .social-links ul li {
  display: inline-block;
  margin-right: 10px;
}

.political-top-header-area .political-header-right-content .social-links ul li:last-child {
  margin-right: 0;
}

.political-top-header-area .political-header-right-content .social-links ul li a {
  height: 40px;
  width: 40px;
  line-height: 43px;
  background-color: #ffffff;
  border-radius: 100%;
  color: #555555;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #eeeeee;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.political-top-header-area .political-header-right-content .social-links ul li a:hover {
  background-color: #dd131a;
  color: #ffffff;
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

/*Banner Style
======================================================*/
.political-banner-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 200px;
}

.political-banner-area .container-fluid {
  max-width: 1690px;
  padding-left: 30px;
  padding-right: 30px;
}

.political-banner-content {
  margin-bottom: 40px;
}

.political-banner-content span {
  display: inline-block;
  margin-bottom: 15px;
  position: relative;
  padding-left: 20px;
}

.political-banner-content span::before {
  position: absolute;
  content: '';
  left: 0;
  height: 7px;
  width: 7px;
  top: 8px;
  border-radius: 100%;
  background-color: #dd131a;
}

.political-banner-content h1 {
  font-size: 85px;
  font-family: "Poppins", sans-serif;
  color: #0c1a3c;
  margin-bottom: 15px;
}

.political-banner-content p {
  font-family: "Roboto", sans-serif;
  margin-bottom: 30px;
}

.political-banner-content .banner-btn ul {
  padding-left: 0;
  margin-bottom: 0;
}

.political-banner-content .banner-btn ul li {
  display: inline-block;
  margin-right: 30px;
}

.political-banner-content .banner-btn ul li:last-child {
  margin-right: 0;
}

.political-banner-content .banner-btn ul li .play-btn {
  position: relative;
  z-index: 1;
  top: 3px;
  cursor: pointer;
}

.political-banner-content .banner-btn ul li .play-btn i {
  position: relative;
  color: #ffffff;
  background-color: #0c1a3c;
  height: 50px;
  width: 50px;
  line-height: 50px;
  padding-top: 2px;
  padding-left: 1px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.political-banner-content .banner-btn ul li .play-btn::before {
  position: absolute;
  content: '';
  height: 55px;
  width: 55px;
  border-radius: 100%;
  background-color: rgba(12, 26, 60, 0.4);
  z-index: -1;
  top: -3px;
  left: -3px;
  -webkit-animation: ripple 4s linear infinite;
          animation: ripple 4s linear infinite;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.political-banner-content .banner-btn ul li .play-btn:hover i {
  background-color: #dd131a;
  color: #ffffff;
}

.political-banner-content .banner-btn ul li .play-btn:hover::before {
  background-color: rgba(221, 19, 26, 0.4);
}

/*Movement Style
======================================================*/
.political-movement-card {
  margin-bottom: 30px;
}

.political-movement-card .movement-content {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
}

.political-movement-card .movement-content h1 {
  font-size: 42px;
  font-family: "Poppins", sans-serif;
  color: #0c1a3c;
  margin-bottom: 5px;
}

.political-movement-card .movement-content p {
  font-family: "Roboto", sans-serif;
}

/*About Style
======================================================*/
.political-about-content .political-about-title span {
  color: #dd131a;
  display: inline-block;
  margin-bottom: 15px;
  font-family: "Roboto", sans-serif;
}

.political-about-content .political-about-title h2 {
  font-size: 40px;
  margin-bottom: 25px;
  font-family: "Poppins", sans-serif;
  color: #0c1a3c;
}

.political-about-content .text-content {
  margin-bottom: 30px;
}

.political-about-content .text-content p {
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
}

.political-about-image {
  position: relative;
  padding-left: 70px;
}

.political-about-image .description-box {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 350px;
  padding: 30px;
}

.political-about-image .description-box h4 {
  font-size: 16px;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  color: #0c1a3c;
}

.political-about-image .description-box p {
  font-size: 15px;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

.political-about-image .description-box h3 {
  font-size: 18px;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
  color: #0c1a3c;
}

.political-about-image .description-box span {
  font-size: 15px;
}

/*Project Style
======================================================*/
.political-project-card {
  position: relative;
  margin-bottom: 40px;
}

.political-project-card .project-content {
  padding: 30px;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  bottom: 0;
  padding-right: 50px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.political-project-card .project-content span {
  color: #dd131a;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  margin-bottom: 10px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.political-project-card .project-content h3 {
  font-size: 22px;
  color: #0c1a3c;
  font-family: "Poppins", sans-serif;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.political-project-card .project-content .link-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.political-project-card .project-content .link-icon i {
  font-weight: 700;
  height: 45px;
  width: 45px;
  line-height: 48px;
  text-align: center;
  background-color: #eef1f6;
  display: inline-block;
  color: #555555;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.political-project-card:hover .project-content {
  background-color: #dd131a;
}

.political-project-card:hover .project-content span {
  color: #ffffff;
}

.political-project-card:hover .project-content h3 {
  color: #ffffff;
}

.political-project-card:hover .project-content .link-icon i {
  background-color: #0c1a3c;
  color: #ffffff;
}

.political-project-slider .swiper-pagination {
  position: initial;
}

.political-project-slider .swiper-pagination .swiper-pagination-bullet {
  background-color: #ccc;
  width: 12px;
  height: 12px;
  opacity: 1;
}

.political-project-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #dd131a;
}

.btn:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

/*Video Style
======================================================*/
.political-donation-and-video-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  background-attachment: fixed;
  z-index: 1;
}

.political-donation-and-video-area::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #0c1a3c;
  opacity: .7;
  z-index: -1;
}

.political-donation-and-video-area::after {
  position: absolute;
  content: '';
  height: 150px;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #ffffff;
  z-index: -1;
}

.select-and-donate ul {
  padding-left: 0;
  margin-bottom: 0;
}

.select-and-donate ul li {
  display: inline-block;
  margin-right: 20px;
}

.select-and-donate ul li:last-child {
  margin-right: 0;
}

.select-and-donate ul li .btn-check:active + .btn, .select-and-donate ul li .btn-check:checked + .btn, .select-and-donate ul li .btn.active, .select-and-donate ul li .btn.show, .select-and-donate ul li .btn:active {
  color: #dd131a;
  background-color: #ffffff;
  border-color: #ffffff;
}

.select-and-donate ul li .btn-check:focus + .btn, .select-and-donate ul li .btn:focus {
  color: #dd131a;
  background-color: #ffffff;
  border-color: #ffffff;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.select-and-donate ul li .btn-outline-primary {
  padding: 15px 20px;
  border-color: #ffffff;
  border-radius: 0;
  color: #ffffff;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.select-and-donate ul li .btn-outline-primary:hover {
  color: #dd131a;
  background-color: #ffffff;
}

.political-donation-content {
  background-color: #dd131a;
  padding: 100px;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.political-donation-content .donate-text span {
  color: #ffffff;
  display: inline-block;
  margin-bottom: 15px;
}

.political-donation-content .donate-text h2 {
  color: #ffffff;
  font-size: 48px;
  font-family: "Poppins", sans-serif;
}

.political-intro-video {
  text-align: center;
  max-width: 700px;
  margin: auto;
}

.political-intro-video .political-section-title span {
  color: #ffffff;
}

.political-intro-video .political-section-title h2 {
  color: #ffffff;
}

.political-intro-video .play-btn {
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.political-intro-video .play-btn i {
  position: relative;
  color: #ffffff;
  background-color: #dd131a;
  height: 70px;
  width: 70px;
  line-height: 70px;
  padding-top: 3px;
  font-size: 25px;
  padding-left: 1px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.political-intro-video .play-btn::before {
  position: absolute;
  content: '';
  height: 80px;
  width: 80px;
  border-radius: 100%;
  background-color: rgba(221, 19, 26, 0.5);
  z-index: -1;
  top: -5px;
  left: auto;
  margin-left: -5px;
  right: auto;
  -webkit-animation: ripple 4s linear infinite;
          animation: ripple 4s linear infinite;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.political-intro-video .play-btn:hover i {
  background-color: #0c1a3c;
  color: #ffffff;
}

.political-intro-video .play-btn:hover::before {
  background-color: rgba(12, 26, 60, 0.5);
}

/*Volunteer Style
======================================================*/
.political-volunteer-card {
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}

.political-volunteer-card .volunteer-content {
  text-align: center;
  padding: 30px;
}

.political-volunteer-card .volunteer-content h3 {
  font-size: 22px;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
  color: #0c1a3c;
}

.political-volunteer-card .volunteer-content p {
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
}

.political-volunteer-card .volunteer-content .social-links ul {
  margin-bottom: 0;
  padding-left: 0;
}

.political-volunteer-card .volunteer-content .social-links ul li {
  display: inline-block;
  margin-right: 10px;
}

.political-volunteer-card .volunteer-content .social-links ul li:last-child {
  margin-right: 0;
}

.political-volunteer-card .volunteer-content .social-links ul li a {
  height: 40px;
  width: 40px;
  line-height: 43px;
  background-color: #ffffff;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #eeeeee;
  color: #555555;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.political-volunteer-card .volunteer-content .social-links ul li a:hover {
  background-color: #dd131a;
  color: #ffffff;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

/*Freedom Style
======================================================*/
.political-freedom-content {
  background-color: #0c1a3c;
  background-size: cover;
  background-position: left;
}

.freedom-contet {
  padding-left: 60px;
}

.freedom-contet span {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: inline-block;
}

.freedom-contet h2 {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  margin-bottom: 25px;
}

/*Testimonial Style
======================================================*/
.political-testimonial-card {
  padding: 40px;
  padding-bottom: 50px;
  background-color: #ffffff;
  margin-bottom: 30px;
}

.political-testimonial-card .ratinig-star {
  margin-bottom: 20px;
}

.political-testimonial-card .ratinig-star ul {
  padding-left: 0;
  margin-bottom: 0;
}

.political-testimonial-card .ratinig-star ul li {
  display: inline-block;
  margin-right: 5px;
}

.political-testimonial-card .ratinig-star ul li:last-child {
  margin-right: 0;
}

.political-testimonial-card .ratinig-star ul li i {
  color: #ffc107;
}

.political-testimonial-card p {
  margin-bottom: 35px;
  font-family: "Roboto", sans-serif;
}

.political-testimonial-card .clients-profile {
  position: relative;
  padding-left: 100px;
}

.political-testimonial-card .clients-profile img {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: auto;
}

.political-testimonial-card .clients-profile h3 {
  font-size: 22px;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
  color: #0c1a3c;
}

.political-testimonial-slider .swiper-pagination {
  position: initial;
}

.political-testimonial-slider .swiper-pagination .swiper-pagination-bullet {
  background: #cccccc;
  width: 12px;
  height: 12px;
  opacity: 1;
}

.political-testimonial-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #dd131a;
}

/*News Style
======================================================*/
.political-join-us-form {
  padding: 30px;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}

.political-join-us-form h3 {
  font-size: 22px;
  margin-bottom: 30px;
  font-family: "Poppins", sans-serif;
}

.political-join-us-form .form-group {
  margin-bottom: 25px;
}

.political-join-us-form .form-group label {
  margin-bottom: 7px;
}

.political-news-card .news-img {
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.political-news-card .news-img img {
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  overflow: hidden;
}

.political-news-card .news-content {
  padding-top: 30px;
  padding-bottom: 25px;
}

.political-news-card .news-content .list {
  padding-left: 0;
  margin-bottom: 15px;
}

.political-news-card .news-content .list li {
  display: inline-block;
  margin-right: 15px;
  position: relative;
  padding-left: 23px;
  font-size: 14px;
}

.political-news-card .news-content .list li a {
  color: #0c1a3c;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.political-news-card .news-content .list li a:hover {
  color: #dd131a;
}

.political-news-card .news-content .list li i {
  position: absolute;
  left: 0;
  top: 1px;
  color: #dd131a;
}

.political-news-card .news-content h2 {
  font-size: 22px;
  line-height: 1.4;
}

.political-news-card .news-content h2 a {
  color: #0c1a3c;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.political-news-card .news-content h2 a:hover {
  color: #dd131a;
}

.political-news-card:hover .news-img img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

/*Footer Style
======================================================*/
.political-footer-area {
  background-color: #0c1a3c;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/*Crypto Currency Demo Style
=================================================
================================================*/
/*Banner Style
======================================================*/
.currency-banner-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 180px;
  padding-bottom: 50px;
}

.currency-banner-area .container-fluid {
  max-width: 1690px;
  padding-left: 30px;
  padding-right: 30px;
}

.currency-banner-content {
  margin-bottom: 60px;
  max-width: 720px;
  position: relative;
}

.currency-banner-content span {
  color: #0b6dff;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  margin-bottom: 10px;
}

.currency-banner-content h1 {
  font-size: 85px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  margin-bottom: 30px;
}

.currency-banner-content p {
  font-family: "Roboto", sans-serif;
  margin-bottom: 30px;
  color: #ffffff;
}

.currency-banner-content .banner-shape-3 {
  position: absolute;
  left: -80px;
  top: 40%;
  -webkit-animation: movebounce 5s linear infinite;
          animation: movebounce 5s linear infinite;
}

.currency-banner-content .banner-shape-4 {
  position: absolute;
  right: 0;
  bottom: -50px;
  -webkit-animation: ripple 9s linear infinite;
          animation: ripple 9s linear infinite;
}

.currency-banner-image {
  position: relative;
}

.currency-banner-image .banner-shape-1 {
  position: absolute;
  top: -50px;
  left: 0;
  -webkit-animation: movebounce2 4s linear infinite;
          animation: movebounce2 4s linear infinite;
}

.currency-banner-image .banner-shape-2 {
  position: absolute;
  top: 0;
  right: 150px;
  -webkit-animation: movebounce 5s linear infinite;
          animation: movebounce 5s linear infinite;
}

.currency-banner-image .currency-form {
  max-width: 500px;
  padding: 30px;
  background: linear-gradient(112.92deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 252, 252, 0.1) 100%);
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  border-radius: 10px;
  position: absolute;
  right: 0;
  bottom: 200px;
}

.currency-banner-image .currency-form .form-group {
  border: 1px solid #ffffff;
  padding: 10px;
  margin-bottom: 25px;
  border-radius: 10px;
  position: relative;
}

.currency-banner-image .currency-form .form-group label {
  color: #ffffff;
  margin-bottom: 0;
  padding-left: 10px;
}

.currency-banner-image .currency-form .form-group .form-control {
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-weight: 700;
  height: auto;
  font-size: 18px;
  padding: 0;
  padding-left: 15px;
}

.currency-banner-image .currency-form .form-group .form-control::-webkit-input-placeholder {
  color: #ffffff;
  font-size: 18px;
}

.currency-banner-image .currency-form .form-group .form-control:-ms-input-placeholder {
  color: #ffffff;
  font-size: 18px;
}

.currency-banner-image .currency-form .form-group .form-control::-ms-input-placeholder {
  color: #ffffff;
  font-size: 18px;
}

.currency-banner-image .currency-form .form-group .form-control::placeholder {
  color: #ffffff;
  font-size: 18px;
}

.currency-banner-image .currency-form .form-group .form-select {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 15px;
  font-weight: 600;
  color: #0c1a3c;
  width: 110px;
  border-radius: 0 10px 10px 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  /*background-image: url('../../images/svg-icon/icon-5.png');*/
}

.currency-banner-image .currency-form .currency-default-btn {
  width: 100%;
}

.cryptocurrency-contact-form {
  background-color: #25282e;
}

/*Features Style
======================================================*/
.currency-featured-card {
  padding: 30px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
  margin-bottom: 30px;
}

.currency-featured-card img {
  margin-bottom: 10px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.currency-featured-card h3 {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  margin-bottom: 20px;
}

.currency-featured-card p {
  font-family: "Roboto", sans-serif;
  color: #ffffff;
}

.currency-featured-card:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/*About Style
======================================================*/
.currency-about-content .currency-about-title span {
  font-size: 16px;
  color: #0b6dff;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
}

.currency-about-content .currency-about-title h2 {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 25px;
}

.currency-about-content .about-text {
  margin-bottom: 30px;
}

.currency-about-content .about-text p {
  margin-bottom: 20px;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
}

/*Buy And Sell Area Style
======================================================*/
.currency-buy-and-sell-area {
  /* Firefox */
  /* Chrome, Edge, and Safari */
}

.currency-buy-and-sell-area .scrollmenu {
  overflow: auto;
  -ms-flex-wrap: unset;
      flex-wrap: unset;
}

.currency-buy-and-sell-area * {
  scrollbar-width: auto;
  scrollbar-color: #1c2029 #0e1013;
}

.currency-buy-and-sell-area *::-webkit-scrollbar {
  width: 16px;
}

.currency-buy-and-sell-area *::-webkit-scrollbar-track {
  background: #0e1013;
}

.currency-buy-and-sell-area *::-webkit-scrollbar-thumb {
  background-color: #1c2029;
  border-radius: 0;
  border: 3px solid #0e1013;
}

.currency-buy-card {
  display: inline-block;
  margin-bottom: 50px;
  margin-right: 20px;
  background-color: #0e1013;
}

.currency-buy-card:last-child {
  margin-right: 0;
}

.currency-buy-card .curency-buy-content {
  padding: 30px;
}

.currency-buy-card .curency-buy-content h3 {
  font-size: 22px;
  color: #ffffff;
  margin-bottom: 18px;
  font-family: "Poppins", sans-serif;
}

.currency-buy-card .curency-buy-content p {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  max-width: 410px;
}

.currency-intro-video-area {
  position: relative;
}

.currency-intro-video-area::before {
  position: absolute;
  content: '';
  background-color: #0e1013;
  left: 0;
  bottom: 0;
  height: 50%;
  width: 100%;
}

.currency-intro-video-area .video-image {
  position: relative;
  position: relative;
  overflow: hidden;
}

.currency-intro-video-area .video-image img {
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.currency-intro-video-area .video-image .icon {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
}

.currency-intro-video-area .video-image .icon .play-btn {
  font-size: 26px;
  background-color: #0b6dff;
  color: #ffffff;
  text-align: center;
  height: 70px;
  width: 70px;
  line-height: 75px;
  border-radius: 100%;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.currency-intro-video-area .video-image .icon .play-btn i {
  position: relative;
  left: 3px;
  top: 1px;
}

.currency-intro-video-area .video-image .icon .play-btn:hover {
  background-color: #ffffff;
  color: #0b6dff;
}

.currency-intro-video-area .video-image .icon::before {
  position: absolute;
  content: '';
  height: 80px;
  width: 80px;
  border-radius: 100%;
  background-color: rgba(11, 109, 255, 0.6);
  -webkit-animation: ripple 4s linear infinite;
          animation: ripple 4s linear infinite;
  z-index: -1;
}

.currency-intro-video-area .video-image .icon::after {
  position: absolute;
  content: '';
  height: 90px;
  width: 90px;
  border-radius: 100%;
  background-color: rgba(11, 109, 255, 0.4);
  -webkit-animation: ripple 3s linear infinite;
          animation: ripple 3s linear infinite;
  z-index: -1;
}

.currency-intro-video-area .video-image:hover img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-filter: blur(3px);
          filter: blur(3px);
}

.currecy-user-content {
  text-align: center;
  position: relative;
}

.currecy-user-content .currency-user-list {
  padding-left: 0;
  margin-bottom: 0;
  text-align: left;
}

.currecy-user-content .currency-user-list li {
  position: absolute;
  list-style-type: none;
  top: 38%;
  left: 245px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.currecy-user-content .currency-user-list li .user-img {
  position: relative;
  border-radius: 100%;
  height: 82px;
  width: 82px;
  z-index: 1;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.currecy-user-content .currency-user-list li .user-img img {
  width: 70px;
  height: 70px;
  border-radius: 100%;
}

.currecy-user-content .currency-user-list li .user-img::before {
  position: absolute;
  content: '';
  height: 78px;
  width: 78px;
  top: -10px;
  left: -10px;
  border-radius: 100%;
  background-color: rgba(11, 109, 255, 0.6);
  z-index: -1;
  opacity: 0;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.currecy-user-content .currency-user-list li .user-content {
  max-width: 260px;
  padding: 30px;
  background-color: #ffffff;
  text-align: left;
  border-radius: 10px;
  position: relative;
  top: -30px;
  left: 37px;
  display: none;
  visibility: hidden;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  z-index: 3;
}

.currecy-user-content .currency-user-list li .user-content .rating-list {
  margin-bottom: 8px;
  position: relative;
}

.currecy-user-content .currency-user-list li .user-content .rating-list i {
  color: #ffc107;
  font-size: 14px;
  margin-left: 3px;
}

.currecy-user-content .currency-user-list li .user-content .rating-list i:first-child {
  margin-left: 0;
}

.currecy-user-content .currency-user-list li .user-content p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 12px;
}

.currecy-user-content .currency-user-list li .user-content h3 {
  font-size: 16px;
  margin-bottom: 5px;
}

.currecy-user-content .currency-user-list li .user-content span {
  font-size: 14px;
}

.currecy-user-content .currency-user-list li .user-content::before {
  position: absolute;
  content: '';
  height: 50px;
  width: 50px;
  border-left: 15px solid #ffffff;
  border-right: 15px solid #ffffff;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  left: -10px;
  top: 15px;
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
}

.currecy-user-content .currency-user-list li:hover {
  visibility: visible;
}

.currecy-user-content .currency-user-list li:hover .user-img {
  border: 6px solid #0b6dff;
}

.currecy-user-content .currency-user-list li:hover .user-img::before {
  opacity: 1;
  -webkit-animation: ripple 4s linear infinite;
          animation: ripple 4s linear infinite;
}

.currecy-user-content .currency-user-list li:hover .user-content {
  display: block;
  visibility: visible;
}

.currecy-user-content .currency-user-list li:nth-child(2) {
  left: 280px;
  top: 66%;
}

.currecy-user-content .currency-user-list li:nth-child(3) {
  left: 360px;
  top: 75%;
}

.currecy-user-content .currency-user-list li:nth-child(4) {
  left: 400px;
  top: 10%;
}

.currecy-user-content .currency-user-list li:nth-child(4) .user-img {
  border: 6px solid #0b6dff;
}

.currecy-user-content .currency-user-list li:nth-child(4) .user-img::before {
  opacity: 1;
  -webkit-animation: ripple 4s linear infinite;
          animation: ripple 4s linear infinite;
}

.currecy-user-content .currency-user-list li:nth-child(5) {
  left: 680px;
  top: 35%;
}

.currecy-user-content .currency-user-list li:nth-child(6) {
  left: 600px;
  top: 60%;
}

.currecy-user-content .currency-user-list li:nth-child(7) {
  left: 720px;
  top: 20%;
}

.currecy-user-content .currency-user-list li:nth-child(8) {
  left: 880px;
  top: 35%;
}

.currecy-user-content .currency-user-list li:nth-child(9) {
  left: 1000px;
  top: 76%;
}

/*Buy And Value Style
======================================================*/
.buy-and-value-table {
  margin-bottom: 40px;
  overflow-y: hidden;
}

.buy-and-value-table thead tr {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.buy-and-value-table thead tr th {
  color: #ffffff;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  padding: 15px 15px;
}

.buy-and-value-table tbody tr {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.buy-and-value-table tbody tr td {
  vertical-align: middle;
  white-space: nowrap;
  padding: 15px 15px;
  color: #ffffff;
  text-align: center;
}

.buy-and-value-table tbody tr td.serial {
  font-weight: 500;
  color: #ffffff;
}

.buy-and-value-table tbody tr td.name a {
  position: relative;
  padding-left: 25px;
  display: inline-block;
}

.buy-and-value-table tbody tr td.name a i {
  position: absolute;
  color: #0b6dff;
  font-size: 18px;
  top: 0;
  left: 0;
}

.buy-and-value-table tbody tr td.name p {
  color: #ffffff;
  font-weight: 500;
}

.buy-and-value-table tbody tr td.name p span {
  font-weight: 400;
  color: #cccccc;
}

.buy-and-value-table tbody tr td.name p:hover {
  color: #0b6dff;
}

.buy-and-value-table tbody tr td.price p {
  color: #ffffff;
}

.buy-and-value-table tbody tr td.price p span {
  color: #cccccc;
  display: inline-block;
}

.buy-and-value-table tbody tr td.change {
  color: #0b6dff;
}

/*Download Style
======================================================*/
.currency-download-area .container {
  background-color: #0e1013;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 10px;
  z-index: 1;
  position: relative;
}

.currency-download-area .container::before {
  position: absolute;
  content: '';
  background-color: #1c2029;
  height: 40px;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.currency-download-area .container::after {
  position: absolute;
  content: '';
  background-color: #1c2029;
  height: 40px;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.currency-download-content .download-title span {
  font-size: 16px;
  color: #0b6dff;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
}

.currency-download-content .download-title h2 {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 25px;
}

.currency-download-content p {
  margin-bottom: 30px;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
}

.currency-download-content .download-btn ul {
  padding-left: 0;
  margin-bottom: 0;
}

.currency-download-content .download-btn ul li {
  display: inline-block;
  margin-right: 20px;
}

.currency-download-content .download-btn ul li:last-child {
  margin-right: 0;
}

.currency-download-content .download-btn ul li img {
  border-radius: 5px;
}

/*Team Style
======================================================*/
.currency-team-card {
  position: relative;
  margin-bottom: 30px;
}

.currency-team-card .team-image img {
  border-radius: 10px;
}

.currency-team-card .team-content {
  padding: 30px;
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  border-radius: 10px;
  background-color: #0e1013;
  text-align: center;
}

.currency-team-card .team-content h3 {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  margin-bottom: 10px;
}

.currency-team-card .team-content span {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  margin-bottom: 20px;
}

.currency-team-card .team-content .social-links ul {
  margin-bottom: 0;
  padding-left: 0;
}

.currency-team-card .team-content .social-links ul li {
  margin: 0 3px;
  display: inline-block;
}

.currency-team-card .team-content .social-links ul li a {
  height: 40px;
  width: 40px;
  line-height: 43px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  color: #ffffff;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.currency-team-card .team-content .social-links ul li a:hover {
  background-color: #0b6dff;
  border-color: #0b6dff;
  color: #ffffff;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

/*Coin Sell Style
======================================================*/
.currency-coin-sell-content .coin-sell-title span {
  font-size: 16px;
  color: #0b6dff;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
}

.currency-coin-sell-content .coin-sell-title h2 {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 20px;
}

.currency-coin-sell-content .coin-sell-title p {
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  margin-bottom: 30px;
}

.currency-coin-sell-content .coin-sell-btn {
  margin-bottom: 30px;
}

.currency-coin-sell-content .bank-links a {
  margin-right: 20px;
}

.currency-coin-sell-content .bank-links a:last-child {
  margin-right: 0;
}

.currency-time-list {
  margin-bottom: 30px;
}

.currency-time-list ul {
  margin-bottom: 0;
  padding-left: 0;
}

.currency-time-list ul li {
  margin: 0 30px;
  height: 70px;
  width: 90px;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #ffffff;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 10px 20px;
}

.currency-time-list ul li span {
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  position: absolute;
  bottom: -35px;
}

.currency-time-list ul li::before {
  position: absolute;
  content: '';
  height: 6px;
  width: 6px;
  background-color: #ffffff;
  border-radius: 100%;
  right: -30px;
  top: 26px;
}

.currency-time-list ul li::after {
  position: absolute;
  content: '';
  height: 6px;
  width: 6px;
  background-color: #ffffff;
  border-radius: 100%;
  right: -30px;
  top: 40px;
}

.currency-time-list ul li:last-child::before {
  display: none;
}

.currency-time-list ul li:last-child::after {
  display: none;
}

.currency-time-list ul li:first-child {
  margin-left: 0;
}

/*Testimonial Style
======================================================*/
.cryptocurrency-testimonials .swiper-pagination {
  position: initial;
}

.cryptocurrency-testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #3e3e3e;
  opacity: 1;
}

.cryptocurrency-testimonials .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fff;
}

.currency-testimonial-card {
  background-color: #1c2029;
  padding: 40px;
  padding-bottom: 50px;
  margin-bottom: 40px;
}

.currency-testimonial-card .rating-list {
  padding-left: 0;
  margin-bottom: 15px;
}

.currency-testimonial-card .rating-list li {
  display: inline-block;
  color: #ffc107;
  margin-right: 5px;
}

.currency-testimonial-card .rating-list li:last-child {
  margin-right: 0;
}

.currency-testimonial-card p {
  color: #ffffff;
  margin-bottom: 35px;
}

.currency-testimonial-card .cliens-profile {
  position: relative;
  padding-left: 85px;
}

.currency-testimonial-card .cliens-profile img {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 70px;
  height: 70px;
  border-radius: 100%;
}

.currency-testimonial-card .cliens-profile h3 {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  margin-bottom: 10px;
}

.currency-testimonial-card .cliens-profile span {
  color: #bdbdbd;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
}

/*Blog Style
======================================================*/
.currency-blog-area .text-center a {
  color: #ffffff;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.currency-blog-area .text-center a:hover {
  color: #0b6dff;
}

.currency-blog-card {
  margin-bottom: 30px;
}

.currency-blog-card .blog-img {
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 10px;
}

.currency-blog-card .blog-img img {
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 10px;
}

.currency-blog-card .blog-content {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 25px;
  padding-bottom: 30px;
}

.currency-blog-card .blog-content h2 {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 15px;
  line-height: 1.5;
}

.currency-blog-card .blog-content h2 a {
  color: #ffffff;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.currency-blog-card .blog-content h2 a:hover {
  color: #0b6dff;
}

.currency-blog-card .blog-content p {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
}

.currency-blog-card .blog-content a {
  color: #0b6dff;
  font-family: "Roboto", sans-serif;
}

.currency-blog-card:hover .blog-img img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/*NFT Demo Style
=================================================
================================================*/
/*Banner Style
======================================================*/
.nft-banner-area {
  background-color: #0a0a0a;
  padding-top: 100px;
}

.nft-banner-area .container-fluid {
  max-width: 1690px;
  padding-left: 30px;
  padding-right: 30px;
}

.nft-banner-content {
  padding-right: 30px;
}

.nft-banner-content span {
  color: #06dbac;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 500;
  display: inline-block;
}

.nft-banner-content h1 {
  font-size: 80px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 15px;
  color: #ffffff;
}

.nft-banner-content p {
  color: #cccccc;
  margin-bottom: 30px;
}

.nft-banner-image {
  text-align: right;
  position: relative;
  margin-left: 50px;
}

.nft-banner-image .current-bid {
  position: absolute;
  background: linear-gradient(112.92deg, rgba(255, 255, 255, 0.09) 0%, rgba(12, 10, 12, 0.8) 100%);
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  max-width: 330px;
  padding: 30px;
  padding-top: 50px;
  left: 0;
  bottom: 200px;
  text-align: left;
  border-radius: 10px;
}

.nft-banner-image .current-bid .profile {
  position: relative;
  padding-left: 80px;
  margin-bottom: 50px;
}

.nft-banner-image .current-bid .profile img {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 70px;
  height: 70px;
  border-radius: 100%;
}

.nft-banner-image .current-bid .profile h3 {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
}

.nft-banner-image .current-bid .bid-list {
  padding-left: 0;
  margin-bottom: 20px;
}

.nft-banner-image .current-bid .bid-list li {
  list-style-type: none;
  position: relative;
}

.nft-banner-image .current-bid .bid-list li::before {
  position: absolute;
  content: '';
  height: 50px;
  width: 1px;
  background-color: #ffffff;
  right: -32px;
  top: 7px;
}

.nft-banner-image .current-bid .bid-list li p {
  color: #1ea5ff;
  margin-bottom: 10px;
}

.nft-banner-image .current-bid .bid-list li span {
  color: #ffffff;
}

.nft-banner-image .current-bid .bid-list li:last-child::before {
  display: none;
}

.nft-banner-image .current-bid .bid-list li:last-child p {
  color: #06dbac;
}

.nft-banner-image .current-bid a {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 7px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.nft-banner-image .current-bid a i {
  position: relative;
  top: 2px;
  padding-left: 7px;
}

.nft-banner-image .current-bid a:hover {
  color: #06dbac;
  border-color: #06dbac;
}

/*Tranding Style
======================================================*/
.trnding-top-content {
  width: 100%;
}

.trnding-top-content .tranding-btn {
  text-align: right;
  margin-bottom: 40px;
}

.nft-tranding-card {
  background-color: #0a0a0a;
  border-radius: 10px;
  margin-bottom: 30px;
}

.nft-tranding-card .tanding-img {
  position: relative;
}

.nft-tranding-card .tanding-img img {
  border-radius: 10px 10px 0 0;
}

.nft-tranding-card .tanding-img .tranding-list {
  position: absolute;
  left: 30px;
  bottom: -23px;
}

.nft-tranding-card .tanding-img .tranding-list ul {
  padding-left: 0;
  margin-bottom: 0;
}

.nft-tranding-card .tanding-img .tranding-list ul li {
  display: inline-block;
  margin-left: -20px;
}

.nft-tranding-card .tanding-img .tranding-list ul li:first-child {
  margin-left: 0;
}

.nft-tranding-card .tanding-img .tranding-list ul li img {
  height: 50px;
  width: 50px;
  border: 3px solid #ffffff;
  border-radius: 100%;
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.nft-tranding-card .tanding-img .tranding-list ul li img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.nft-tranding-card .tranding-content {
  padding: 30px;
  padding-top: 50px;
}

.nft-tranding-card .tranding-content .left-content h3 {
  font-size: 22px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  margin-bottom: 15px;
}

.nft-tranding-card .tranding-content .left-content span {
  color: #ffffff;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.nft-tranding-card .tranding-content .right-content {
  text-align: right;
}

.nft-tranding-card .tranding-content .right-content h4 {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 15px;
}

.nft-tranding-card .tranding-content .right-content h4 i {
  color: #06dbac;
  font-size: 16px;
  position: relative;
  top: 2px;
  padding-right: 7px;
}

.nft-tranding-card .tranding-content .right-content span {
  color: #ffffff;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

/*Top Sellers Style
======================================================*/
.top-sellers-top-content .top-sellers-btn {
  margin-bottom: 40px;
  text-align: right;
}

.nft-top-sellers-area {
  overflow: hidden;
}

.top-sellers-box {
  padding: 40px;
  border: 1px solid rgba(75, 75, 75, 0.8);
  border-radius: 10px;
  position: relative;
  margin-bottom: 30px;
}

.top-sellers-box .number {
  position: absolute;
  font-size: 140px;
  font-weight: 700;
  left: 15px;
  top: -25px;
  opacity: .1;
}

.top-sellers-box .seller-address {
  position: relative;
  padding-left: 85px;
}

.top-sellers-box .seller-address > img {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 70px;
  height: 70px;
  border-radius: 100%;
}

.top-sellers-box .seller-address h3 {
  font-size: 19px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px;
}

.top-sellers-box .seller-address span {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
}

.top-sellers-box .seller-address span img {
  position: relative;
  top: -3px;
  padding-right: 5px;
}

.top-sellers-box .sell {
  text-align: end;
}

.top-sellers-box .sell span {
  background: rgba(6, 219, 173, 0.15);
  color: #06dbac;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #11b18f;
}

@-webkit-keyframes text-scrolling {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(120%, 0, 0);
            transform: translate3d(120%, 0, 0);
  }
}

@keyframes text-scrolling {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(120%, 0, 0);
            transform: translate3d(120%, 0, 0);
  }
}

@-webkit-keyframes text-scrolling2 {
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  0% {
    -webkit-transform: translate3d(120%, 0, 0);
            transform: translate3d(120%, 0, 0);
  }
}

@keyframes text-scrolling2 {
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  0% {
    -webkit-transform: translate3d(120%, 0, 0);
            transform: translate3d(120%, 0, 0);
  }
}

/*Live Auction Style
======================================================*/
.auction-top-content .auction-btn {
  text-align: right;
  margin-bottom: 40px;
}

.live-auction-card {
  background-color: #0a0a0a;
  border-radius: 7px;
}

.live-auction-card .auction-image {
  position: relative;
}

.live-auction-card .auction-image .live-auctions-countdown {
  position: absolute;
  padding-top: 20px;
  padding-bottom: 15px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background: linear-gradient(90.03deg, rgba(0, 0, 0, 0.5) 2.49%, rgba(0, 0, 0, 0.5) 116.75%);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  text-align: center;
  border-radius: 5px;
  padding-left: 0;
}

.live-auction-card .auction-image .live-auctions-countdown li {
  position: relative;
  margin: 0 8px;
}

.live-auction-card .auction-image .live-auctions-countdown li span {
  height: 40px;
  width: 45px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #ffffff;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #0a0a0a;
  border-radius: 5px;
  padding: 7px;
  display: inline-block;
}

.live-auction-card .auction-image .live-auctions-countdown li p {
  font-size: 11px;
  color: #ffffff;
}

.live-auction-card .auction-image .live-auctions-countdown li::before {
  position: absolute;
  content: '';
  height: 3px;
  width: 3px;
  background-color: #ffffff;
  border-radius: 100%;
  right: -12px;
  top: 15px;
}

.live-auction-card .auction-image .live-auctions-countdown li::after {
  position: absolute;
  content: '';
  height: 3px;
  width: 3px;
  background-color: #ffffff;
  border-radius: 100%;
  right: -12px;
  top: 24px;
}

.live-auction-card .auction-image .live-auctions-countdown li:last-child {
  margin-right: 0;
}

.live-auction-card .auction-image .live-auctions-countdown li:last-child::before {
  display: none;
}

.live-auction-card .auction-image .live-auctions-countdown li:last-child::after {
  display: none;
}

.live-auction-card .auction-image .live-auctions-countdown li:first-child {
  margin-left: 0;
}

.live-auction-card .auction-content {
  padding: 25px;
}

.live-auction-card .auction-content .top-content {
  margin-bottom: 20px;
}

.live-auction-card .auction-content .top-content .img-list {
  padding-left: 0;
  margin-bottom: 0;
}

.live-auction-card .auction-content .top-content .img-list li {
  display: inline-block;
  margin-left: -13px;
}

.live-auction-card .auction-content .top-content .img-list li:first-child {
  margin-left: 0;
}

.live-auction-card .auction-content .top-content .img-list li img {
  height: 32px;
  width: 32px;
  border: 3px solid #ffffff;
  border-radius: 100%;
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.live-auction-card .auction-content .top-content .img-list li img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.live-auction-card .auction-content .top-content span {
  color: #ffffff;
  font-size: 13px;
  position: relative;
  margin-left: -15px;
  top: 7px;
}

.live-auction-card .auction-content .top-content .more-btn {
  text-align: right;
}

.live-auction-card .auction-content .top-content .more-btn a {
  color: #ffffff;
  font-size: 18px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.live-auction-card .auction-content .top-content .more-btn a:hover {
  color: #06dbac;
}

.live-auction-card .auction-content h3 {
  font-size: 22px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

.live-auction-card .auction-content span {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  margin-bottom: 18px;
}

.live-auction-card .auction-content .blottom-content ul {
  padding-left: 0;
  margin-bottom: 0;
}

.live-auction-card .auction-content .blottom-content ul li {
  list-style-type: none;
  color: #ffffff;
}

.live-auction-card .auction-content .blottom-content ul li i {
  position: relative;
  color: #06dbac;
  top: 3px;
  padding-right: 7px;
}

.live-action-slider .swiper-pagination {
  position: initial;
  margin-top: 30px;
}

.live-action-slider .swiper-pagination .swiper-pagination-bullet {
  background-color: #ccc;
  width: 12px;
  height: 12px;
  opacity: 1;
}

.live-action-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #06dbac;
}

/*Create And Sell Style
======================================================*/
.creat-and-sell-card {
  padding: 30px;
  background: linear-gradient(137.43deg, rgba(255, 255, 255, 0.3) 3.89%, rgba(255, 255, 255, 0.2) 100%);
  -webkit-backdrop-filter: blur(23.2936px);
          backdrop-filter: blur(23.2936px);
  border-radius: 7px;
  position: relative;
  margin-bottom: 30px;
}

.creat-and-sell-card img {
  margin-bottom: 20px;
}

.creat-and-sell-card h3 {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  margin-bottom: 15px;
}

.creat-and-sell-card p {
  color: #ffffff;
  margin-bottom: 0;
}

.creat-and-sell-card .number {
  font-size: 90px;
  color: #ffffff;
  opacity: .1;
  position: absolute;
  font-weight: 700;
  top: 10px;
  right: 20px;
}

/*Author Style
======================================================*/
.nft-author-top-content .author-btn {
  text-align: right;
  margin-bottom: 40px;
}

.nft-author-card {
  text-align: center;
  background: -webkit-gradient(linear, left top, right top, color-stop(-1.52%, rgba(255, 255, 255, 0.1)), color-stop(104.35%, rgba(255, 255, 255, 0.024)));
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) -1.52%, rgba(255, 255, 255, 0.024) 104.35%);
  -webkit-backdrop-filter: blur(79.2003px);
          backdrop-filter: blur(79.2003px);
  margin-bottom: 30px;
  border-radius: 7px;
}

.nft-author-card .author-img {
  position: relative;
}

.nft-author-card .author-img img {
  border-radius: 7px 7px 0 0;
}

.nft-author-card .author-img .author-profile-img {
  position: absolute;
  bottom: -70px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.nft-author-card .author-img .author-profile-img img {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: 3px solid #ffffff;
}

.nft-author-card .author-content {
  padding: 100px 30px 35px 30px;
}

.nft-author-card .author-content h3 {
  font-size: 22px;
  color: #ffffff;
  margin-bottom: 20px;
}

.nft-author-card .author-content span {
  color: #06dbac;
  margin-bottom: 30px;
  display: inline-block;
}

.nft-author-card .author-content ul {
  padding-left: 0;
  margin-bottom: 0;
}

.nft-author-card .author-content ul li {
  list-style-type: none;
}

.nft-author-card .author-content ul li h3 {
  font-size: 30px;
  color: #ffffff;
  margin-bottom: 10px;
}

.nft-author-card .author-content ul li span {
  margin-bottom: 30px;
}

/*Collection Style
======================================================*/
.collection-card {
  padding: 25px;
  background: -webkit-gradient(linear, left top, right top, color-stop(-1.52%, rgba(184, 183, 184, 0.1)), color-stop(104.35%, rgba(105, 104, 107, 0.1)));
  background: linear-gradient(90deg, rgba(184, 183, 184, 0.1) -1.52%, rgba(105, 104, 107, 0.1) 104.35%);
  -webkit-backdrop-filter: blur(79.2003px);
          backdrop-filter: blur(79.2003px);
  margin-bottom: 30px;
  border-radius: 7px;
}

.collection-card .collction-top-content {
  margin-bottom: 25px;
}

.collection-card .collction-top-content .author-profile {
  position: relative;
  padding-left: 70px;
}

.collection-card .collction-top-content .author-profile img {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.collection-card .collction-top-content .author-profile h3 {
  font-size: 22px;
  color: #19191b;
  font-family: "Poppins", sans-serif;
}

.collection-card .collction-top-content .author-profile span {
  font-family: "Roboto", sans-serif;
}

.collection-card .collction-top-content .heart-and-number {
  text-align: right;
}

.collection-card .collction-top-content .heart-and-number span {
  color: #bd00ff;
  font-size: 16px;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px 15px;
  display: inline-block;
}

.collection-card .collction-top-content .heart-and-number span i {
  position: relative;
  padding-right: 7px;
  top: 2px;
}

.collection-card .collection-img-wrap img {
  width: 100%;
}

.collection-card .collection-img-wrap .collection-img {
  margin-bottom: 24px;
}

.collection-card .collection-img-wrap .collection-img.bg-1 {
  /*background-image: url('/images/collection/cullection-1.jpg');*/
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.collection-card .collection-img-wrap .collection-img.bg-2 {
  /*background-image: url('/images/collection/cullection-5.jpg');*/
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.collection-card .collection-img-wrap .collection-img.bg-3 {
  /*background-image: url('/images/collection/cullection-9.jpg');*/
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

/*Priducts Style
======================================================*/
.shorting .mix {
  display: none;
}

.products-top-content .shoting-btn {
  text-align: right;
  margin-bottom: 40px;
}

.products-top-content .shoting-btn ul {
  padding-left: 0;
  margin-bottom: 0;
}

.products-top-content .shoting-btn ul li {
  display: inline-block;
}

.products-top-content .shoting-btn ul li button {
  background-color: transparent;
  border: 1px solid #eeeeee;
  padding: 10px 30px;
  border-radius: 5px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.products-top-content .shoting-btn ul li button:hover, .products-top-content .shoting-btn ul li button:focus, .products-top-content .shoting-btn ul li button.active {
  border-color: #06dbac;
}

.nft-product-card {
  background-color: #0a0a0a;
  margin-bottom: 30px;
}

.nft-product-card .product-img {
  position: relative;
}

.nft-product-card .product-img .number {
  position: absolute;
  top: 20px;
  left: 20px;
}

.nft-product-card .product-img .number span {
  color: #0797ff;
  font-size: 16px;
  background-color: #ffffff;
  padding: 8px 12px;
  border-radius: 5px;
}

.nft-product-card .product-img .number span i {
  position: relative;
  top: 2px;
  padding-right: 7px;
}

.nft-product-card .product-img .products-bid-btn {
  position: absolute;
  top: 50%;
  left: 24%;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}

.nft-product-card .product-content {
  padding: 25px;
}

.nft-product-card .product-content .img-and-bid {
  position: relative;
  padding-left: 130px;
  margin-bottom: 40px;
}

.nft-product-card .product-content .img-and-bid .img-list {
  padding-left: 0;
  margin-bottom: 0;
  position: absolute;
  left: 0;
}

.nft-product-card .product-content .img-and-bid .img-list li {
  display: inline-block;
  margin-left: -20px;
}

.nft-product-card .product-content .img-and-bid .img-list li:first-child {
  margin-left: 0;
}

.nft-product-card .product-content .img-and-bid .img-list li img {
  height: 40px;
  width: 40px;
  border: 3px solid #ffffff;
  border-radius: 100%;
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.nft-product-card .product-content .img-and-bid .img-list li img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.nft-product-card .product-content .img-and-bid span {
  position: relative;
  top: 10px;
  color: #ffffff;
}

.nft-product-card .product-content h3 {
  font-size: 22px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.nft-product-card .product-content .bottom-list {
  padding-left: 0;
  margin-bottom: 0;
}

.nft-product-card .product-content .bottom-list li {
  list-style-type: none;
}

.nft-product-card .product-content .bottom-list li span {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
}

.nft-product-card:hover .product-img .products-bid-btn {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.tranding-slider .swiper-pagination {
  position: initial;
}

.tranding-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}

.tranding-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #06dbac;
}

/*Currency Exchange Demo Style
=================================================
================================================*/
/*Header Style
======================================================*/
.currency-exchange-header-area {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}

.currency-exchange-header-area .navbar {
  position: unset;
}

.currency-exchange-header-area .navbar-light.nav-style10.is-sticky {
  background-color: #030734 !important;
  position: fixed !important;
}

.currency-exchange-top-header-area {
  padding-top: 15px;
  padding-bottom: 15px;
}

.currency-exchange-top-header-area .container-fluid {
  max-width: 1690px;
  padding-left: 30px;
  padding-right: 30px;
}

.currency-exchange-top-header-area .exchange-header-left-content ul {
  padding-left: 0;
  margin-bottom: 0;
}

.currency-exchange-top-header-area .exchange-header-left-content ul li {
  display: inline-block;
  margin-right: 20px;
}

.currency-exchange-top-header-area .exchange-header-left-content ul li:last-child {
  margin-right: 0;
}

.currency-exchange-top-header-area .exchange-header-left-content ul li a {
  color: #555555;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.currency-exchange-top-header-area .exchange-header-left-content ul li a:hover {
  color: #00a9a4;
}

.currency-exchange-top-header-area .exchange-header-left-content ul li i {
  position: relative;
  top: 1px;
  padding-right: 10px;
  color: #00a9a4;
}

.currency-exchange-top-header-area .exchange-header-middle-content {
  text-align: center;
}

.currency-exchange-top-header-area .exchange-header-middle-content a {
  color: #00a9a4;
}

.currency-exchange-top-header-area .exchange-header-right-content {
  text-align: right;
}

.currency-exchange-top-header-area .exchange-header-right-content ul {
  padding-left: 0;
  margin-bottom: 0;
}

.currency-exchange-top-header-area .exchange-header-right-content ul li {
  display: inline-block;
  margin-right: 30px;
}

.currency-exchange-top-header-area .exchange-header-right-content ul li:last-child {
  margin-right: 0;
}

.currency-exchange-top-header-area .exchange-header-right-content ul li a {
  color: #555555;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.currency-exchange-top-header-area .exchange-header-right-content ul li a:hover {
  color: #00a9a4;
}

.currency-exchange-top-header-area .exchange-header-right-content ul li .language {
  position: relative;
  padding-left: 17px;
  cursor: pointer;
  top: 0;
}

.currency-exchange-top-header-area .exchange-header-right-content ul li .language select {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.currency-exchange-top-header-area .exchange-header-right-content ul li .language select:focus {
  outline: none;
}

.currency-exchange-top-header-area .exchange-header-right-content ul li .language i {
  position: absolute;
  left: 0;
  top: 0;
  color: #00a9a4;
}

/*Banner Style
======================================================*/
.currency-exchaange-banner {
  background-color: #f5f5f5;
  background-size: cover;
  padding-top: 60px;
}

.currency-exchaange-banner .container-fluid {
  max-width: 1690px;
  padding-left: 30px;
  padding-right: 30px;
}

.currency-exchange-banner-content {
  margin-bottom: 60px;
  position: relative;
}

.currency-exchange-banner-content span {
  color: #00a9a4;
  display: inline-block;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
}

.currency-exchange-banner-content h1 {
  font-size: 75px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 25px;
}

.currency-exchange-banner-content p {
  margin-bottom: 30px;
  font-family: "Roboto", sans-serif;
}

.currency-exchange-banner-content .banner-btn {
  margin-bottom: 40px;
}

.currency-exchange-banner-content .riviews {
  position: relative;
  padding-left: 170px;
}

.currency-exchange-banner-content .riviews .img-list {
  padding-left: 0;
  margin-bottom: 0;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.currency-exchange-banner-content .riviews .img-list li {
  display: inline-block;
  margin-left: -20px;
}

.currency-exchange-banner-content .riviews .img-list li:first-child {
  margin-left: 0;
}

.currency-exchange-banner-content .riviews .img-list li img {
  height: 50px;
  width: 50px;
  border: 3px solid #ffffff;
  border-radius: 100%;
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.currency-exchange-banner-content .riviews .img-list li img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.currency-exchange-banner-content .riviews span {
  color: #555555;
  position: relative;
  top: 6px;
}

.currency-exchange-banner-content .banner-shape-4 {
  position: absolute;
  top: 10px;
  left: -50px;
  -webkit-animation: rotateme 8s linear infinite;
          animation: rotateme 8s linear infinite;
}

.currency-exchange-banner-image {
  position: relative;
  z-index: 1;
}

.currency-exchange-banner-image .currency-converter-form {
  background-color: #ffffff;
  padding: 30px;
  width: 400px;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: absolute;
  left: 30px;
  bottom: 120px;
  z-index: 1;
}

.currency-exchange-banner-image .currency-converter-form h3 {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.currency-exchange-banner-image .currency-converter-form .form-group {
  margin-bottom: 25px;
}

.currency-exchange-banner-image .currency-converter-form .form-group label {
  margin-bottom: 7px;
}

.currency-exchange-banner-image .currency-converter-form .form-group .form-control {
  background-color: #ffffff;
}

.currency-exchange-banner-image .currency-converter-form .form-group.icon-add1 {
  position: relative;
}

.currency-exchange-banner-image .currency-converter-form .form-group.icon-add1 .form-control {
  padding-left: 40px;
}

.currency-exchange-banner-image .currency-converter-form .form-group.icon-add1 img {
  position: absolute;
  left: 15px;
  top: 48px;
}

.currency-exchange-banner-image .currency-converter-form .form-group.icon-add2 {
  position: relative;
}

.currency-exchange-banner-image .currency-converter-form .form-group.icon-add2 .form-control {
  padding-left: 40px;
}

.currency-exchange-banner-image .currency-converter-form .form-group.icon-add2 img {
  position: absolute;
  left: 15px;
  top: 48px;
}

.currency-exchange-banner-image .currency-converter-form .exchange-default-btn {
  width: 100%;
}

.currency-exchange-banner-image .banner-shape-1 {
  position: absolute;
  left: -30px;
  bottom: 130px;
  -webkit-animation: movebounce 8s linear infinite;
          animation: movebounce 8s linear infinite;
  z-index: -1;
}

.currency-exchange-banner-image .banner-shape-2 {
  position: absolute;
  left: -50px;
  top: 160px;
  -webkit-animation: movebounce 8s linear infinite;
          animation: movebounce 8s linear infinite;
}

.currency-exchange-banner-image .banner-shape-3 {
  position: absolute;
  right: 0;
  bottom: 200px;
  -webkit-animation: rotateme 8s linear infinite;
          animation: rotateme 8s linear infinite;
}

/*Features Style
======================================================*/
.exchange-features-card {
  padding: 30px;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-left: 5px solid #00a9a4;
  border-radius: 10px;
  margin-bottom: 20px;
}

.exchange-features-card .top-content {
  position: relative;
  padding-left: 50px;
  margin-bottom: 20px;
}

.exchange-features-card .top-content span {
  background-color: #00a9a4;
  color: #ffffff;
  height: 35px;
  width: 35px;
  line-height: 35px;
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.exchange-features-card .top-content h3 {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
}

.exchange-features-card p {
  margin-bottom: 15px;
}

.exchange-features-card a {
  color: #00a9a4;
  font-family: "Roboto", sans-serif;
}

.exchange-features-card a i {
  position: relative;
  top: 2px;
  padding-left: 7px;
}

/*About Style
======================================================*/
.exchainge-about-content {
  position: relative;
}

.exchainge-about-content .exchainge-about-title span {
  color: #00a9a4;
  font-size: 16px;
  display: inline-block;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
}

.exchainge-about-content .exchainge-about-title h2 {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.exchainge-about-content .about-text {
  margin-bottom: 30px;
}

.exchainge-about-content .about-text p {
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

.exchainge-about-content .about-shape-1 {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: -130px;
  -webkit-animation: movebounce 8s linear infinite;
          animation: movebounce 8s linear infinite;
}

.exchange-specialist-card {
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  margin-bottom: 30px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.exchange-specialist-card .specilist-img {
  position: relative;
  border-radius: 5px 5px 0 0;
}

.exchange-specialist-card .specilist-img img {
  border-radius: 5px 5px 0 0;
}

.exchange-specialist-card .specilist-img .icon-link {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  opacity: 0;
}

.exchange-specialist-card .specilist-img .icon-link a {
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
}

.exchange-specialist-card img {
  border-radius: 7px 7px 0  0;
}

.exchange-specialist-card .specialist-content {
  padding: 30px;
}

.exchange-specialist-card .specialist-content h3 {
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 17px;
}

.exchange-specialist-card .specialist-content p {
  margin-bottom: 18px;
}

.exchange-specialist-card .specialist-content a {
  color: #00a9a4;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.exchange-specialist-card .specialist-content a i {
  position: relative;
  top: 2px;
  padding-left: 7px;
}

.exchange-specialist-card:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.exchange-specialist-card:hover .specilist-img .icon-link {
  opacity: 1;
}

.exchange-specialist-card:hover .specilist-img::before {
  width: 100%;
}

/*Choose Style
======================================================*/
.exchange-choose-card {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.exchange-choose-card img {
  margin-bottom: 20px;
}

.exchange-choose-card h3 {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 15px;
}

.exchange-choose-card p {
  font-family: "Roboto", sans-serif;
}

/*Testimonial Style
======================================================*/
.exchange-testimonials-card {
  padding: 30px;
  padding-bottom: 40px;
  background-color: #ffffff;
  margin-bottom: 40px;
  border-radius: 5px;
  position: relative;
}

.exchange-testimonials-card .rating-list {
  padding-left: 0;
  margin-bottom: 0;
  margin-bottom: 12px;
}

.exchange-testimonials-card .rating-list li {
  display: inline-block;
  margin-right: 5px;
}

.exchange-testimonials-card .rating-list li:last-child {
  margin-right: 0;
}

.exchange-testimonials-card .rating-list li i {
  color: #ffc107;
}

.exchange-testimonials-card p {
  margin-bottom: 25px;
  font-family: "Roboto", sans-serif;
}

.exchange-testimonials-card .clients-profile {
  position: relative;
  padding-left: 90px;
}

.exchange-testimonials-card .clients-profile img {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: auto;
  width: 70px;
  height: 70px;
  border-radius: 100%;
}

.exchange-testimonials-card .clients-profile h3 {
  font-size: 18px;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
}

.exchange-testimonials-card .clients-profile p {
  margin-bottom: 0;
}

.exchange-testimonials-card .icon {
  position: absolute;
  bottom: 10px;
  right: 30px;
}

.exchange-testimonials-card .icon i {
  font-size: 50px;
  color: #00a9a4;
}

.exchange-testimonials-slider .swiper-pagination {
  position: initial;
}

.exchange-testimonials-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}

.exchange-testimonials-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #00a9a4;
}

/*Blog Style
======================================================*/
.exchange-blog-card {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  margin-bottom: 30px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.exchange-blog-card .blog-img {
  position: relative;
  border-radius: 5px 5px 0 0;
}

.exchange-blog-card .blog-img img {
  border-radius: 5px 5px 0 0;
}

.exchange-blog-card .blog-img .icon-link {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  opacity: 0;
}

.exchange-blog-card .blog-img .icon-link a {
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
}

.exchange-blog-card .blog-img::before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 0%;
  border-radius: 5px 5px 0 0;
  background: rgba(3, 7, 52, 0.75);
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.exchange-blog-card .blog-contenet {
  padding: 30px;
}

.exchange-blog-card .blog-contenet .list {
  padding-left: 0;
  margin-bottom: 0;
  margin-bottom: 15px;
}

.exchange-blog-card .blog-contenet .list li {
  display: inline-block;
  margin-right: 15px;
  position: relative;
  padding-left: 23px;
}

.exchange-blog-card .blog-contenet .list li i {
  position: absolute;
  left: 0;
  top: 2px;
  color: #00a9a4;
}

.exchange-blog-card .blog-contenet .list li:last-child {
  margin-right: 0;
}

.exchange-blog-card .blog-contenet .list li a {
  color: #19191b;
  font-weight: 500;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.exchange-blog-card .blog-contenet .list li a:hover {
  color: #00a9a4;
}

.exchange-blog-card .blog-contenet h3 {
  font-size: 22px;
  margin-bottom: 15px;
  line-height: 1.5;
  font-family: "Poppins", sans-serif;
}

.exchange-blog-card .blog-contenet h3 a {
  color: #19191b;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.exchange-blog-card .blog-contenet h3 a:hover {
  color: #00a9a4;
}

.exchange-blog-card .blog-contenet a {
  color: #00a9a4;
  font-family: "Roboto", sans-serif;
}

.exchange-blog-card .blog-contenet a i {
  position: relative;
  top: 2px;
  padding-left: 7px;
}

.exchange-blog-card:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.exchange-blog-card:hover .blog-img .icon-link {
  opacity: 1;
}

.exchange-blog-card:hover .blog-img::before {
  width: 100%;
}

/*Partner Style
======================================================*/
.exchange-partners-slider.owl-carousel .owl-item img {
  width: auto;
  margin: auto;
}

/*Faq Style
======================================================*/
.faq-accordion .accordion {
  border: none;
}

.faq-accordion .accordion .accordion__item {
  display: block;
  padding: 0;
  margin-bottom: 30px;
  border: none;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
}

.faq-accordion .accordion .accordion__item:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion__button {
  position: relative;
  display: block;
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  background-color: #fff;
  color: #19191b;
}

.faq-accordion .accordion .accordion__button:before {
  position: relative;
  float: right;
  top: 11px;
}

.faq-accordion .accordion .accordion__panel {
  padding: 0 20px 20px 20px;
}

.faq-accordion .accordion .accordion__panel p {
  font-weight: 400;
}

/*Converter Style
======================================================*/
.converter-area {
  background-color: #13287e;
  position: relative;
  z-index: 1;
}

.converter-area .input-area {
  max-width: 1000px;
  margin: auto;
}

.converter-area .input-area .form-group .form-control {
  border: none;
  border-radius: 7px 0 0 7px;
}

.converter-area .input-area .form-group .form-select {
  background-color: #00a9a4;
  border-radius: 0 7px 7px 0;
  color: #ffffff;
  /*background-image: url('/images/svg-icon/icon-6.png');*/
}

.converter-area .bgb {
  text-align: center;
}

.converter-area .bgb ul {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 30px;
}

.converter-area .bgb ul li {
  display: inline-block;
  margin-right: 20px;
  color: #ffffff;
}

.converter-area .bgb ul li:last-child {
  margin-right: 0;
}

.converter-area::before {
  position: absolute;
  content: '';
  background-color: #ffffff;
  width: 100%;
  height: 250px;
  left: 0;
  bottom: 0;
  z-index: -1;
}

#particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.converter-form {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 30px 30px 10px;
  border-radius: 7px;
  margin-top: 30px;
  overflow-y: hidden;
}

.converter-form thead tr {
  border: 1px solid #eeeeee;
}

.converter-form thead tr th {
  color: #19191b;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  padding: 15px 15px;
}

.converter-form thead tr .flag img {
  width: 35px;
  height: 35px;
  border-radius: 100%;
}

.converter-form tbody tr {
  border: 1px solid #eeeeee;
}

.converter-form tbody tr td {
  vertical-align: middle;
  white-space: nowrap;
  padding: 15px 15px;
  color: #555555;
  text-align: center;
}

.converter-form tbody tr td.code a {
  font-weight: 500;
  color: #00a9a4;
}

.converter-form .list {
  margin-top: 30px;
  margin-bottom: 20px;
}

.converter-form .list ul {
  padding-left: 0;
  margin-bottom: 0;
}

.converter-form .list ul li {
  list-style-type: none;
}

.converter-form .list ul li span {
  display: inline-block;
  position: relative;
  padding-left: 20px;
}

.converter-form .list ul li span::before {
  position: absolute;
  content: '';
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: #00a9a4;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.converter-form .list ul li a {
  color: #00a9a4;
}

.converter-form .list ul li a i {
  position: relative;
  top: 2px;
  padding-left: 7px;
}

/*Blog Details Style
======================================================*/
.news-details .news-simple-card img {
  margin-bottom: 30px;
}

.news-details .news-simple-card .list {
  margin-bottom: 15px;
}

.news-details .news-simple-card .list ul {
  padding-left: 0;
  margin-bottom: 0;
}

.news-details .news-simple-card .list ul li {
  display: inline-block;
  margin-right: 15px;
  font-size: 15px;
}

.news-details .news-simple-card .list ul li i {
  padding-right: 7px;
  color: #0797ff;
}

.news-details .news-simple-card .list ul li a {
  color: #555555;
  text-decoration: none;
}

.news-details .news-details-content {
  margin-bottom: 30px;
}

.news-details .news-details-content h1, .news-details .news-details-content h2, .news-details .news-details-content h3, .news-details .news-details-content h4, .news-details .news-details-content h5, .news-details .news-details-content h6 {
  margin-bottom: 15px;
}

.news-details .news-details-content h1 {
  font-size: 36px;
}

.news-details .news-details-content h2 {
  font-size: 33px;
}

.news-details .news-details-content h3 {
  font-size: 30px;
}

.news-details .news-details-content h4 {
  font-size: 27px;
}

.news-details .news-details-content h5 {
  font-size: 27px;
}

.news-details .news-details-content h6 {
  font-size: 23px;
}

.news-details .news-details-content p {
  margin-bottom: 15px;
}

.news-details .news-details-content p:last-child {
  margin-bottom: 0;
}

.news-details .news-details-content ul, .news-details .news-details-content ol {
  padding-left: 17px;
}

.news-details .news-details-content ul li, .news-details .news-details-content ol li {
  margin-bottom: 10px;
  line-height: 1.8;
}

.news-details .news-details-content ul li:last-child, .news-details .news-details-content ol li:last-child {
  margin-bottom: 0;
}

.news-details .blog-img-and-content {
  margin-bottom: 30px;
}

.news-details .blog-img-and-content img {
  margin-bottom: 30px;
}

.tags-and-share {
  padding-bottom: 25px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 30px;
}

.tags-and-share .tags ul {
  padding-left: 0;
  margin-bottom: 0;
}

.tags-and-share .tags ul li {
  margin-right: 10px;
  display: inline-block;
}

.tags-and-share .tags ul li span {
  font-size: 18px;
  font-weight: 500;
  color: #19191b;
}

.tags-and-share .tags ul li a {
  color: #555555;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  position: relative;
  text-decoration: none;
}

.tags-and-share .tags ul li a:hover {
  color: #0797ff;
}

.tags-and-share .tags ul li a::before {
  position: absolute;
  content: ',';
  right: -4px;
}

.tags-and-share .tags ul li:last-child a::before {
  display: none;
}

.tags-and-share .share {
  text-align: right;
}

.tags-and-share .share ul {
  padding-left: 0;
  margin-bottom: 0;
}

.tags-and-share .share ul li {
  display: inline-block;
  margin-left: 5px;
}

.tags-and-share .share ul li span {
  font-size: 18px;
  font-weight: 500;
  color: #19191b;
  margin-right: 5px;
}

.tags-and-share .share ul li a {
  height: 35px;
  width: 35px;
  line-height: 38px;
  border-radius: 100%;
  background-color: #f4f6f9;
  color: #0797ff;
  display: inline-block;
  text-align: center;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  text-decoration: none;
}

.tags-and-share .share ul li a:hover {
  background-color: #0797ff;
  color: #ffffff;
}

.comments {
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 30px;
}

.comments h3 {
  font-size: 25px;
  margin-bottom: 20px;
}

.comments .single-comments-box {
  padding-left: 100px;
  position: relative;
  margin-bottom: 30px;
}

.comments img {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 80px;
  height: 80px;
}

.comments .reply {
  position: absolute;
  right: 0;
  background-color: #f4f6f9;
  padding: 10px 15px;
  top: 15px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.comments .reply a {
  color: #555555;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  text-decoration: none;
}

.comments .reply:hover {
  background-color: #0797ff;
}

.comments .reply:hover a {
  color: #ffffff;
}

.comments h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.comments .date {
  margin-bottom: 10px;
}

.reply-area {
  margin-bottom: 30px;
}

.reply-area h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.reply-area p {
  margin-bottom: 20px;
}

.reply-area .reply-form .form-group {
  margin-bottom: 25px;
}

.reply-area .reply-form .form-group .form-control {
  background-color: #f4f6f9;
  border: 1px solid transparent;
}

.reply-area .reply-form .form-group .form-control:focus {
  border-color: #0797ff;
}

.reply-area .reply-form .form-check {
  margin-bottom: 30px;
}

.reply-area .reply-form .form-check .form-check-input {
  border-radius: 0;
  position: relative;
  top: -2px;
}

.reply-area .reply-form .form-check .form-check-input:checked {
  background-color: #0797ff;
  border-color: #0797ff;
}

.reply-area .reply-form .form-check .form-check-input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

/*Page Banner Style
======================================================*/
.page-banner-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 200px;
  padding-bottom: 150px;
}

.page-banner-area::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(17, 29, 94, 0.8);
}

.page-banner-content {
  position: relative;
}

.page-banner-content h1 {
  font-size: 50px;
  margin-bottom: 15px;
  color: #ffffff;
}

.page-banner-content ul {
  padding-left: 0;
  margin-bottom: 0;
}

.page-banner-content ul li {
  display: inline-block;
  position: relative;
  padding-right: 25px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.page-banner-content ul li a {
  text-decoration: none;
  color: #ffffff;
}

.page-banner-content ul li::before {
  position: absolute;
  content: '/';
  right: 9px;
  top: 0;
}

.page-banner-content ul li:last-child {
  color: #0797ff;
  padding-right: 0;
}

.page-banner-content ul li:last-child::before {
  display: none;
}

/*Services Details Style
======================================================*/
.services-details .top-image {
  margin-bottom: 30px;
}

.services-details .top-image img {
  border-radius: 10px;
  width: 100%;
}

.services-details h1, .services-details h2, .services-details h3, .services-details h4, .services-details h5, .services-details h6 {
  margin-bottom: 15px;
}

.services-details h1 {
  font-size: 27px;
}

.services-details h2 {
  font-size: 26px;
}

.services-details h3 {
  font-size: 23px;
}

.services-details h4 {
  font-size: 20px;
}

.services-details h5 {
  font-size: 18px;
}

.services-details h6 {
  font-size: 16px;
}

.services-details ul li, .services-details ol li {
  margin-bottom: 10px;
  line-height: 1.6;
}

.services-details ul li:last-child, .services-details ol li:last-child {
  margin-bottom: 0;
}

/*Project Details Style
======================================================*/
.project-details-content {
  margin-bottom: 30px;
}

.project-details-content .top-image {
  margin-bottom: 30px;
}

.project-details-content .top-image img {
  border-radius: 10px;
}

.project-details-content .project-details-text h1, .project-details-content .project-details-text h2, .project-details-content .project-details-text h3, .project-details-content .project-details-text h4, .project-details-content .project-details-text h5, .project-details-content .project-details-text h6 {
  margin-bottom: 15px;
}

.project-details-content .project-details-text h1 {
  font-size: 27px;
}

.project-details-content .project-details-text h2 {
  font-size: 26px;
}

.project-details-content .project-details-text h3 {
  font-size: 23px;
}

.project-details-content .project-details-text h4 {
  font-size: 20px;
}

.project-details-content .project-details-text h5 {
  font-size: 18px;
}

.project-details-content .project-details-text h6 {
  font-size: 16px;
}

.project-details-content .project-details-text ul li, .project-details-content .project-details-text ol li {
  margin-bottom: 10px;
  line-height: 1.6;
}

.project-details-content .project-details-text ul li:last-child, .project-details-content .project-details-text ol li:last-child {
  margin-bottom: 0;
}

.project-details {
  padding: 30px;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}

.project-details h3 {
  font-size: 20px;
  margin-bottom: 25px;
}

.project-details ul {
  padding-left: 0;
  margin-bottom: 0;
}

.project-details ul li {
  list-style-type: none;
  margin-bottom: 15px;
}

.project-details ul li span {
  color: #19191b;
  font-weight: 500;
  padding-right: 5px;
}

.project-details ul li:last-child {
  margin-bottom: 0;
}

.main-text-content h1, .main-text-content h2, .main-text-content h3, .main-text-content h4, .main-text-content h5, .main-text-content h6 {
  margin-bottom: 15px;
}

.main-text-content h1 {
  font-size: 30px;
}

.main-text-content h2 {
  font-size: 27px;
}

.main-text-content h3 {
  font-size: 26px;
}

.main-text-content h4 {
  font-size: 23px;
}

.main-text-content h5 {
  font-size: 20px;
}

.main-text-content h6 {
  font-size: 18px;
}

.main-text-content ul li, .main-text-content ol li {
  margin-bottom: 10px;
  line-height: 1.6;
}

.main-text-content ul li:last-child, .main-text-content ol li:last-child {
  margin-bottom: 0;
}

.main-text-content a {
  color: #f4732a;
}

.about-content .about-title {
  margin-bottom: 30px;
}

.about-content .about-title h2 {
  font-size: 30px;
  margin-bottom: 15px;
}

.about-content .about-text {
  margin-bottom: 30px;
}

.about-content .about-text:last-child {
  margin-bottom: 0;
}

.about-content .about-text h4 {
  font-size: 20px;
  margin-bottom: 15px;
}

.about-content .about-text ul {
  margin: 0;
}

.about-content .about-text ul li {
  margin-bottom: 10px;
  line-height: 1.8;
}

.about-content .about-text ul li:last-child {
  margin-bottom: 0;
}

.contact-info-card {
  margin-bottom: 30px;
  background-color: #f7f7f7;
  text-align: center;
  padding: 50px 30px;
  border-radius: 10px;
}

.contact-info-card i {
  display: inline-block;
  background: #3561e4;
  color: #fff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  border-radius: 100%;
  margin-bottom: 20px;
}

.contact-info-card p {
  font-size: 18px;
}

.contact-info-card p a {
  color: #000;
}

.contact-info-card p a:hover {
  color: #3561e4;
}

/* Sidebar Demo Modal Style */
.demos-btn {
  position: fixed;
  top: 30%;
  right: 0;
  background-color: red;
  color: #fff;
  z-index: 9999;
  display: inline-block;
  cursor: pointer;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  text-orientation: upright;
  text-transform: uppercase;
  padding: 10px 7px;
  border-radius: 5px 0 0 5px;
  -webkit-transition: all .5s;
  transition: all .5s;
}

.sidebar-demo-modal {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.95);
  padding: 100px 30px;
  overflow-y: auto;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.sidebar-demo-modal.show {
  right: 0;
  left: 0;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.sidebar-demo-modal .close-icon {
  background-color: red;
  color: #fff;
  position: absolute;
  top: 25px;
  right: 25px;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100px;
  cursor: pointer;
  font-size: 25px;
}

.sidebar-demo-modal .close-icon:hover {
  background-color: #d50505;
}

.sidebar-demo-modal .card {
  background-color: #fff;
  text-align: center;
}

.sidebar-demo-modal .card h4 {
  padding: 20px;
  margin: 0;
  font-size: 20px;
  border-top: 1px solid #eee;
}

.sidebar-demo-modal .card:hover h4 {
  color: #f4732a;
}

/* End Sidebar Demo Modal Style */
